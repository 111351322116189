import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
// import { Products } from "./data/ProductStocks";
import { CATEGORIES } from "../../graphql/categories";
import { useQuery } from "@apollo/client";
import { Spin } from "antd";

const ProductStockChart = () => {
  const { loading, error, data } = useQuery(CATEGORIES);
  console.error(error);
  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..."></Spin>
      </center>
    );
  }
  const datas = {
    labels: data.categories.map((data) => data.title),
    datasets: [
      {
        label: "# of Votes",
        data: data.categories.map((data) => data.products.length),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  ChartJS.register(ArcElement, Tooltip, Legend);
  return <Doughnut data={datas} />;
};

export default ProductStockChart;
