import React, { useState } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  Button,
  Form,
  Input,
  Upload,
  Layout,
  Row,
  Col,
  Select,
  message,
} from "antd";
import { useQuery } from "@apollo/client";
import { BRANDS } from "../../graphql/brand";
import { SUB_CATEGORIES } from "../../graphql/sub-category";
import { v4 as uuidv4 } from "uuid";
const uuid = uuidv4().toString();
const { Content } = Layout;
const { Option } = Select;
const URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PRO_API;
const CreateQuotation = () => {
  const [state, setState] = useState({
    imageUrl: null,
    loading: false,
  });
  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    if (e.file.status === "done") {
      console.log(
        "fileLIst",
        e?.fileList.map((res) => res.response.file[0].path)
      );
      return e?.fileList.map((res) => res.response.file[0].path);
    }
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      setState({
        // imageUrl: info.file.response.imageUrl,
        imageUrl: info.fileList.map(
          (res) => `${URL}/${res.response.file[0].path}`
        ),
        loading: false,
      });
    }
  };
  const { loading: brandLoading, data: brandData } = useQuery(BRANDS);
  const { loading, data } = useQuery(SUB_CATEGORIES);

  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    console.log(values);
    const data = {
      ...values,
      type: "create",
      product: values.product,
      JBID: `JB${uuid.replace(/-/gi, "")}`,
    };
    axios.post(`${URL}/create-quote`, data).then((res) => {
      message.success("successfully");
      window.location.reload();
    });
  };
  if (loading || brandLoading) return "loading...";
  return (
    <>
      <Content>
        <div className="contentContainer-width">
          <h1 className="header-content">Create Your Quotation</h1>
          <Form
            // name="basic"
            layout="vertical"
            // size="large"
            // name="dynamic_form_nest_item"
            // style={{
            //   maxWidth: 600,
            // }}
            autoComplete="off"
            onFinish={handleSubmit}
            form={form}
          >
            <Row gutter={[12, 12]}>
              <Col span={8}>
                <Form.Item
                  label="Customer name"
                  rules={[
                    {
                      required: true,
                      message: "Input your customer name!",
                    },
                  ]}
                  name="customerName"
                >
                  <Input placeholder="input Customer name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Customer email"
                  rules={[
                    {
                      required: true,
                      message: "Input your customer email!",
                    },
                  ]}
                  name="customerEmail"
                >
                  <Input placeholder="input customer email" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Input your customer phone!",
                    },
                  ]}
                  label="Customer phone"
                  name="customerPhone"
                >
                  <Input placeholder="input Customer Phone" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Input status!",
                    },
                  ]}
                  label="Status"
                  name={["Waiting", "Sent", "Create Order"]}
                >
                  <Select placeholder="Select Status">
                    <Option value="Waiting">Waiting</Option>
                    <Option value="Sent">Sent</Option>
                    <Option value="Create Order">Create Order</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item
                  style={{ width: "100%" }}
                  label="Customer ID"
                  name="customerId"
                >
                  <Input placeholder="input Customer ID" />
                </Form.Item>
              </Col> */}
              <Col span={16}>
                <Form.Item
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Input your customer address!",
                    },
                  ]}
                  label="Customer address"
                  name="customerAddress"
                >
                  <Input.TextArea
                    style={{ height: "60px" }}
                    placeholder="input Customer address"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.List name="product">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <Row gutter={[12, 12]}>
                        <Col span={8}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Input product name!",
                              },
                            ]}
                            label="Product name"
                            name={[name, "productName"]}
                          >
                            <Input placeholder="input product name" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Input qty!",
                              },
                            ]}
                            label="QTY"
                            name={[name, "qty"]}
                          >
                            <Input placeholder="input qty" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Input price after tax!",
                              },
                            ]}
                            label="Price after tax"
                            name={[name, "priceAfterTax"]}
                          >
                            <Input placeholder="input price after tax" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[12, 12]}>
                        <Col span={8}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Input price in Cambodia!",
                              },
                            ]}
                            label="Price in Cambodia"
                            name={[name, "priceInKh"]}
                          >
                            <Input placeholder="input price in Cambodia" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Input Shipping!",
                              },
                            ]}
                            label="Shipping"
                            name={[name, "shipping"]}
                          >
                            <Input placeholder="input Shipping" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Input discount!",
                              },
                            ]}
                            label="Discount"
                            name={[name, "discount"]}
                          >
                            <Input placeholder="input discount" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[12, 12]}>
                        <Col span={12}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Input weight!",
                              },
                            ]}
                            label="Weight"
                            name={[name, "weight"]}
                          >
                            <Input placeholder="input weight" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Input link!",
                              },
                            ]}
                            label="Link"
                            name={[name, "link"]}
                          >
                            <Input placeholder="input link" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[12, 12]}>
                        <Col span={24 / 3}>
                          <Form.Item
                            label="Brand name"
                            name={[name, "brandName"]}
                            rules={[
                              {
                                required: true,
                                message: "Brandname is required!",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select a brand name"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {brandData.brands.map((brand) => {
                                return (
                                  <Option value={brand.title} key={brand.id}>
                                    {brand.title}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Sub-category"
                            name={[name, "categoriesId"]}
                            rules={[
                              {
                                required: true,
                                message: "Category is required!",
                              },
                            ]}
                          >
                            <Select
                              //   onChange={handleChanges}
                              showSearch
                              placeholder="Select a Categories"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {data.SubCategories.map((category) => {
                                return (
                                  <Option
                                    // onClick={() => setSlug(category.slug)}
                                    value={category.slug}
                                    key={category.id}
                                  >
                                    {category.title}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          {/* <Form.Item
                            // name="upload"
                            name={[name, "multi-files"]}
                            label="Upload"
                            // valuePropName="fileList"
                            // getValueFromEvent={normFile}
                            extra="longgggggggggggggggggggggggggggggggggg"
                          >
                            <Upload
                              onChange={handleChange}
                              // name="logo"
                              name={[name, "multi-files"]}
                              // action="/upload.do"
                              action={`${URL}/multiple-upload`}
                              listType="picture"
                            >
                              <Button icon={<UploadOutlined />}>
                                Click to upload
                              </Button>
                            </Upload>
                          </Form.Item> */}
                          <Form.Item
                            // name="upload"
                            name={[name, "thumbnail"]}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                          >
                            <Upload
                              multiple="true"
                              name="multi-files"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              action={`${URL}/multiple-upload`}
                              // action="https://server.jabaram.com/multiple-upload"
                              onChange={handleChange}
                            >
                              {state.imageUrl ? (
                                <img
                                  src={state.imageUrl[0]}
                                  alt="thumbnail"
                                  style={{ width: "120px", height: "100px" }}
                                />
                              ) : (
                                <div>
                                  {state.loading ? (
                                    <LoadingOutlined />
                                  ) : (
                                    <PlusOutlined />
                                  )}
                                  <div style={{ marginTop: 8 }}>315 × 200</div>
                                </div>
                              )}
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Input Descriptions!",
                          },
                        ]}
                        label="Descriptions"
                        name={[name, "descriptions"]}
                      >
                        <Input.TextArea placeholder="input description" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </>
  );
};

export default CreateQuotation;
