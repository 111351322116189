import React, { useState } from "react";
import { Layout, Table, Tag, Typography } from "antd";
import { useQuery } from "@apollo/client";
import { REQUEST_QUOTE } from "../../graphql/quotation";
import { Link } from "react-router-dom";
import Modal from "antd/es/modal/Modal";

const { Content } = Layout;
const { Paragraph } = Typography;
const RequestQuote = () => {
  const [des, setDes] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, loading } = useQuery(REQUEST_QUOTE);
  if (loading) return "loading...";
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Number",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "ID",
      dataIndex: "JBID",
      key: "index",
    },
    {
      title: "Customer ID",
      dataIndex: "userId",
      key: "id",
      render: (id) => {
        return <Paragraph copyable>{id}</Paragraph>;
      },
    },
    {
      title: "Cusomter Name",
      dataIndex: "fullname",
      key: "index",
    },
    {
      title: "Cusomter Email",
      dataIndex: "email",
      key: "index",
    },
    {
      title: "Cusomter Phone",
      dataIndex: "phone",
      key: "index",
    },
    {
      title: "Descriptions",
      dataIndex: "descriptions",
      key: "index",
      render: (data) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setDes(data);
            }}
          >
            <p>{data.substring(0, 30)} ...</p>
          </div>
        );
      },
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "index",
      render: (link) => {
        return <Paragraph copyable>{link}</Paragraph>;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (link, data) => {
        return (
          <Link to={`/dashboard/create/quotation/${data.id}`}>
            <Tag color="#5339d1">Confirm</Tag>
          </Link>
        );
      },
    },
  ];
  return (
    <div>
      <Modal
        title="Descriptions"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>{des}</p>
      </Modal>
      <Content>
        <h2>Requests Views</h2>
        <br />
        <Table columns={columns} dataSource={data.request_quote_users} />
      </Content>
    </div>
  );
};

export default RequestQuote;
