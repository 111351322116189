import React, { useState } from "react";
import {
  Layout,
  Form,
  Input,
  Row,
  Col,
  message,
  Upload,
  Button,
  Select,
  Spin,
  Space,
  InputNumber,
  Divider,
  Collapse,
  Rate,
  Tag,
  Radio,
} from "antd";
import {
  PlusOutlined,
  PictureFilled,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { SUB_CATEGORIES } from "../../graphql/sub-category";
import { BRANDS } from "../../graphql/brand";
import { CREATE_PRODUCT } from "../../graphql/products";
import { CATEGORIES } from "../../graphql/categories";
import { SUB_CATE_BY_MAIN_SLUG } from "../../graphql/sub-category";
import { useQuery, useMutation } from "@apollo/client";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import photos from "../../assets/images/2.png";
import parse from "html-react-parser";
import ColorPicker from "react-pick-color";

const { Content } = Layout;
const { Option } = Select;

const URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PRO_API;

const UplaodTestimonialNew = () => {
  // ========state======
  const [color, setColor] = useState("");
  const [mainColor, setMainColor] = useState([]);
  const [sizes, setSizes] = useState("");
  const [mainSizes, setMainSizes] = useState([]);
  const [slug, setSlug] = useState([]);
  const [value, setValue] = useState("");

  const [form] = Form.useForm();
  const [createProduct] = useMutation(CREATE_PRODUCT);

  // ===========new state ------------
  const [name, setName] = useState("");
  const [selectedImage, setSelectedImage] = useState(0);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [branding, setBranding] = useState("");
  const [rating, setRating] = useState(3);
  const [price, setPrice] = useState(0);
  const [weight, setWeight] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [highlights, setHighlights] = useState("");
  const [keyfeature, setKeyfeature] = useState("");
  const [size, setSize] = useState("");
  const [where, setWhere] = useState("third-party");
  const [slugMainCate, setSlugMainCate] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [thumbnails, setThumbnails] = useState([]);
  const [imgLink, setImgLink] = useState([]);
  const [links, setLinks] = useState([]);

  const clickLink = () => {
    setLinks((current) => [...current]);
  };
  const handleImageClick = (ind) => () => {
    setSelectedImage(ind);
  };

  const handleRemoveItem = (ind) => {
    console.log(ind, "name");
    setMainColor((preState) => preState.filter((index) => index !== ind));
  };
  const handleRemoveItemSize = (ind) => {
    setMainSizes((preState) => preState.filter((index) => index !== ind));
  };

  // console.log(submitLink, "submitlink");
  const { loading, data } = useQuery(SUB_CATEGORIES);
  const {
    laoding: subCatMainLoading,
    data: subCatMainData,
    refetch,
  } = useQuery(SUB_CATE_BY_MAIN_SLUG, {
    // onCompleted: (data) => setSlugMainCate(data),
    variables: { slug: slugMainCate.toString() },
  });
  const { loading: mainCatLoading, data: mainCatData } = useQuery(CATEGORIES);
  const [state, setState] = useState({
    imageUrl: null,
    loading: false,
  });

  const { loading: brandLoading, data: brandData } = useQuery(BRANDS);

  const handleChange = (info) => {
    setFileList(info.fileList);
    console.log(info);
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      setThumbnails(info.fileList.map((res) => res.response.imageUrl));
      setImageUrl(info.file.response.imageUrl);
    }
  };

  const handleChanges = (value) => {
    setSlug(value);
  };

  const handleSubmit = async (values) => {
    console.log(values);
    // const saveDescription = await instanceRef.current.save();
    createProduct({
      variables: {
        ...values,
        userId: "1",
        color: mainColor.map((res) => res),
        size: mainSizes.map((es) => es),
        descriptions: value,
        productName: values.name ? values.name : "",
        discountPrice:
          where === "own"
            ? Number((price - (price * discount) / 100).toFixed(2))
            : Number(
                (
                  price * 0.12 +
                  weight * 7 +
                  price -
                  (price * discount) / 100
                ).toFixed(2)
              ),

        originalPrice:
          where === "own"
            ? Number(price)
            : price * 0.12 + weight * 7 + Number(price)
            ? price * 0.12 + weight * 7 + Number(price)
            : "",
        usPrice: Number(price),
        weight: Number(weight),

        shippingCost:
          where === "own"
            ? Number(0)
            : price * 0.12 + weight * 7
            ? price * 0.12 + weight * 7
            : 0,

        rating: values.rating ? parseInt(values.rating) : 0,
        thumbnail: thumbnails,

        asin: values.asin ? values.asin : "",
        discount: values.discount ? parseInt(values.discount) : 0,

        link: values.link ? values.link : "",
        categoriesId: values.categoriesId ? values.categoriesId : [],
        categoriesSlug: slug ? slug : [],
        mainCategoriesId: values.mainCategoriesId,
        mainCategoriesSlug: slugMainCate,
        details: values.details ? values.details : "",
        descriptions: values.descriptions ? values.descriptions : "",
        brandName: values.brandName ? values.brandName : "",
        imagesURL: !values.imagesURL
          ? ""
          : values.imagesURL.map((res) => res.imagesURL),
      },
    })
      .then(async (res) => {
        // instanceRef.current.clear();
        setState({ imageUrl: null });
        form.resetFields();
        setFileList([]);
        setDiscount(0);
        setThumbnails([]);
        await message.success(res.data.createProduct.message, 3);
      })
      .catch((error) => console.error(error));
  };

  const selectCategory = (value) => {
    setCategory(value);
    setSlugMainCate(value);
    refetch();
  };
  const selectSubCategory = (value) => {
    setSubCategory(value);
    setSlug(value);
  };

  // format number to US dollar
  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  if (loading || brandLoading || !data || mainCatLoading || subCatMainLoading) {
    return (
      <center>
        <Spin tip="Loading..." />
      </center>
    );
  }
  const onChangeSelect = (value) => {
    setWhere(value);
  };

  return (
    <React.Fragment>
      <Content>
        <Row gutter={[32, 32]}>
          <Col span={10}>
            <h1 className="header-content">Add Products</h1>
            <div className="add-products-container">
              {/* details */}
              <div className="content-add-products">
                <Form
                  form={form}
                  name="basic"
                  onFinish={handleSubmit}
                  layout="vertical"
                >
                  <div>
                    <p className="label-form-2">Where?</p>
                    <p className="second-label">
                      {where === "third-party" &&
                        "The product which imported from (Amazon, Ebay, ...)"}
                      {where === "own" &&
                        "The products which are already in Cambodia!"}
                      {where === "general" &&
                        "The product exclude formula but has form to add pricing! "}
                    </p>
                    <Form.Item
                      name="wheres"
                      rules={[
                        {
                          required: true,
                          message: "Wheres is required!",
                        },
                      ]}
                    >
                      <Select
                        onChange={onChangeSelect}
                        placeholder="Select your product from"
                        // defaultValue="third-party"
                        size="large"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Option value="third-party">
                          Third Party Products
                        </Option>
                        <Option value="own">Own Products</Option>
                        <Option value="general">General Products</Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <Form.Item name="bestSeller" label="Best Seller">
                    <Radio.Group>
                      <Radio value={true}>YES</Radio>
                      <Radio value={false}>NO</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <br />
                  <Form.Item
                    label="Thumbnail"
                    name="thumbnail"
                    // rules={[
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                  >
                    <Upload
                      name="thumbnail"
                      action={`${URL}/upload/image`}
                      listType="picture-card"
                      multiple={true}
                      // beforeUpload={beforeUpload}
                      onChange={handleChange}
                      showUploadList={true}
                      withCredentials={true}
                    >
                      {/* <div className="btn-upload"> */}
                      <PictureFilled />
                      {/* </div> */}
                    </Upload>
                  </Form.Item>
                  <br />
                  <Form.List name="imagesURL">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...resetField }) => (
                          <div
                            key={key}
                            style={{
                              alignItems: "center",
                              // width: "100%",
                              display: "flex",
                              // marginBottom: 8,
                            }}
                            // align="baseline"
                          >
                            <Form.Item
                              style={{ width: "100%" }}
                              label="Image-Link"
                              name={[name, "imagesURL"]}
                            >
                              <Input
                                size="large"
                                onChange={(e) => {
                                  // setImgLink(e.target.value);
                                  setImgLink((cur) => [...cur, e.target.value]);
                                }}
                                style={{ width: "100%" }}
                                className="schoolInput"
                              />
                            </Form.Item>
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(name)}
                            />
                          </div>
                        ))}
                        <Form.Item>
                          <Button
                            size="middle"
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Image Link
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  {/* name */}
                  <p className="label-form">Product details</p>
                  <Form.Item
                    label="Product name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Product name is required!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Form.Item>
                  {/* category */}
                  <Form.Item
                    label="Category"
                    name="mainCategoriesId"
                    rules={[
                      {
                        required: true,
                        message: "Category is required!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Select
                      //   mode="multiple"
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      placeholder="select category"
                      optionLabelProp="label"
                      showSearch
                      //   options={categoryOptions}
                      onChange={selectCategory}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {mainCatData.categories.map((main) => {
                        return (
                          <Option value={main.slug} key={main.id}>
                            {main.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {/* sub category */}
                  <Form.Item
                    label="Sub-category"
                    name="categoriesId"
                    rules={[
                      {
                        required: true,
                        message: "Sub-category is required!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginLeft: "12px",
                    }}
                  >
                    <Select
                      disabled={category === ""}
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      placeholder="select sub category"
                      showSearch
                      onChange={selectSubCategory}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {subCatMainData?.SubCategoryByCateSlug.map((res) => {
                        return (
                          <Option
                            // onClick={() => setSlug(res.slug)}
                            value={res.slug}
                            key={res.id}
                          >
                            {res.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {/* branding */}
                  <Form.Item
                    label="Brading"
                    name="brandName"
                    rules={[
                      {
                        required: true,
                        message: "Branding is required!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Select
                      onChange={(value) => {
                        setBranding(value);
                      }}
                      size="large"
                      showSearch
                      placeholder="Select a brand name"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {brandData.brands.map((brand) => {
                        return (
                          <Option value={brand.title} key={brand.id}>
                            {brand.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {/* rating */}
                  <Form.Item
                    initialValue={3}
                    label="Rating"
                    name="rating"
                    rules={[
                      {
                        required: true,
                        message: "Rating is required!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginLeft: "12px",
                    }}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      size="large"
                      min={1}
                      max={5}
                      defaultValue={3}
                      onChange={(value) => {
                        setRating(value);
                      }}
                    />
                  </Form.Item>
                  {/* link */}
                  <Form.Item
                    label="Link"
                    name="link"
                    rules={[
                      {
                        type: "url",
                        message: "This field must be a valid url.",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Input size="large" placeholder="Referal link" />
                  </Form.Item>
                  {/* Asin */}
                  <Form.Item
                    label="Asin"
                    name="asin"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginLeft: "12px",
                    }}
                  >
                    <Input size="large" />
                  </Form.Item>
                  {/* <p className="label-form">Product details</p> */}
                  <Form.Item label="Product Size" name="sizes">
                    <Input
                      size="large"
                      onChange={(e) => {
                        setSizes(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Button
                    size="small"
                    onClick={() => setMainSizes((arr) => [...arr, sizes])}
                    type="primary"
                  >
                    Add size
                  </Button>
                  <p>Picks product's color</p>
                  <Row gutter={[12, 12]}>
                    <Col span={18}>
                      <ColorPicker
                        theme={{
                          background: "#fff",
                          inputBackground: "grey",
                          borderColor: "none",
                          borderRadius: "8px",
                          border: "none",
                          color: "black",
                          boxShadow: "none",
                          width: "320px",
                        }}
                        color={color}
                        onChange={(color) => setColor(color.hex)}
                      />
                    </Col>
                    <Col span={6}>
                      <div
                        style={{
                          marginTop: "20px",
                          background: `${color}`,
                          width: "30px",
                          height: "30px",
                          borderRadius: "15px",
                        }}
                      ></div>
                    </Col>

                    <Button
                      size="small"
                      onClick={() => setMainColor((arr) => [...arr, color])}
                      type="primary"
                    >
                      Add color
                    </Button>
                  </Row>
                  <br />

                  {/* <div className="content-add-products"> */}
                  <p className="label-form">Pricing</p>
                  <Space>
                    {/* <Form layout="vertical"> */}
                    {/* price */}
                    <Space direction="vertical">
                      <Form.Item
                        label="Price (USD)"
                        name="price"
                        rules={[
                          {
                            required: true,
                            message: "Price is required!",
                          },
                        ]}
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          onChange={(value) => {
                            setPrice(value);
                          }}
                        />
                      </Form.Item>
                      {/* discount */}
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Discount is required!",
                          },
                        ]}
                        label="Discount (%)"
                        name="discount"
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          min={0}
                          max={100}
                          onChange={(value) => {
                            setDiscount(value);
                          }}
                        />
                      </Form.Item>
                      {/* weight */}
                      {where !== "own" && (
                        <>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Weight is required!",
                              },
                            ]}
                            label="Weight (Kg)"
                            name="weight"
                          >
                            <InputNumber
                              size="large"
                              style={{ width: "100%" }}
                              onChange={(value) => {
                                setWeight(value);
                              }}
                              min={0}
                            />
                          </Form.Item>
                        </>
                      )}
                    </Space>
                    {/* </Form> */}
                    <Divider type="vertical" />
                    <div>
                      <div className="pricing-detail">
                        <span>Subtotal</span>
                        <span>
                          {where === "own" ? (
                            <>${price}</>
                          ) : (
                            <> {!price ? "$0.00" : price.toFixed(2)}</>
                          )}
                        </span>
                      </div>
                      <div className="pricing-detail">
                        <span>Shipping</span>
                        {where === "own" ? (
                          <span>$0.00</span>
                        ) : (
                          <span>
                            {!price ? (
                              "$0.00"
                            ) : (
                              <>{(price * 0.12 + weight * 7).toFixed(2)}</>
                            )}
                          </span>
                        )}
                      </div>
                      <div className="pricing-detail">
                        <span>Discount</span>
                        <span>
                          {!discount
                            ? "$0.00"
                            : USDollar.format((discount * price) / 100)}
                        </span>
                      </div>
                      <Divider />
                      <div className="pricing-detail">
                        <span>Total</span>
                        <span className="total-price">
                          {where === "own" ? (
                            <span>
                              {" "}
                              ${(price - (price * discount) / 100).toFixed(2)}
                            </span>
                          ) : (
                            <>
                              {" "}
                              {(
                                price * 0.12 +
                                weight * 7 +
                                price -
                                (price * discount) / 100
                              ).toFixed(2)}
                            </>
                          )}
                        </span>
                      </div>
                      <div className="pricing-detail">
                        <span>Main Price</span>

                        <span className="total-price">
                          {where === "own" ? (
                            <span>${Number(price).toFixed(2)}</span>
                          ) : (
                            <>
                              {(
                                price * 0.12 +
                                weight * 7 +
                                Number(price)
                              ).toFixed(2)}
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </Space>
                  <br />
                  <Form.Item label="description" name="descriptions">
                    <ReactQuill
                      theme="snow"
                      value={value}
                      onChange={setValue}
                      placeholder="Write something..."
                    />
                  </Form.Item>

                  <br />

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      // disabled={
                      //   name === "" ||
                      //   category === "" ||
                      //   subCategory.length <= 0 ||
                      //   branding === "" ||
                      //   !rating ||
                      //   price === 0 ||
                      //   !price
                      // }
                    >
                      Publish
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <br />
              {/* pricing */}

              <br />
              {/* desc */}
              {/* <div className="content-add-products">
                <Collapse
                  defaultActiveKey={["1"]}
                  ghost
                  expandIconPosition="end"
                  className="product-detail-collapse"
                >
                  <Panel
                    header={
                      <>
                        <p className="label-form-2">Descriptions</p>{" "}
                        <p className="second-label">Text</p>
                      </>
                    }
                    key="1"
                  >
                    <ReactQuill
                      theme="snow"
                      value={desc}
                      onChange={setDesc}
                      placeholder="Write something..."
                    />
                  </Panel>
                </Collapse>
              </div> */}
              <br />
              {/* highlights */}
              {/* <div className="content-add-products">
                <Collapse
                  ghost
                  expandIconPosition="end"
                  className="product-detail-collapse"
                >
                  <Panel
                    header={
                      <>
                        <p className="label-form">Highlights</p>{" "}
                      </>
                    }
                  >
                    <ReactQuill
                      theme="snow"
                      placeholder="List styles is recommended!"
                      value={highlights}
                      onChange={setHighlights}
                    />
                  </Panel>
                </Collapse>
              </div> */}
              <br />
              {/* key features */}
              {/* <div className="content-add-products">
                <Collapse
                  ghost
                  expandIconPosition="end"
                  className="product-detail-collapse"
                >
                  <Panel
                    header={
                      <>
                        <p className="label-form">Key Features</p>{" "}
                      </>
                    }
                  >
                    <ReactQuill
                      theme="snow"
                      placeholder="List styles is recommended!"
                      value={keyfeature}
                      onChange={setKeyfeature}
                    />
                  </Panel>
                </Collapse>
              </div> */}
              <br />
              {/* size guides */}
              {/* <div className="content-add-products">
                <Collapse
                  ghost
                  expandIconPosition="end"
                  className="product-detail-collapse"
                >
                  <Panel
                    header={
                      <>
                        <p className="label-form">Size Guides</p>{" "}
                      </>
                    }
                  >
                    <ReactQuill
                      theme="snow"
                      placeholder="Table is recommended!"
                      value={size}
                      onChange={setSize}
                    />
                  </Panel>
                </Collapse>
              </div> */}
            </div>
            {/* <Button
              size="large"
              type="primary"
              style={{ width: "100%" }}
              disabled={
                name === "" ||
                category === "" ||
                subCategory.length <= 0 ||
                branding === "" ||
                !rating ||
                price === 0 ||
                !price
              }
            >
              Publish
            </Button> */}
          </Col>
          <Col span={14}>
            <h1 className="header-content">Preview</h1>
            <div className="product-preview-container">
              <Row gutter={[24, 24]}>
                <Col offset={3} span={4} className="allow-scroll">
                  {!thumbnails[0]
                    ? imgLink?.map((res, ind) => {
                        console.log(ind, "ind");
                        return (
                          <div key={ind} onClick={handleImageClick(ind)}>
                            <img
                              // unoptimized={true}
                              // quality={100}
                              // height={500}
                              // width={500}
                              // priority
                              className="product-image-small"
                              alt="product"
                              src={res}
                            />
                          </div>
                        );
                      })
                    : thumbnails.map((res, ind) => (
                        <div key={ind} onClick={handleImageClick(ind)}>
                          <img
                            // unoptimized={true}
                            // quality={100}
                            // height={500}
                            // width={500}
                            // priority
                            className="product-image-small"
                            alt="product"
                            src={res}
                          />
                        </div>
                      ))}
                </Col>
                <Col span={17}>
                  <div className="product-image-container">
                    {discount !== 0 && (
                      <div className="discount-tag">
                        <Tag color="error">{discount}% OFF</Tag>
                      </div>
                    )}
                    {/* <img
                      alt="product photos"
                      src={!imageUrl ? photos : imageUrl}
                      className="product-image"
                    /> */}
                    <>
                      {!thumbnails[0] ? (
                        <img
                          alt="product name"
                          src={
                            imgLink.length == 0
                              ? photos
                              : imgLink[selectedImage]
                          }
                          // src={imagesURL[selectedImage]}
                          className="product-image"
                        />
                      ) : (
                        <img
                          alt="product name"
                          src={thumbnails[selectedImage]}
                          className="product-image"
                        />
                      )}
                    </>
                  </div>
                </Col>
                <Col offset={3} span={21}>
                  <div className="description-container">
                    <p className="second-label">
                      {branding === "" ? "Branding" : branding}
                    </p>
                    <h2 className="product-name">
                      {name === "" ? "Product Name" : name}
                    </h2>
                    <h1 className="product-price">
                      {where === "own" ? (
                        <>${(price - (price * discount) / 100).toFixed(2)}</>
                      ) : (
                        <>
                          {" "}
                          {!price
                            ? "USD 0.00"
                            : `$ ${(
                                price * 0.12 +
                                weight * 7 +
                                price -
                                (price * discount) / 100
                              ).toFixed(2)}`}
                        </>
                      )}
                    </h1>
                    <Rate allowHalf defaultValue={3} value={rating} />
                    <br />
                    <h2>Size</h2>
                    <Row gutter={[12, 12]}>
                      {mainSizes.map((res) => {
                        return (
                          <Col span={3}>
                            <div
                              className="size-border"
                              onClick={() => {
                                handleRemoveItemSize(res);
                              }}
                            >
                              {res}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>

                    <br />
                    <h2>Colors</h2>
                    <Row>
                      {mainColor?.map((res, ind) => {
                        return (
                          <Col span={3}>
                            <div
                              onClick={() => {
                                handleRemoveItem(res);
                              }}
                              style={{
                                display: "flex",
                                marginTop: "20px",
                                background: `${res}`,
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                              }}
                            ></div>
                          </Col>
                        );
                      })}
                    </Row>

                    <br />
                    <Button
                      type="primary"
                      ghost
                      size="large"
                      style={{ width: "100%" }}
                    >
                      Checkout
                    </Button>
                    <br />
                    <br />
                    <Button
                      type="primary"
                      size="large"
                      style={{ width: "100%" }}
                    >
                      Add to cart
                    </Button>
                    <br />
                    <div>
                      {value !== "" && (
                        <>
                          <h3>Descriptions</h3>
                          <div>{parse(value)}</div>
                        </>
                      )}
                    </div>
                    <div>
                      {highlights !== "" && (
                        <>
                          <h3>Highlights</h3>
                          <div>{parse(highlights)}</div>
                        </>
                      )}
                    </div>
                    <div>
                      {keyfeature !== "" && (
                        <>
                          <h3>Key Features</h3>
                          <div>{parse(highlights)}</div>
                        </>
                      )}
                    </div>
                    <div>
                      {size !== "" && (
                        <>
                          <h3>Size Guides</h3>
                          <div>{parse(highlights)}</div>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default UplaodTestimonialNew;
