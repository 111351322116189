import { Fragment, useState } from "react";
import { Table, Button } from "antd";
import {
  approveProduct,
  listProducts,
  deleteProducts,
  listReviewProducts,
} from "../../services/products";

export const TablePreview = ({ data, columns, setItems, secret }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const start = () => {
    setLoading(true);
    if (secret === "master") {
      deleteProducts(selectedRowKeys).then((response) => {
        if (response.data.status === 200) {
          setTimeout(() => {
            setSelectedRowKeys([]);
            setLoading(false);
          }, 1000);
          listProducts().then((response) => {
            setItems(response.data.data);
          });
        }
      });
      return;
    }
    approveProduct(selectedRowKeys).then((response) => {
      if (response.data.status === 200) {
        setTimeout(() => {
          setSelectedRowKeys([]);
          setLoading(false);
        }, 1000);
        listReviewProducts().then((response) => {
          setItems(response.data.data);
        });
      }
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <Fragment>
      <div
        style={{
          marginBottom: 12,
          marginTop: 16,
        }}
      >
        <Button onClick={start} disabled={!hasSelected} loading={loading}>
          {secret === "master" ? "Delete" : "Public"}
        </Button>
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
    </Fragment>
  );
};
