import { gql } from "@apollo/client";

const MAINCATEGORIES = gql`
  query {
    mainCategories {
      id
      title
      products {
        productName
      }
      categories {
        id
        title
      }
      created_at
      updated_at
    }
  }
`;

const MAINCATEGORY = gql`
  query ($id: String!) {
    mainCategory(id: $id) {
      title
      categoryId
      categories {
        id
        title
      }
    }
  }
`;

const CREATE_MAIN_CATEGORIES = gql`
  mutation CreateMainCategory(
    $title: String!
    $categoryId: String!
    $categorySlug: String!
  ) {
    createMainCategory(
      title: $title
      categoryId: $categoryId
      categorySlug: $categorySlug
    ) {
      message
    }
  }
`;

const UPDATE_MAIN_CATEGORY = gql`
  mutation (
    $title: String!
    $id: String!
    $categoryId: String!
    $categorySlug: String!
  ) {
    updateMainCategory(
      id: $id
      title: $title
      categoryId: $categoryId
      categorySlug: $categorySlug
    ) {
      message
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation ($id: String!) {
    deleteCategory(id: $id) {
      message
    }
  }
`;

export {
  MAINCATEGORIES,
  CREATE_MAIN_CATEGORIES,
  DELETE_CATEGORY,
  UPDATE_MAIN_CATEGORY,
  MAINCATEGORY,
};
