import { gql } from "@apollo/client";

const SUB_CATEGORIES = gql`
  query {
    SubCategories {
      id
      categoryId
      categorySlug
      title
      slug
      products {
        productName
      }
      created_at
      updated_at
    }
  }
`;

const SUB_CATEGORY = gql`
  query ($id: String!) {
    SubCategory(id: $id) {
      id
      slug
      categoryId
      categorySlug
      title
      products {
        productName
        id
      }
      message
      created_at
      updated_at
    }
  }
`;

const CREATE_SUB_CATEGORY = gql`
  mutation CreateSubCategory(
    $title: String!
    $categoryId: String!
    $categorySlug: String!
  ) {
    createSubCategory(
      title: $title
      categoryId: $categoryId
      categorySlug: $categorySlug
    ) {
      message
    }
  }
`;

const UPDATE_SUB_CATEGORY = gql`
  mutation UpdateSubCategory(
    $id: String!
    $title: String!
    $categoryId: String!
    $categorySlug: String!
  ) {
    updateSubCategory(
      id: $id
      title: $title
      categoryId: $categoryId
      categorySlug: $categorySlug
    ) {
      message
    }
  }
`;

const SUB_CATE_BY_MAIN_SLUG = gql`
  query ($slug: String) {
    SubCategoryByCateSlug(slug: $slug) {
      title
      slug
      categoryId
      categorySlug
    }
  }
`;

export {
  SUB_CATE_BY_MAIN_SLUG,
  SUB_CATEGORIES,
  SUB_CATEGORY,
  CREATE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
};
