import React from "react";
import {
  Form,
  Input,
  message,
  Button,
  Spin,
  Layout,
  Row,
  Col,
  Select,
} from "antd";
import { useMutation, useQuery } from "@apollo/client";
import {
  MAINCATEGORY,
  UPDATE_MAIN_CATEGORY,
} from "../../graphql/main-categories";
import { CATEGORIES } from "../../graphql/categories";
import { useParams } from "react-router";

const { Content } = Layout;

const UpdateMainCategory = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { loading, error, data } = useQuery(MAINCATEGORY, {
    variables: {
      id,
    },
  });
  const { loading: loadingCate, data: dataCate } = useQuery(CATEGORIES);
  const [updateCategory] = useMutation(UPDATE_MAIN_CATEGORY);

  if (error) {
    console.error(error);
  }
  if (loading || loadingCate || !data || !dataCate) {
    return (
      <center>
        <Spin tip="Loading ..." />
      </center>
    );
  }

  const handleSubmit = (values) => {
    console.log(values);
    updateCategory({
      variables: {
        id,
        title: values.title,
        categoryId: values.categoryId,
      },
    })
      .then(async (res) => {
        message.success(res.data.updateMainCategory.message);
        window.location.replace("/dashboard/product/main-categories");
      })
      .catch((error) => {
        let err = JSON.parse(JSON.stringify(error));
        message.error(err.graphQLErrors[0].message);
      });
  };

  return (
    <React.Fragment>
      <Content>
        <div className="contentContainer-width">
          <h1 className="header-content">Update Main Category</h1>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            size="large"
            onFinish={handleSubmit}
            initialValues={data.mainCategory}
            defaultValue={data.maianCategory}
          >
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input category!",
                    },
                  ]}
                >
                  <Input className="schoolInput" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Category"
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: "Category is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    // mode=""
                    placeholder="Select a Categories"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataCate.categories.map((category) => {
                      return (
                        // eslint-disable-next-line react/jsx-no-undef
                        <Option value={category.id} key={category.id}>
                          {category.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="button-submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <br />
      </Content>
    </React.Fragment>
  );
};

export default UpdateMainCategory;
