import { GUEST_ORDERS, STATUS_CONTACT } from "../../graphql/order";
import { Popconfirm, Spin, Table, Tag, message } from "antd";
import { useMutation, useQuery } from "@apollo/client";

import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";

const Orders = () => {
  const [status_contact] = useMutation(STATUS_CONTACT);
  const { loading, error, data, refetch } = useQuery(GUEST_ORDERS);
  if (error) {
    console.error(error);
  }
  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..."></Spin>
      </center>
    );
  }

  console.log(data, "guest");
  const columns = [
    // {
    //   title: "Thumbnail",
    //   dataIndex: "products",
    //   key: "thumbnail",
    //   render: (products) => (
    //     <span>
    //       {products.map((res) => {
    //         return (
    //           <img
    //             style={{ borderRadius: "4px", paddingRight: "3px" }}
    //             height="40px"
    //             width="60px"
    //             src={res.thumbnail}
    //             alt="avatar"
    //           />
    //         );
    //       })}
    //     </span>
    //   ),
    // },
    {
      title: "Number",
      dataIndex: "id",
      key: "id",
      render: (data, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "csm",
      render: (fullname, data) => {
        return <div>{fullname}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "fullname",
      key: "csm",
      render: (fullname, data) => {
        return <div>{data.email}</div>;
      },
    },
    // {
    //   title: "Products",
    //   dataIndex: "productOrder",
    //   key: "productOrder",
    //   render: (productOrder) => {
    //     if (productOrder.length > 2) {
    //       return (
    //         <span>
    //           {productOrder.slice(0, 1).map((res) => {
    //             return (
    //               <span>
    //                 {res.products[0].productName.substring(0, 10) + "..."}
    //               </span>
    //             );
    //           })}
    //         </span>
    //       );
    //     } else {
    //       return (
    //         <span>
    //           {productOrder.map((res) => {
    //             return (
    //               <span>
    //                 {res.products[0].productName.substring(0, 10) + "..."}
    //                 {", "}
    //               </span>
    //             );
    //           })}
    //         </span>
    //       );
    //     }
    //   },
    // },
    // {
    //   title: "Total Price in PP",
    //   dataIndex: "amountOrder",
    //   key: "amountOrder",
    //   render: (amountOrder) => <span>$ {amountOrder}</span>,
    // },
    {
      title: "Total Price in PP",
      dataIndex: "productOrder",
      key: "productOrder",
      render: (productOrder) => {
        const data1 = [];
        // eslint-disable-next-line array-callback-return
        productOrder.map((item) => {
          item.products.map((res) => data1.push(item.qty * res.discountPrice));
        });
        const total = data1.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0);
        return <h1>${total.toFixed(2)}</h1>;
      },
    },
    {
      title: "Order Date/Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (data) => {
        return moment(parseInt(data)).format("Do MMM YYYY, hh:mm a");
      },
    },
    // {
    //   title: "Pcs.",
    //   dataIndex: "products",
    //   key: "products.",
    //   render: (products) => <span>{products.length}</span>,
    // },

    // {
    //   title: "Status",
    //   dataIndex: "contacted",
    //   key: "status",
    //   render: (contacted) => <span>{contacted === false && "hellos"}</span>,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "contacted",
    //   key: "action",
    //   render: (contacted, data) => {
    //     const { id } = data;
    //     return (
    //       <React.Fragment>
    //         {contacted === false ? (
    //           <Popconfirm
    //             title="Are you sure to delete this Blog？"
    //             onConfirm={() => {
    //               status_contact({ variables: { id, contacted: true } })
    //                 .then((res) => {
    //                   message.success(res.data.status_contact.message);
    //                   refetch();
    //                 })
    //                 .catch((error) => {
    //                   let err = JSON.parse(JSON.stringify(error));
    //                   message.error(err.graphQLErrors[0].message);
    //                 });
    //             }}
    //             okText="Yes"
    //             cancelText="No"
    //           >
    //             <Tag color="#08090a">Pending</Tag>
    //           </Popconfirm>
    //         ) : (
    //           <Popconfirm
    //             title="Are you sure to delete this Blog？"
    //             onConfirm={() => {
    //               status_contact({ variables: { id, contacted: false } })
    //                 .then((res) => {
    //                   message.success(res.data.status_contact.message);
    //                   refetch();
    //                 })
    //                 .catch((error) => {
    //                   let err = JSON.parse(JSON.stringify(error));
    //                   message.error(err.graphQLErrors[0].message);
    //                 });
    //             }}
    //             okText="Yes"
    //             cancelText="No"
    //           >
    //             <Tag color="#1890ff">Contaced</Tag>
    //           </Popconfirm>
    //         )}
    //       </React.Fragment>
    //     );
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "action",
      render: (status) => {
        // const lastElement = noted[noted.length - 1];
        return (
          <React.Fragment>
            {status === "confirmed" && <Tag color="#05c46b">{status}</Tag>}
            {status === "paid" && <Tag color="#7da753">{status}</Tag>}
            {status === "purchased" && <Tag color="#ff6b81">{status}</Tag>}
            {status === "shipped" && (
              <Tag color="#ffd32a">
                <span style={{ color: "black" }}>{status}</span>
              </Tag>
            )}
            {status === "delivered" && <Tag color="#0fbcf9">{status}</Tag>}
            {status === "cancelled" && <Tag color="#f53b57">{status}</Tag>}
          </React.Fragment>
        );
      },
    },
    {
      title: "Details",
      dataIndex: "id",
      key: "action",
      render: (id) => {
        return (
          <React.Fragment>
            <Link to={`/dashboard/guestOrder/${id}`}>
              <Tag color="#05c46b">View</Tag>
            </Link>

            <Popconfirm
              title="Are you sure to delete this Blog？"
              //   onConfirm={() => {
              //     deleteProduct({ variables: { id } })
              //       .then((res) => {
              //         message.success(res.data.deleteProduct.message);
              //         refetch();
              //       })
              //       .catch((error) => {
              //         let err = JSON.parse(JSON.stringify(error));
              //         message.error(err.graphQLErrors[0].message);
              //       });
              //   }}
              okText="Yes"
              cancelText="No"
              //   icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              {/* <Tag color="#ff4333">Delete</Tag> */}
            </Popconfirm>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <>
      <h2>Guest order list</h2>
      <div className="pie-box">
        <Table
          columns={columns}
          dataSource={data.guestOrders}
          pagination={false}
        />
      </div>
    </>
  );
};

export default Orders;
