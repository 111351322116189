import React from "react";
import { Layout, Row, Col, Table, Tag, Divider } from "antd";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUOATATOIN } from "../../graphql/quotation";
import moment from "moment";

const { Content } = Layout;
const ViewQuotation = () => {
  const { id } = useParams();

  const { loading, data } = useQuery(QUOATATOIN, {
    variables: { id },
  });

  if (loading) return "loading...";

  const columns = [
    {
      title: "Number",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Prduct Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (thumbnail, data) => {
        return (
          // <a href={`${data.lilnk}`} target="">

          <img
            style={{ borderRadius: "4px" }}
            height="40px"
            width="40px"
            src={`http://localhost:60011/${thumbnail[0]}`}
            alt="avatar"
          />
          // </a>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "qty",
      render: (products, data) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <Tag color="orange-inverse">{data.qty}</Tag>
          </div>
        );
      },
    },
    {
      title: "Price after Tax",
      dataIndex: "priceAfterTax",
      key: "priceAfterTax",
    },
    {
      title: "Shipping",
      dataIndex: "shipping",
      key: "shipping",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Price in Cambodia",
      dataIndex: "priceInKh",
      key: "priceInKh",
    },
  ];

  return (
    <div>
      <Content>
        <div className="contentContainer-width">
          <Row>
            <Col span={8}>
              <h2>Quote Summary</h2>
              <p>ID: {data.quotation.JBID}</p>
              <p>Status : {data.quotation.status}</p>
              <p>
                Date :{" "}
                {moment(parseInt(data.quotation.created_at)).format(
                  "Do MMM YYYY, hh:mm"
                )}
              </p>
              <Divider orientation="left" plain>
                <h1>Customer Descriptions</h1>
              </Divider>
              <p>{data.quotation.request_quote.descriptions}</p>
            </Col>
            <Col span={8}>
              <h2>Customer Address</h2>
              <p>Address: {data.quotation.customerAddress}</p>
            </Col>
            <Col span={8}>
              <h2>Recipient</h2>
              <p>Name: {data.quotation.customerName}</p>
              <p>Email: {data.quotation.customerEmail}</p>
              <p>Phone: {data.quotation.customerPhone}</p>
            </Col>
          </Row>
        </div>
        <br />

        <Table columns={columns} dataSource={data.quotation.product} />
      </Content>
    </div>
  );
};

export default ViewQuotation;
