import { Col, Row, Spin, Table } from "antd";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { TRACKING } from "../graphql/tracking";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";

const Tracking = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(TRACKING, {
    variables: { id },
  });
  // const { loading: loadingTracking, data: dataTracking } = useQuery(TRACKING);
  if (error) {
    console.error(error);
  }

  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..." />
      </center>
    );
  }
  const exportPDF = () => {
    const input = document.getElementById("App");
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 208;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("tracking.pdf");
    });
  };

  const {
    trackingNumber,
    packageType,
    weight,
    cost,
    insurance,
    shippingCountry,
    estimate,
    weightChange,
    shipping,
    payment,
    costChange,
    user,
    trackingId,
  } = data.tracking;

  const datasource = [
    {
      key: "1",
      trackingNumber: trackingNumber,
      packageType: packageType,
      weight: weight,
      cost: cost,
      insurance: insurance,
      shippingCountry: shippingCountry,
      estimate: estimate,
      weightChange: weightChange,
      shipping: shipping,
      payment: payment,
      costChange: costChange,
      user: user,
      trackingId: trackingId,
    },
  ];

  const columns = [
    {
      title: "Tracking ID",
      dataIndex: "trackingId",
      key: "trackingId",
    },
  ];

  return (
    <React.Fragment>
      <header
        id="App"
        // className="tracking-pdf"
        // className="App-header"
      >
        {user.address === null && "address null"}
        <div>
          <h2>Tracking ID: {trackingId}</h2>
          <h2>Name: {user.fullname}</h2>
          <h2>Email: {user.email}</h2>
          <h2>Phone: {user.address.phone}</h2>
          <h2>Address: {user.address.address}</h2>
        </div>
        <br />
        <br />
        <Row>
          <Col span={4}>Tracking Number</Col>
          <Col span={4}>Package Name</Col>
          <Col span={2}>Weight</Col>
          <Col span={2}>Cost</Col>
          <Col span={2}>Insurance</Col>
          <Col span={4}>Estimate</Col>
          <Col span={2}>ShippingCountry</Col>
          <Col span={2}>Shipping</Col>
          <Col span={2}>Total Cost</Col>
        </Row>
        <hr />
        <Row>
          <Col span={4}>{trackingNumber}</Col>
          <Col span={4}>{packageType}</Col>
          <Col span={2}> {weightChange === 0 ? weight : weightChange} kg</Col>
          <Col span={2}>${costChange === 0 ? cost : costChange}</Col>
          <Col span={2}>{insurance}%</Col>
          <Col span={4}>{estimate}</Col>
          <Col span={2}>{shippingCountry}</Col>
          <Col span={2}>${shipping}</Col>
          <Col span={2}>${payment}</Col>
        </Row>
      </header>
      {/* <header id="App">
        <Table dataSource={datasource} columns={columns} pagination={false} />
      </header> */}
      <center>
        <button
          style={{ marginTop: "100px" }}
          type="primary"
          onClick={() => exportPDF()}
        >
          Print Pdf
        </button>
      </center>
    </React.Fragment>
  );
};

export default Tracking;
