import { gql } from "@apollo/client";

// const CREATE_QUOAT = gql`
//   mutation (
//     $customerName: String
//     $customerEmail: String
//     $customerPhone: String
//     $customerAddress: String
//     $product: [ProductQuote]
//     $productName: String
//     $thumbnail: String
//     $qty: Float
//     $priceAfterTax: Float
//     $shipping: Float
//     $priceInKh: Float
//     $discount: Float
//     $weight: Float
//     $status: String
//     $link: String
//     $brandName: String
//     $categoriesId: [String]
//     $descriptions: String
//   ) {
//     create_quotation(
//       product: $product
//       customerName: $customerName
//       customerEmail: $customerEmail
//       customerPhone: $customerPhone
//       customerAddress: $customerAddress
//       thumbnail: $thumbnail
//       productName: $productName
//       qty: $qty
//       priceAfterTax: $priceAfterTax
//       shipping: $shipping
//       priceInKh: $priceInKh
//       discount: $discount
//       weight: $weight
//       status: $status
//       link: $link
//       brandName: $brandName
//       categoriesId: $categoriesId
//       descriptions: $descriptions
//     ) {
//       message
//     }
//   }
// `;

// // const CREATE_QUOAT = gql`
// //   mutation (
// //     $customerName: String
// //     $customerEmail: String
// //     $customerPhone: String
// //     $customerAddress: String
// //     $product: [String]
// //   ) {
// //     create_quotation(
// //       customerName: $customerName
// //       customerEmail: $customerEmail
// //       customerPhone: $customerPhone
// //       customerAddress: $customerAddress
// //       product: [$product]
// //     ) {
// //       message
// //     }
// //   }
// // `;
const QUOATATOINS = gql`
  query {
    quotations {
      id
      JBID
      type
      status
      customerId
      customerName
      customerEmail
      customerPhone
      customerAddress
      created_at
      request_quote {
        descriptions
        JBID
      }
      product {
        productName
        thumbnail
        qty
        priceAfterTax
        shipping
        priceInKh
        discount
        weight
        descriptions
        brandName
        categoriesId
        link
      }
    }
  }
`;

const DELETE_QUOTATION = gql`
  mutation ($id: String) {
    delete_quoatation(id: $id) {
      message
    }
  }
`;

const QUOATATOIN = gql`
  query ($id: String) {
    quotation(id: $id) {
      id
      JBID
      status
      customerId
      customerName
      customerEmail
      customerPhone
      customerAddress
      created_at
      request_quote {
        descriptions
      }
      product {
        productName
        thumbnail
        qty
        priceAfterTax
        shipping
        priceInKh
        discount
        weight
        descriptions
        brandName
        categoriesId
        link
      }
    }
  }
`;

const REQUEST_QUOTE = gql`
  query {
    request_quote_users {
      id
      status
      phone
      JBID
      fullname
      email
      userId
      descriptions
      created_at
      link
    }
  }
`;
const REQUEST_QUOTE_BY_ID = gql`
  query ($id: String) {
    request_quote(id: $id) {
      id
      status
      phone
      JBID
      fullname
      email
      userId
      descriptions
      created_at
      link
    }
  }
`;
const UPDATE_STATUS_QUOTE = gql`
  mutation ($id: String, $status: String) {
    update_status_quote(id: $id, status: $status) {
      message
    }
  }
`;

export {
  QUOATATOINS,
  DELETE_QUOTATION,
  QUOATATOIN,
  REQUEST_QUOTE,
  REQUEST_QUOTE_BY_ID,
  UPDATE_STATUS_QUOTE,
};
