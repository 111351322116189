import { gql } from "@apollo/client";

const CATEGORIES = gql`
  query {
    categories {
      slug
      id
      title
      products {
        productName
      }
      subCategoriesSlug {
        id
        slug
        title
      }
      SubCategories_a_slug {
        title
        categoryId
        slug
      }
      subCategoryId
      subCategories {
        id
        title
      }
      created_at
      updated_at
    }
  }
`;

const CATEGORY = gql`
  query ($id: String!) {
    category(id: $id) {
      id
      title
      subCategoriesSlug {
        id
        slug
        title
      }
      subCategoryId
      subCategories {
        id
        title
      }
    }
  }
`;

// const CREATE_CATEGORIES = gql`
//   mutation ($title: String!) {
//     createCategory(title: $title) {
//       message
//     }
//   }
// `;

const CREATE_CATEGORIES = gql`
  mutation ($title: String!) {
    createCategory(title: $title) {
      message
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation ($id: String!, $title: String!) {
    updateCategory(id: $id, title: $title) {
      message
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation ($id: String!) {
    deleteCategory(id: $id) {
      message
    }
  }
`;

export {
  CATEGORIES,
  CREATE_CATEGORIES,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  CATEGORY,
};
