import { Button, Layout, Modal } from "antd";

import BlogsCategories from "./categories";
import CreateBlog from "./create-category";
import React from "react";

const { Content } = Layout;

const Categories = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <Content>
        <Modal
          title="Create Category"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
        >
          <CreateBlog setIsModalOpen={setIsModalOpen} />
        </Modal>
        {/* <div className="contentContainer-width">
          <CreateBlog />
        </div> */}
        <div>
          <div className="flex between">
            <h1 className="header-content">Categories</h1>
            <Button type="primary" onClick={showModal}>
              New Category
            </Button>
          </div>
          <BlogsCategories />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Categories;
