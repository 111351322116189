import React, { useState } from "react";
import {
  Layout,
  Form,
  Input,
  Row,
  Col,
  message,
  Upload,
  Button,
  Select,
  Spin,
  Space,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  InboxOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { SUB_CATEGORIES } from "../../graphql/sub-category";
import { BRANDS } from "../../graphql/brand";
import { CREATE_PRODUCT } from "../../graphql/products";
import { useQuery, useMutation } from "@apollo/client";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Icon, { UploadOutlined } from "@ant-design/icons";
const { Content } = Layout;
const { Option } = Select;
const { Dragger } = Upload;
const URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PRO_API;

const UplaodTestimonial = () => {
  const [slug, setSlug] = useState([]);
  const [value, setValue] = useState("");
  const [pri, setPri] = useState(0);
  const [wieght, setWieght] = useState(0);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [createProduct] = useMutation(CREATE_PRODUCT);
  const [thumbnail, setThumbnail] = useState([]);
  const { loading, data } = useQuery(SUB_CATEGORIES);
  // const [state, setState] = useState({
  //   imageUrl: [],
  //   loading: false,
  // });

  console.log(thumbnail, "thumba");
  console.log(fileList, "fileList");
  const { loading: brandLoading, data: brandData } = useQuery(BRANDS);

  // const handleChange = (info) => {
  //   if (info.file.status === "uploading") {
  //     setState({ loading: true });
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     setState({
  //       // imageUrl: info.file.response.imageUrl,
  //       imageUrl: info.fileList.map(
  //         (res) => `${URL}/${res.response.file[0].path}`
  //       ),
  //       loading: false,
  //     });
  //   }
  // };

  const handleChange = (info) => {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setThumbnail(
        info.fileList.map((res) => `${URL}/${res.response.file[0].path}`)
      );
    } else if (info.file.status === "error") {
      // setImageUrl(info)
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleChanges = (value) => {
    setSlug(value);
  };

  const handleSubmit = async (values) => {
    console.log(values);

    if (fileList) {
      let _images = [];
      fileList.map((image) => {
        _images.push(image.response.imageUrl);
      });

      createProduct({
        variables: {
          userId: "1",
          descriptions: value,
          productName: values.name ? values.name : "",
          // originalPrice: parseInt(values.price) ? parseInt(values.price) : "",
          originalPrice:
            pri * 0.12 + wieght * 10 + Number(pri)
              ? pri * 0.12 + wieght * 10 + Number(pri)
              : "",
          usPrice: Number(pri),
          weight: Number(wieght),
          // clearance: values.clearance ? values.clearance : "",
          // shippingCost: values.shipping ? parseInt(values.shipping) : 0,
          shippingCost: pri * 0.12 + wieght * 10 ? pri * 0.12 + wieght * 10 : 0,
          // star: values.star ? parseInt(values.star) : "",
          rating: values.rating ? parseInt(values.rating) : 0,
          thumbnail: thumbnail,
          asin: values.asin ? values.asin : "",
          discount: values.discount ? parseInt(values.discount) : 0,
          // imagesURL: values.imagesURL ? values.imagesURL : [],
          link: values.link ? values.link : "",
          categoriesId: values.categoriesId ? values.categoriesId : [],
          categoriesSlug: slug ? slug : [],
          details: values.details ? values.details : "",
          descriptions: values.descriptions ? values.descriptions : "",
          brandName: values.brandName ? values.brandName : "",
          imagesURL: values.imagesURL.map((res) => res.imagesURL),
          // imagesURL: values.imagesURL ? values.imagesURL : "",
        },
      })
        .then(async (res) => {
          // instanceRef.current.clear();
          // setState({ imageUrl: null });
          form.resetFields();
          await message.success(res.data.createProduct.message, 3);
        })
        .catch((error) => console.error(error));
    }
  };

  if (loading || brandLoading || !data) {
    return (
      <center>
        <Spin tip="Loading..."></Spin>
      </center>
    );
  }

  return (
    <React.Fragment>
      <Content>
        <div className="contentContainer-width">
          <h1 className="header-content">Create Your Products</h1>
          <Form
            name="basic"
            layout="vertical"
            size="large"
            onFinish={handleSubmit}
            form={form}
          >
            <Row gutter={[32, 0]}>
              <Col span={20}>
                <Row gutter={[12, 12]}>
                  <Col span={8}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Name is required!",
                        },
                      ]}
                    >
                      <Input className="schoolInput" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Main Price"
                      // name="price"
                      rules={[
                        {
                          required: true,
                          message: "Price is required!",
                        },
                      ]}
                    >
                      <Input
                        name="price"
                        style={{ color: "purple" }}
                        disabled
                        value={(pri * 0.12 + wieght * 10 + Number(pri)).toFixed(
                          2
                        )}
                        type="number"
                        className="schoolInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Shipping"
                      // name="shipping"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Shipping is required!',
                      //   },
                      // ]}
                    >
                      <Input
                        disabled
                        value={(pri * 0.12 + wieght * 10).toFixed(2)}
                        type="number"
                        className="schoolInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[12, 12]}>
                  <Col span={8}>
                    <Form.Item
                      // onChange={(e) => setPri(e.target.name)}
                      // value={pri}
                      label="Price"
                      // name="pri"
                    >
                      <Input
                        type="number"
                        // onChange={(e) =>
                        //   setPri(e.target.value.replace(/\D/g, ""))
                        // }
                        onChange={(e) => setPri(e.target.value)}
                        value={pri}
                        name="pri"
                        className="schoolInput"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Weight">
                      <Input
                        type="number"
                        step="0.1"
                        // onChange={(e) =>
                        //   setWieght(e.target.value.replace(/\D/g, ""))
                        // }
                        onChange={(e) => setWieght(e.target.value)}
                        value={wieght}
                        name="wieght"
                        className="schoolInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Sub-category"
                      name="categoriesId"
                      rules={[
                        {
                          required: true,
                          message: "Category is required!",
                        },
                      ]}
                    >
                      <Select
                        onChange={handleChanges}
                        showSearch
                        placeholder="Select a Categories"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {data.SubCategories.map((category) => {
                          return (
                            <Option
                              onClick={() => setSlug(category.slug)}
                              value={category.slug}
                              key={category.id}
                            >
                              {/* <div
                              // onClick={() => setSlug(category.slug)}
                              > */}
                              {category.title}
                              {/* </div> */}
                            </Option>
                          );
                        })}

                        {/* <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="tom">Tom</Option> */}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Discount" name="discount">
                      <Input type="number" className="schoolInput" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Asin" name="asin">
                      <Input className="schoolInput" />
                    </Form.Item>
                  </Col>
                  <Col span={24 / 3}>
                    {/* <Form.Item label="Brand" name="brandName">
                      <Input className="schoolInput" />
                    </Form.Item> */}
                    <Form.Item
                      label="Brand name"
                      name="brandName"
                      rules={[
                        {
                          required: true,
                          message: "Brandname is required!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a brand name"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {brandData.brands.map((brand) => {
                          return (
                            <Option value={brand.title} key={brand.id}>
                              {brand.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Rating" name="rating">
                      <Input className="schoolInput" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Link" name="link">
                      <Input className="schoolInput" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.List name="imagesURL">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...resetField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                label="Image-Link"
                                name={[name, "imagesURL"]}
                              >
                                <Input className="schoolInput" />
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              size="small"
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add field
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>

                    {/* </Form.Item> */}
                  </Col>
                </Row>
                <Form.Item label="description" name="descriptions">
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    placeholder="Write something..."
                  />
                  {/* <Input.TextArea className="schoolInput" /> */}
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item label="Thumbnail" name="thumbnail">
                  {/* <Upload
                    name="thumbnail"
                    action={`${URL}/upload/images`}
                    listType="picture"
                    multiple="true"
                    // beforeUpload={beforeUpload}
                    onChange={handleChange}
                    withCredentials={true}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload> */}
                  <Upload
                    multiple="true"
                    name="multi-files"
                    listType="text"
                    className="avatar-uploader"
                    showUploadList={true}
                    action={`${URL}/multiple-upload`}
                    onChange={handleChange}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  {/* <Upload
                    name="multi-files"
                    multiple="true"
                    // action="http://localhost:60011/multiple-upload"
                    listType="picture"
                    onChange={handleChange}
                    // defaultFileList={[state.imageUrl]}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload> */}
                  <div style={{ color: "red", fontSize: "13px" }}>
                    The size must under 1MB
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="button-submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button type="text" className="button-cancel">
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default UplaodTestimonial;
