import React from "react";
import { Form, Input, message, Button } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { BRANDS, CREATE_BRAND } from "../../graphql/brand";

const CreateBrand = () => {
  const [form] = Form.useForm();
  const { refetch } = useQuery(BRANDS);

  const [createbrand] = useMutation(CREATE_BRAND);

  const handleSubmit = (values) => {
    createbrand({
      variables: {
        title: values.title,
      },
    })
      .then((res) => {
        message.success(res.data.create_brand.message);
        refetch();
        form.resetFields();
      })
      .catch((error) => {
        let err = JSON.parse(JSON.stringify(error));
        message.error(err.graphQLErrors[0].message);
        form.resetFields();
      });
  };

  return (
    <React.Fragment>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        size="large"
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: "Title is required!",
            },
          ]}
        >
          <Input className="schoolInput" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="button-submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default CreateBrand;
