import { gql } from "@apollo/client";

const PRODUCTS = gql`
  query {
    products {
      imagesURL
      id
      shippingCost
      productName
      originalPrice
      shippingCost
      discount
      categoriesId
      approved
      rating
      asin
      created_at
      updated_at
      #categories {
      #  id
      # title
      # }
      usPrice
      weight
      brandName
      thumbnail
      link
      clearanceCost
    }
  }
`;
const PRODUCT_NOT_APPROVE = gql`
  query {
    get_products_not_approve {
      imagesURL
      id
      shippingCost
      productName
      originalPrice
      shippingCost
      discount
      categoriesId
      approved
      rating
      asin
      created_at
      updated_at
      #categories {
      #  id
      # title
      # }
      usPrice
      weight
      brandName
      thumbnail
      link
      clearanceCost
    }
  }
`;
const PRODUCT_BEST_SELLER = gql`
  query {
    get_products_bestSeller {
      imagesURL
      id
      shippingCost
      productName
      originalPrice
      shippingCost
      discount
      categoriesId
      approved
      rating
      asin
      created_at
      updated_at
      #categories {
      #  id
      # title
      # }
      usPrice
      weight
      brandName
      thumbnail
      link
      clearanceCost
    }
  }
`;
const PRODUCT = gql`
  query ($id: String) {
    product(id: $id) {
      bestSeller
      imagesURL
      id
      color
      size
      categoriesSlug
      descriptions
      shippingCost
      productName
      originalPrice
      shippingCost
      discountPrice
      discount
      mainCategoriesId
      mainCategoriesSlug
      categoriesId
      rating
      asin
      wheres
      # categories {
      #  id
      #  title
      # }
      usPrice
      weight
      brandName
      thumbnail
      link
      approved
      clearanceCost
    }
  }
`;

const CREATE_PRODUCT_1 = gql`
  mutation (
    $name: String!
    $description: String
    $categoriesId: [String!]
    $thumbnail: String!
    $price: Int!
  ) {
    createProduct(
      name: $name
      description: $description
      categoriesId: $categoriesId
      thumbnail: $thumbnail
      price: $price
    ) {
      message
    }
  }
`;
const UPDATE_PRODUCT = gql`
  mutation (
    $bestSeller: Boolean
    $color: [String]
    $size: [String]
    $id: String!
    $productName: String!
    $originalPrice: Float!
    $discountPrice: Float
    $shippingCost: Float
    $rating: Int
    $thumbnail: [String]!
    $asin: String
    $discount: Float!
    $imagesURL: [String!]
    $categoriesId: [String!]
    $categoriesSlug: [String!]
    $mainCategoriesId: [String!]
    $mainCategoriesSlug: [String!]
    $link: String
    $brandName: String
    $usPrice: Float
    $weight: Float
    $approved: Boolean
    $descriptions: String!
    $wheres: String!
  ) {
    updateProduct(
      bestSeller: $bestSeller
      color: $color
      size: $size
      id: $id
      productName: $productName
      discountPrice: $discountPrice
      originalPrice: $originalPrice
      shippingCost: $shippingCost
      rating: $rating
      thumbnail: $thumbnail
      asin: $asin
      discount: $discount
      imagesURL: $imagesURL
      categoriesId: $categoriesId
      categoriesSlug: $categoriesSlug
      mainCategoriesId: $mainCategoriesId
      mainCategoriesSlug: $mainCategoriesSlug
      link: $link
      usPrice: $usPrice
      weight: $weight
      brandName: $brandName
      approved: $approved
      descriptions: $descriptions
      wheres: $wheres
    ) {
      message
    }
  }
`;
const DELETE_PRODUCT = gql`
  mutation ($id: String!) {
    deleteProduct(id: $id) {
      message
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation (
    $bestSeller: Boolean
    $color: [String]
    $size: [String]
    $productName: String!
    $originalPrice: Float!
    $shippingCost: Float!
    $rating: Int!
    $thumbnail: [String!]
    $asin: String!
    $discount: Float!
    $imagesURL: [String!]
    $categoriesId: [String!]
    $categoriesSlug: [String!]
    $mainCategoriesId: [String!]
    $mainCategoriesSlug: [String!]
    $link: String!
    $details: String!
    $descriptions: String!
    $brandName: String!
    $usPrice: Float
    $weight: Float
    $discountPrice: Float
    $wheres: String
  ) {
    createProduct(
      bestSeller: $bestSeller
      color: $color
      size: $size
      productName: $productName
      originalPrice: $originalPrice
      shippingCost: $shippingCost
      rating: $rating
      thumbnail: $thumbnail
      asin: $asin
      discount: $discount
      imagesURL: $imagesURL
      categoriesId: $categoriesId
      categoriesSlug: $categoriesSlug
      mainCategoriesId: $mainCategoriesId
      mainCategoriesSlug: $mainCategoriesSlug
      link: $link
      details: $details
      descriptions: $descriptions
      brandName: $brandName
      usPrice: $usPrice
      weight: $weight
      discountPrice: $discountPrice
      wheres: $wheres
    ) {
      message
    }
  }
`;

export {
  PRODUCTS,
  PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  CREATE_PRODUCT_1,
  PRODUCT_NOT_APPROVE,
  PRODUCT_BEST_SELLER,
};
