import { BANNER, UPDATE_BANNER } from "../../graphql/banner";
import { Button, Form, Input, Upload, message } from "antd";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import beforeUpload from "../../libs/beforeUpload";
import { useParams } from "react-router";

const UpdateBanner = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [state, setState] = useState({
    imageUrl: null,
    loading: false,
  });
  const { loading, data } = useQuery(BANNER, {
    variables: {
      id,
    },
  });

  const [updatebanner] = useMutation(UPDATE_BANNER);
  const handleChange = (info) => {
    console.log(info.file);
    if (info.file.status === "uploading") {
      setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      setState({
        imageUrl: info.file.response.imageUrl,
        loading: false,
      });
    }
  };
  if (loading || !data) {
    return "loading...";
  }
  const { image } = data.banner;
  const handleSubmit = (values) => {
    updatebanner({
      variables: {
        id: id,
        image: `${state.imageUrl === null ? image : state.imageUrl}`,
        link: values.link === "" ? data.banner.link : values.link,
      },
    })
      .then((res) => {
        message.success(res.data.update_banner.message);
        window.location.replace("/dashboard/create/banner");
      })
      .catch((error) => {
        let err = JSON.parse(JSON.stringify(error));
        message.error(err.graphQLErrors[0].message);
        form.resetFields();
      });
  };

  return (
    <React.Fragment>
      <h2>Update Banner</h2>
      <div className="pie-box">
        <Form
          name="basic"
          form={form}
          layout="vertical"
          size="large"
          onFinish={handleSubmit}
          initialValues={data.banner}
        >
          <Form.Item label="Thumbnail">
            <Upload
              name="thumbnail"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              // action="http://localhost:60011/upload/image"
              action="https://server.jabaram.com/upload/image"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {state.imageUrl ? (
                <img
                  src={state.imageUrl}
                  alt="thumbnail"
                  style={{ width: "120px", height: "100px" }}
                />
              ) : (
                <img
                  src={`${image}`}
                  alt="thumbnail"
                  style={{ width: "120px", height: "100px" }}
                />
              )}
            </Upload>
            <div style={{ color: "red", fontSize: "13px" }}>
              The size must under 1MB. Size: 315 × 200
            </div>
          </Form.Item>
          <Form.Item name="link" label="Link ">
            <Input className="schoolInput" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="button-submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default UpdateBanner;
