import { ORDERS, STATUS_CONTACT } from "../../graphql/order";
import { QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Popconfirm,
  Spin,
  Table,
  Tag,
  Typography,
  Input,
  Button,
  Space,
} from "antd";
import { useMutation, useQuery } from "@apollo/client";
import Highlighter from "react-highlight-words";

import { Link } from "react-router-dom";
import React, { useState, useRef } from "react";
import moment from "moment";

const { Paragraph } = Typography;

const Orders = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [status_contact] = useMutation(STATUS_CONTACT);
  const { loading, error, data, refetch } = useQuery(ORDERS);
  if (error) {
    console.error(error);
  }
  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..."></Spin>
      </center>
    );
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  console.log(data, "dat");

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "id",
      render: (orderId) => {
        return <div>{orderId}</div>;
      },
      // ...getColumnSearchProps("id"),
    },
    {
      title: "FullName",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (datas, data) => {
        console.log(datas, "data");
        const { fullname } = data.customer_name;
        return <div>{datas.fullname}</div>;
      },
      // ...getColumnSearchProps("fullname"),
    },
    {
      title: "Email",
      dataIndex: "customer_name",
      key: "csm",
      render: (customer_name, data) => {
        return <Paragraph copyable>{customer_name.email}</Paragraph>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "customer_name",
      key: "csm",
      render: (customer_name, data) => {
        return (
          <Paragraph copyable>
            {data.addressOrder.phone === ""
              ? "no phone"
              : data.addressOrder.phone}
          </Paragraph>
        );
      },
    },
    {
      title: "Total Price in PP",
      dataIndex: "productOrder",
      key: "productOrder",
      render: (productOrder) => {
        const data1 = [];
        // eslint-disable-next-line array-callback-return
        productOrder.map((item) => {
          item.products.map((res) => data1.push(item.qty * res.discountPrice));
        });
        const total = data1.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0);
        return <h1>$ {total.toFixed(2)}</h1>;
      },
    },
    {
      title: "Order Date/Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (data) => {
        return moment(parseInt(data)).format("Do MMM YYYY, hh:mm a");
      },
    },
    {
      title: "Status",
      dataIndex: "noted",
      key: "action",
      render: (noted) => {
        const lastElement = noted[noted.length - 1];
        return (
          <React.Fragment>
            {lastElement.status === "confirmed" && (
              <Tag color="#434343">{lastElement.status}</Tag>
            )}
            {lastElement.status === "paid" && (
              <Tag color="#7da753">{lastElement.status}</Tag>
            )}
            {lastElement.status === "purchased" && (
              <Tag color="#e56f1a">{lastElement.status}</Tag>
            )}
            {lastElement.status === "shipped" && (
              <Tag color="#fffe30">
                <span style={{ color: "black" }}>{lastElement.status}</span>
              </Tag>
            )}
            {lastElement.status === "delivered" && (
              <Tag color="#2f57ca">{lastElement.status}</Tag>
            )}
            {lastElement.status === "cancelled" && (
              <Tag color="#e01b13">{lastElement.status}</Tag>
            )}
          </React.Fragment>
        );
      },
    },

    {
      title: "Details",
      dataIndex: "id",
      key: "action",
      render: (id) => {
        return (
          <React.Fragment>
            <Link to={`/dashboard/order/${id}`}>
              <Tag color="#5339d1">View</Tag>
            </Link>

            <Popconfirm
              title="Are you sure to delete this order？"
              okText="Yes"
              cancelText="No"
            ></Popconfirm>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <>
      <h2>Customer orders list</h2>
      <div className="pie-box">
        <Table
          columns={columns}
          dataSource={data.get_orders}
          pagination={true}
        />
      </div>
    </>
  );
};

export default Orders;
