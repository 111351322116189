import { Modal, Popconfirm, Spin, Table, Tag, message } from "antd";
import React, { useState } from "react";
import { TRACKING, TRACKINGS } from "../graphql/tracking";

import { Link } from "react-router-dom";
import moment from "moment";
import { useQuery } from "@apollo/client";

const Trackings = () => {
  const [noted, setNoted] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loading, error, data } = useQuery(TRACKINGS);
  // const { loading: loadingTracking, data: dataTracking } = useQuery(TRACKING);
  if (error) {
    console.error(error);
  }

  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..." />
      </center>
    );
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Tracking Number",
      dataIndex: "trackingNumber",
      key: "title",
    },
    {
      title: "Shipping Country",
      dataIndex: "shippingCountry",
      key: "title",
    },
    {
      title: "Package",
      dataIndex: "packageType",
      key: "title",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "title",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "title",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "title",
      render: (res, data) => {
        return (
          <>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setNoted(data);
                setIsModalOpen(true);
              }}
            >
              {res.substring(0, 2) + "..."}
            </p>
          </>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "user",
      key: "title",
      render: (user) => {
        return user.fullname;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(parseInt(created_at)).format("Do MMMM YYYY");
      },
    },

    {
      title: "Status",
      dataIndex: "id",
      render: (id, data) => {
        return (
          <React.Fragment>
            {/* <Popconfirm
              title="Are you sure to delete this category？"
                onConfirm={() => {
                  deleteCategory({ variables: { id } })
                    .then((res) => {
                      message.success(res.data.deleteCategory.message);
                      refetch();
                    })
                    .catch((error) => {
                      let err = JSON.parse(JSON.stringify(error));
                      message.error(err.graphQLErrors[0].message);
                    });
                }}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
            </Popconfirm> */}
            <div style={{ display: "flex" }}>
              <Tag color="#ffa502">{data.status}</Tag>
            </div>
          </React.Fragment>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "id",
      render: (id) => {
        return (
          <Link to={`/dashboard/preview/tracking/detail/${id}`}>
            <Tag style={{ cursor: "pointer" }} color="#2ed573">
              View
            </Tag>
          </Link>
        );
      },
    },
  ];
  console.log(noted);

  return (
    <React.Fragment>
      <Modal
        title={
          Object.keys(noted).length === 0 ? "No Object" : noted.user.fullname
        }
        open={isModalOpen}
        // onOk={handleOk}
        footer={false}
        onCancel={handleCancel}
      >
        <p>{noted.note}</p>
      </Modal>
      <h2 className="header-content">Trackings</h2>
      <Table columns={columns} dataSource={data.trackings} />
    </React.Fragment>
  );
};

export default Trackings;
