// import React from "react";
// import { Row, Col, Spin } from "antd";
// import Countup from "react-countup";
// import { TeamOutlined, ControlOutlined, TagOutlined } from "@ant-design/icons";
// import { PRODUCTS } from "../../graphql/products";
// import { GET_USERS } from "../../graphql/user";
// import { CATEGORIES } from "../../graphql/categories";

// import { useQuery } from "@apollo/client";

// const Loading = () => {
//   return (
//     <div>
//       <center>
//         <Spin tip="Loading ..."></Spin>
//       </center>
//     </div>
//   );
// };

// const TotalCart = () => {
//   const { loading, data } = useQuery(GET_USERS);
//   if (loading || !data) {
//     return <Loading />;
//   }

//   const User = () => {
//     return (
//       <React.Fragment>
//         <Row className="widget-card">
//           <Col span={10}>
//             <TeamOutlined className="background-image-widget" />
//           </Col>
//           <Col span={14}>
//             <Countup end={data.users.length} className="counter" />
//             <h1 className="text-details">Total Users</h1>
//           </Col>
//         </Row>
//       </React.Fragment>
//     );
//   };
//   const Courses = () => {
//     const { loading, data } = useQuery(PRODUCTS);
//     if (loading || !data) {
//       return <Loading />;
//     }
//     return (
//       <React.Fragment>
//         <Row className="widget-card2">
//           <Col span={10}>
//             <TagOutlined className="background-image-widget2" />
//           </Col>
//           <Col span={14}>
//             <Countup end={data.products.length} className="counter2" />

//             <h1 className="text-details2">Total Products</h1>
//           </Col>
//         </Row>
//       </React.Fragment>
//     );
//   };
//   const CouresePaid = () => {
//     return (
//       <React.Fragment>
//         <Row className="widget-card3">
//           <Col span={10}>
//             <TeamOutlined className="background-image-widget3" />
//           </Col>
//           <Col span={14}>
//             <Countup end={data.users.length} className="counter" />
//             <h1 className="text-details3">Total Members</h1>
//           </Col>
//         </Row>
//       </React.Fragment>
//     );
//   };
//   const Admin = () => {
//     const { loading, data } = useQuery(CATEGORIES);
//     if (loading || !data) {
//       return <Loading />;
//     }
//     return (
//       <React.Fragment>
//         <Row className="widget-card4">
//           <Col span={10}>
//             <ControlOutlined className="background-image-widget4" />
//           </Col>
//           <Col span={14}>
//             <Countup end={data.categories.length} className="counter4" />
//             {/* <Countup end={100} className="counter4" /> */}

//             <h1 className="text-details4">Total Categories</h1>
//           </Col>
//         </Row>
//       </React.Fragment>
//     );
//   };

//   return (
//     <React.Fragment>
//       <Row gutter={[24, 24]}>
//         <Col xs={24} sm={24} lg={12} xl={6}>
//           <User />
//         </Col>
//         <Col xs={24} sm={24} lg={12} xl={6}>
//           <Courses />
//         </Col>
//         <Col xs={24} sm={24} lg={12} xl={6}>
//           <CouresePaid />
//         </Col>
//         <Col xs={24} sm={24} lg={12} xl={6}>
//           <Admin />
//         </Col>
//       </Row>
//     </React.Fragment>
//   );
// };
// export default TotalCart;

import React from "react";
import { Row, Col, Spin } from "antd";
import Countup from "react-countup";
import { TeamOutlined, ControlOutlined, TagOutlined } from "@ant-design/icons";
import { PRODUCTS } from "../../graphql/products";
import { GET_USERS, EMPLOYEES } from "../../graphql/user";
import { CATEGORIES } from "../../graphql/categories";

import { useQuery } from "@apollo/client";

const Loading = () => {
  return (
    <div>
      <center>
        <Spin tip="Loading ..."></Spin>
      </center>
    </div>
  );
};

const TotalCart = () => {
  const { loading, data } = useQuery(GET_USERS);
  if (loading || !data) {
    return <Loading />;
  }

  const User = () => {
    const { loading, data } = useQuery(EMPLOYEES);
    if (loading || !data) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        <div className="widget-card-employees">
          <center>
            <div>
              <img className="dashboard-user-icon" src="/images/users.png" />
            </div>
            <div>
              <h1 className="text-details">Employees</h1>
              <Countup end={data.employees.length} className="counter" />
            </div>
          </center>
        </div>
      </React.Fragment>
    );
  };
  const Courses = () => {
    const { loading, data } = useQuery(PRODUCTS);
    if (loading || !data) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        <div className="widget-card-products">
          <center>
            <div>
              <img className="dashboard-products-icon" src="/images/box.png" />
            </div>
            <div>
              <h1 className="text-details">Products</h1>
              <Countup end={data.products.length} className="counter" />
            </div>
          </center>
        </div>
      </React.Fragment>
    );
  };
  const CouresePaid = () => {
    return (
      <React.Fragment>
        <div className="widget-card-clients">
          <center>
            <div>
              <img
                className="dashboard-client-icon"
                src="/images/social-services.png"
              />
            </div>
            <div>
              <h1 className="text-details">Clients</h1>
              <Countup end={data.users.length} className="counter" />
            </div>
          </center>
        </div>
      </React.Fragment>
    );
  };
  const Admin = () => {
    const { loading, data } = useQuery(CATEGORIES);
    if (loading || !data) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        {/* <Row className="widget-card4">
          <Col span={10}>
            <ControlOutlined className="background-image-widget4" />
          </Col>
          <Col span={14}>
            <Countup end={data.categories.length} className="counter4" />

            <h1 className="text-details4">Total Categories</h1>
          </Col>
        </Row> */}
        <div className="widget-card-cats">
          <center>
            <div>
              <img
                className="dashboard-client-icon"
                src="/images/maintenance.png"
              />
            </div>
            <div>
              <h1 className="text-details">Categories</h1>
              <Countup end={data.categories.length} className="counter" />
            </div>
          </center>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} lg={12} xl={6}>
          <User />
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6}>
          <CouresePaid />
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6}>
          <Courses />
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6}>
          <Admin />
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default TotalCart;
