import { gql } from "@apollo/client";

const CREATE_BANNER = gql`
  mutation ($image: String, $link: String) {
    create_banner(image: $image, link: $link) {
      message
    }
  }
`;

const UPDATE_BANNER = gql`
  mutation ($id: String, $image: String, $link: String) {
    update_banner(id: $id, image: $image, link: $link) {
      message
    }
  }
`;
const DELETE_BANNER = gql`
  mutation ($id: String) {
    delete_banner(id: $id) {
      message
    }
  }
`;
const BANNERS = gql`
  query {
    banners {
      link
      id
      image
      show
      created_at
      updated_at
    }
  }
`;
const BANNER = gql`
  query ($id: String) {
    banner(id: $id) {
      id
      link
      image
      show
      created_at
      updated_at
    }
  }
`;

export { CREATE_BANNER, UPDATE_BANNER, BANNER, BANNERS, DELETE_BANNER };
