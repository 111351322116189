import { gql } from "@apollo/client";

const TRACKINGS = gql`
  query {
    trackings {
      id
      trackingId
      insurance
      shippingCountry
      packageType
      trackingNumber
      note
      status
      cost
      weight
      estimate
      created_at
      updated_at
      user {
        fullname
        id
      }
    }
  }
`;
const TRACKING = gql`
  query ($id: String) {
    tracking(id: $id) {
      id
      trackingId
      insurance
      shippingCountry
      packageType
      trackingNumber
      note
      status
      cost
      weight
      estimate
      created_at
      updated_at
      costChange
      weightChange
      payment
      shipping
      user {
        id
        fullname
        email
        address {
          address
          phone
          email
        }
      }
    }
  }
`;
const TRACKING_USER = gql`
  query ($id: String) {
    trackings_user(id: $id) {
      id
      insurance
      shippingCountry
      packageType
      trackingNumber
      note
      status
      cost
      weight
      estimate
      created_at
      updated_at
      user {
        id
        fullname
        email
        address {
          address
          phone
          email
        }
      }
    }
  }
`;

const UPDATE_TRACKING = gql`
  mutation Update_tracking_status(
    $id: String
    $status: String
    $shipping: Float
    $totalShippig: Float
    $payment: Float
    $weightChange: Float
    $costChange: Float
  ) {
    update_tracking_status(
      id: $id
      status: $status
      shipping: $shipping
      totalShippig: $totalShippig
      payment: $payment
      weightChange: $weightChange
      costChange: $costChange
    ) {
      message
    }
  }
`;

export { TRACKINGS, TRACKING, TRACKING_USER, UPDATE_TRACKING };
