import React, { useEffect } from "react";
import { Layout, Table, Tag, Spin, message, Popconfirm, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { DELETE_PRODUCT, PRODUCT_NOT_APPROVE } from "../../graphql/products";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ImportButton } from "../../components/actions/ImportButton";
import { listProducts } from "../../services/products";

const { Content } = Layout;

const Blogs = () => {
  const { loading, error, data, refetch } = useQuery(PRODUCT_NOT_APPROVE);
  const [deleteProduct] = useMutation(DELETE_PRODUCT);
  useEffect(() => {
    listProducts().then((response) => {
      console.log(response);
    });
  }, []);
  if (error) {
    console.error(error);
  }
  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..."></Spin>
      </center>
    );
  }

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (thumbnail, data) => {
        console.log(data);
        return (
          <img
            style={{ borderRadius: "4px" }}
            height="40px"
            width="60px"
            src={!thumbnail[0] ? data.imagesURL[0] : thumbnail[0]}
            // src={`http://localhost:7000/public/uploads/${thumbnail}`}
            alt="avatar"
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "name",
      render: (data) => {
        return <> {data.substring(0, 20) + "..."}</>;
      },
    },
    // {
    //   title: "Categories",
    //   dataIndex: "categories",
    //   key: "categories",
    //   render: (categories) => {
    //     return categories.map((category) => {
    //       return <Tag color="#7868e6">{category.title}</Tag>;
    //     });
    //   },
    // },
    // {
    //   title: "Author",
    //   dataIndex: "user",
    //   key: "user",
    //   render: (user) => {
    //     return user.fullname;
    //   },
    // },
    {
      title: "Original Price",
      dataIndex: "originalPrice",
      key: "originalPrice",
    },
    {
      title: "Shipping Cost",
      dataIndex: "shippingCost",
      key: "shippingCost",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (data) => {
        return moment(parseInt(data)).format("Do MMM YYYY");
      },
    },
    // {
    //   title: "Updated Date",
    //   dataIndex: "updated_at",
    //   key: "updated_at",
    //   render: (data) => {
    //     return moment(parseInt(data)).format("Do MMM YYYY");
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "id",
      key: "action",
      render: (id) => {
        return (
          <React.Fragment>
            <Link to={`/dashboard/product/edit-products/${id}`}>
              <Tag color="#5339d1">Edit</Tag>
            </Link>

            {/* <Popconfirm
              title="Are you sure to delete this Blog？"
              onConfirm={() => {
                deleteProduct({ variables: { id } })
                  .then((res) => {
                    message.success(res.data.deleteProduct.message);
                    refetch();
                  })
                  .catch((error) => {
                    let err = JSON.parse(JSON.stringify(error));
                    message.error(err.graphQLErrors[0].message);
                  });
              }}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <Tag color="#ff4333">Delete</Tag>
            </Popconfirm> */}
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Content>
        <div>
          <Row>
            <Col span={12}>
              <h1 className="header-content">Products</h1>
            </Col>
            <Col span={12} align="right">
              <ImportButton />
            </Col>
          </Row>
          <Table columns={columns} dataSource={data.get_products_not_approve} />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Blogs;
