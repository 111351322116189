import { Spin, Table, Tag } from "antd";

import { BRANDS } from "../../graphql/brand";
import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";

const Brands = ({ handleState }) => {
  const { loading, error, data } = useQuery(BRANDS);
  if (error) {
    console.error(error);
  }
  console.log(data);
  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..." />
      </center>
    );
  }
  console.log(data);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(parseInt(created_at)).format("Do MMMM YYYY");
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) => {
        return moment(parseInt(updated_at)).format("Do MMMM YYYY");
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (id) => {
        return (
          <React.Fragment>
            <Link to={`/dashboard/update/brand/${id}`}>
              <Tag color="#2176ff">Edit</Tag>
            </Link>
            {/* <Popconfirm
              title="Are you sure to delete this category？"
              onConfirm={() => {
                deleteCategory({ variables: { id } })
                  .then((res) => {
                    message.success(res.data.deleteCategory.message);
                    refetch();
                  })
                  .catch((error) => {
                    let err = JSON.parse(JSON.stringify(error));
                    message.error(err.graphQLErrors[0].message);
                  });
              }}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <Tag color="#ff4333">Delete</Tag>
            </Popconfirm> */}
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Table columns={columns} dataSource={data.brands} />
    </React.Fragment>
  );
};

export default Brands;
