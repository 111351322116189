import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Categories from "./components/categories/main";
import { ConfigProvider } from "antd";
import CreateBanner from "./components/banners/main";
import CreateBrand from "./components/brands/main";
import CreateCategory from "./components/categories/create-category";
import CreateProduct from "./components/products/create-product";
import AddNewProduct from "./components/products/add-products";
import Dashboard from "./components/dashboard";
import GuestOrder from "./components/guest/orders";
import Login from "./components/login";
import Logout from "./components/logout";
import MainCategories from "./components/mainCategories/main";
// import Test from "./components/test";
import Order from "./components/orders/order";
import { PreviewProduct } from "./pages/previewProduct";
import PrivateRoute from "./privateRoute";
import { Products } from "./pages/product";
import React from "react";
import Register from "./components/register";
import Subcategories from "./components/sub-category/main";
import Tracking from "./pages/tracking";
import TrackingDetail from "./pages/tracking-detail";
import Trackings from "./pages/trackings";
import UpdateBanner from "./components/banners/update-banner";
import UpdateBrand from "./components/brands/update_brand";
import UpdateCategory from "./components/categories/update-category";
import UpdateMainCategory from "./components/mainCategories/update-main-category";
import UpdateProduct from "./components/products/update-product";
import UpdateSubCategory from "./components/sub-category/update-sub-category";
import { UserProvider } from "./contexts/userContext";
import Users from "./components/users/users";
import ViewGuestOrder from "./components/guest/viewGuestOrder";
import ViewOrder from "./components/orders/viewOrder";
import axios from "axios";
import CreateQuotation from "./components/quotation/createQuotation";
import Quotations from "./components/quotation/quotations";
import ViewQuotation from "./components/quotation/viewQuotation";
import RequestQuote from "./components/requestQuote/requestQuote";
import CreateConfirmQuotation from "./components/requestQuote/createConfirmQuote";
import Productss from "./components/products/products";
import EditProduct from "./components/products/edit-product";
import ProductNotApprove from "./components/products/product-not-aprrove";
import ProductBestseller from "./components/products/best-seller";
// import { Sendmail } from "./pages/sendmail";

axios.defaults.withCredentials = true;

function App() {
  return (
    <React.Fragment>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#0061ff",
            fontFamily: "Nunito",
            fontSize: 15,
          },
        }}
      >
        <BrowserRouter>
          <UserProvider>
            <Routes>
              {/* ==== Public Route Section ==== */}
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />

              <Route path="/dashboard" element={<PrivateRoute />}>
                <Route path="" element={<Dashboard />} />
                <Route path="users" element={<Users />} />
                <Route
                  path="product/sub-category"
                  element={<Subcategories />}
                />
                <Route
                  path="update/sub-category/:id"
                  element={<UpdateSubCategory />}
                />
                <Route path="create/brand" element={<CreateBrand />} />
                <Route path="update/brand/:id" element={<UpdateBrand />} />
                <Route path="create/banner" element={<CreateBanner />} />
                <Route path="update/banner/:id" element={<UpdateBanner />} />
                <Route path="product/all" element={<Productss />} />
                <Route path="product/approve" element={<ProductNotApprove />} />
                <Route
                  path="product/best-seller"
                  element={<ProductBestseller />}
                />
                <Route path="preview/product" element={<PreviewProduct />} />
                <Route path="preview/trackings" element={<Trackings />} />
                <Route
                  path="preview/tracking/detail/:id"
                  element={<TrackingDetail />}
                />
                <Route path="preview/tracking/:id" element={<Tracking />} />
                <Route path="product/add" element={<CreateProduct />} />
                <Route
                  path="product/add-products"
                  element={<AddNewProduct />}
                />
                <Route
                  path="product/edit-products/:id"
                  element={<EditProduct />}
                />
                <Route path="product/edit/:id" element={<UpdateProduct />} />
                <Route path="product/categories" element={<Categories />} />
                <Route
                  path="update/category/:id"
                  element={<UpdateCategory />}
                />
                <Route path="orders" element={<Order />} />
                <Route path="guestOrders" element={<GuestOrder />} />
                <Route path="order/:id" element={<ViewOrder />} />
                <Route path="guestOrder/:id" element={<ViewGuestOrder />} />
                <Route path="create/quotation" element={<CreateQuotation />} />
                <Route path="quotations" element={<Quotations />} />
                <Route path="view/quote/:id" element={<ViewQuotation />} />
                <Route
                  path="view/request-quotations"
                  element={<RequestQuote />}
                />
                <Route
                  path="create/quotation/:id"
                  element={<CreateConfirmQuotation />}
                />
              </Route>

              {/* ==== Private Route Section ==== */}
              {/* <PrivateRoute path="/logout" element={Logout} />

            <Route
              path="product/all"
              element={Products}
            />
            <Route
              path="preview/product"
              element={PreviewProduct}
            />
            <Route
              path="preview/trackings"
              element={Trackings}
            />
            <Route
              path="preview/tracking/detail/:id"
              element={TrackingDetail}
            />
            <PublicRoute
              exact={true}
              path="preview/tracking/:id"
              element={Tracking}
            />
            <Route
              path="product/add"
              element={CreateProduct}
            />
            <Route
              path="product/edit/:id"
              element={UpdateProduct}
            />
            <Route
              path="update/category/:id"
              element={UpdateCategory}
            />
            <Route
              path="orders"
              element={Order}
            />
            <Route
              path="guestOrders"
              element={GuestOrder}
            />
            <Route
              path="order/:id"
              element={ViewOrder}
            />
            <Route
              path="guestOrder/:id"
              element={ViewGuestOrder}
            /> */}
              {/* <PrivateRoute exact={true} path="/sendmail" element={Sendmail} /> */}
            </Routes>
          </UserProvider>
        </BrowserRouter>
      </ConfigProvider>
    </React.Fragment>
  );
}

export default App;
