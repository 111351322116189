import React, { useEffect, useState } from "react";
import {
  Layout,
  Form,
  Input,
  Row,
  Col,
  message,
  Upload,
  Button,
  Select,
  Space,
  InputNumber,
  Divider,
  Rate,
  Tag,
  Radio,
} from "antd";
import parse from "html-react-parser";
import ColorPicker from "react-pick-color";
import {
  PlusOutlined,
  PictureFilled,
  MinusCircleOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import { PRODUCT, UPDATE_PRODUCT } from "../../graphql/products";
import { useMutation, useQuery } from "@apollo/client";
import { BRANDS } from "../../graphql/brand";
import { useParams } from "react-router";
import { CATEGORIES } from "../../graphql/categories";
import { SUB_CATE_BY_MAIN_SLUG } from "../../graphql/sub-category";
import { stringify, v4 as uuidv4 } from "uuid";
const { Content } = Layout;
const { Option } = Select;
const URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PRO_API;

const EditProduct = () => {
  const { id } = useParams();
  const [color, setColor] = useState("");
  const [mainColor, setMainColor] = useState([]);
  const [sizes, setSizes] = useState("");
  const [mainSizes, setMainSizes] = useState([]);

  const [where, setWhere] = useState("third-party");
  const [fileList, setFileList] = useState([]);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [category, setCategory] = useState("");
  const [branding, setBranding] = useState("");
  const [slugMainCate, setSlugMainCate] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [subCategory, setSubCategory] = useState([]);
  const [imgLink, setImgLink] = useState("");
  const [thumbnails, setThumbnails] = useState([]);
  const [rating, setRating] = useState(0);
  const [price, setPrice] = useState();
  const [weight, setWeight] = useState();
  const [discount, setDiscount] = useState();
  const [selectedImage, setSelectedImage] = useState(0);
  const [slug, setSlug] = useState([]);
  const [bestSeller, setBestSeller] = useState(false);
  const [approved, setApproved] = useState(false);

  const { loading, data } = useQuery(PRODUCT, {
    variables: {
      id,
    },
  });
  console.log(data?.product);
  const handleRemoveItem = (ind) => {
    console.log(ind, "name");
    setMainColor((preState) => preState.filter((index) => index !== ind));
  };
  const handleRemoveItemSize = (ind) => {
    setMainSizes((preState) => preState.filter((index) => index !== ind));
  };
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const { loading: mainCatLoading, data: mainCatData } = useQuery(CATEGORIES);
  const {
    laoding: subCatMainLoading,
    data: subCatMainData,
    refetch,
  } = useQuery(SUB_CATE_BY_MAIN_SLUG, {
    variables: { slug: slugMainCate.toString() },
  });
  const { loading: brandLoading, data: brandData } = useQuery(BRANDS);
  const handleChange = (info) => {
    setFileList(info.fileList);
    console.log(info);
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      console.log(info.file, "file");
      // Get this url from response in real world.
      //   setThumbnails(info.fileList.map((res) => res.response.imageUrl));
      //   setImageUrl(info.file.response.imageUrl);
    }
  };

  const selectCategory = (value) => {
    setCategory(value);
    setSlugMainCate(value);
  };
  const selectSubCategory = (value) => {
    setSubCategory(value);
    setSlug(value);
  };

  useEffect(() => {
    if (data) {
      const image = data.product.thumbnail;
      let _images = [];
      image.map((image) => {
        _images.push({
          uid: uuidv4(),
          name: image,
          status: "done",
          url: image,
        });
      });
      setFileList(_images);
      setThumbnails(_images);
    }
  }, [data]);

  useEffect(() => {
    if (data?.product?.size) {
      setMainSizes(data?.product?.size);
    }
    if (data?.product?.color) {
      setMainColor(data?.product?.color);
    }
  }, [data]);

  // useEffect(() => {
  //   let imgLInks = [{ imagesURL: "" }];
  //   data?.product.imagesURL.map((res) => {
  //     imgLInks.push({ imagesURL: res });
  //   });
  //   if (imgLink) {
  //     imgLInks.push({ imgLink });
  //   }
  //   console.log(imgLInks, "imglink");
  // }, [data, imgLink]);
  console.log(imgLink, "imgagelinkn");

  if (loading || mainCatLoading || subCatMainLoading || brandLoading)
    return "laoding...";

  let _images = [];
  fileList.map((image) => {
    if (image.response) {
      _images.push(image.response.imageUrl);
    } else {
      _images.push(image.url);
    }
  });

  console.log(_images, "imageLink");

  const handleImageClick = (ind) => () => {
    setSelectedImage(ind);
  };

  const handleSubmit = async (values) => {
    console.log(values, "values");
    updateProduct({
      variables: {
        ...values,
        userId: "1",
        // approved: !approved ? data.product.approved : approved,
        // bestSeller: !bestSeller ? data.product.bestSeller : bestSeller,
        id: id,
        color: mainColor.map((res) => res),
        size: mainSizes.map((es) => es),
        thumbnail: _images,
        imagesURL: !values.imagesURLs
          ? data?.product.imagesURL
          : _images.length > 0 && !values.imagesURLs
          ? ""
          : values.imagesURLs.map((res) => res.imagesURLs),
        categoriesSlug: !slug ? data?.product.categoriesSlug : slug,
        mainCategoriesSlug: !slugMainCate
          ? data?.product.mainCategoriesSlug
          : slugMainCate,
        // approved: values.approved === "checked" ? true : values.approved,
        productName: values.productName,
        rating: Number(values.rating),
        // imagesURL:
        //   values.imagesURL.length == 0 ? values.imagesURL : values.imagesURL,
        shippingCost:
          where === "own"
            ? Number(0)
            : weight === undefined && price === undefined
            ? Number((data?.product.shippingCost).toFixed(2))
            : weight >= 0 && price === undefined
            ? Number((data?.product.usPrice * 0.12 + weight * 7).toFixed(2))
            : weight >= 0 && price >= 0
            ? Number((price * 0.12 + weight * 7).toFixed(2))
            : !weight && price >= 0
            ? Number((price * 0.12 + data?.product.weight * 7).toFixed(2))
            : 0,
        discountPrice:
          where === "own"
            ? Number((price - (price * discount) / 100).toFixed(2))
            : price === undefined &&
              weight === undefined &&
              discount === undefined
            ? Number((data?.product.discountPrice).toFixed(2))
            : price >= 0 && weight === undefined && discount === undefined
            ? Number(
                (
                  price * 0.12 +
                  data?.product.weight * 7 +
                  price -
                  (price * data?.product.discount) / 100
                ).toFixed(2)
              )
            : price >= 0 && weight >= 0 && discount === undefined
            ? Number(
                (
                  price * 0.12 +
                  weight * 7 +
                  price -
                  (price * data?.product.discount) / 100
                ).toFixed(2)
              )
            : price === undefined && weight >= 0 && discount === undefined
            ? Number(
                (
                  data?.product.usPrice * 0.12 +
                  weight * 7 +
                  data?.product.usPrice -
                  (data?.product.usPrice * data?.product.discount) / 100
                ).toFixed(2)
              )
            : price === undefined && weight >= 0 && discount >= 0
            ? Number(
                (
                  data?.product.usPrice * 0.12 +
                  weight * 7 +
                  data?.product.usPrice -
                  (data?.product.usPrice * discount) / 100
                ).toFixed(2)
              )
            : price >= 0 && weight >= 0 && discount >= 0
            ? Number(
                (
                  price * 0.12 +
                  weight * 7 +
                  price -
                  (price * discount) / 100
                ).toFixed(2)
              )
            : price === undefined && weight === undefined && discount >= 0
            ? Number(
                (
                  data?.product.usPrice * 0.12 +
                  data?.product.weight * 7 +
                  data?.product.usPrice -
                  (data?.product.usPrice * discount) / 100
                ).toFixed(2)
              )
            : price >= 0 && weight === undefined && discount >= 0
            ? Number(
                (
                  price * 0.12 +
                  data?.product.weight * 7 +
                  price -
                  (price * discount) / 100
                ).toFixed(2)
              )
            : 0,
        originalPrice:
          where === "own"
            ? Number(price)
            : price === undefined && weight === undefined
            ? Number((data?.product.originalPrice).toFixed(2))
            : price >= 0 && weight === undefined
            ? Number(
                (
                  price * 0.12 +
                  data?.product.weight * 7 +
                  Number(price)
                ).toFixed(2)
              )
            : price >= 0 && weight >= 0
            ? Number((price * 0.12 + weight * 7 + price).toFixed(2))
            : price === undefined && weight >= 0
            ? Number(
                (
                  data?.product.usPrice * 0.12 +
                  weight * 7 +
                  data?.product.usPrice
                ).toFixed(2)
              )
            : 0,
      },
    })
      .then(async () => {
        await message.success("your product updated successfully");
        window.location.replace("/dashboard/product/approve");
      })
      .catch((error) => console.error(error));
  };
  const onChangeSelect = (value) => {
    setWhere(value);
  };

  return (
    <React.Fragment>
      <Content>
        <Row gutter={[32, 32]}>
          <Col span={10}>
            <h1 className="header-content">Edit Products</h1>
            <div className="add-products-container">
              <div className="content-add-products">
                <Form
                  onFinish={handleSubmit}
                  initialValues={data.product}
                  // fields={[
                  //   {
                  //     name: ["imagesURL"],
                  //     value: data.product.imagesURL,
                  //   },
                  // ]}
                  name="basic"
                  layout="vertical"
                  // autoComplete="off"
                >
                  {/* <Form.Item
                    name="approved"
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Checkbox
                      checked={approved}
                      onChange={(e) => setApproved(e.target.checked)}
                    >
                      Approve this product
                    </Checkbox>
                  </Form.Item> */}
                  <div>
                    <p className="label-form-2">Where?</p>
                    <p className="second-label">
                      {where === "third-party" &&
                        "The product which imported from (Amazon, Ebay, ...)"}
                      {where === "own" &&
                        "The products which are already in Cambodia!"}
                      {where === "general" &&
                        "The product exclude formula but has form to add pricing! "}
                    </p>
                    <Form.Item
                      name="wheres"
                      rules={[
                        {
                          required: true,
                          message: "Wheres is required!",
                        },
                      ]}
                    >
                      <Select
                        onChange={onChangeSelect}
                        placeholder="Select your product from"
                        // defaultValue="third-party"
                        size="large"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Option value="third-party">
                          Third Party Products
                        </Option>
                        <Option value="own">Own Products</Option>
                        <Option value="general">General Products</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <Row>
                    <Col span={12}>
                      <Form.Item name="bestSeller" label="Best Seller">
                        <Radio.Group>
                          <Radio value={true}>YES</Radio>
                          <Radio value={false}>NO</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="approved" label="Aprroved">
                        <Radio.Group>
                          <Radio value={true}>YES</Radio>
                          <Radio value={false}>NO</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <br />

                  <Form.Item label="Thumbnail" name="thumbnail">
                    <Upload
                      name="thumbnail"
                      action={`${URL}/upload/image`}
                      listType="picture-card"
                      ultiple={true}
                      fileList={fileList}
                      multiple={true}
                      // beforeUpload={beforeUpload}
                      onChange={handleChange}
                      showUploadList={true}
                      withCredentials={true}
                    >
                      <PictureFilled />
                    </Upload>
                  </Form.Item>
                  <br />
                  <Form.List name="imagesURLs">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...resetField }) => (
                          <div
                            key={key}
                            style={{
                              display: "flex",
                              // marginBottom: 8,
                            }}
                            // align="baseline"
                          >
                            <Form.Item
                              style={{ width: "100%" }}
                              {...resetField}
                              label="Image-Link"
                              name={[name, "imagesURLs"]}
                            >
                              <Input
                                onChange={(e) => {
                                  setImgLink(e.target.value);
                                }}
                                style={{ width: "100%" }}
                                className="schoolInput"
                              />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </div>
                        ))}
                        <Form.Item>
                          <Button
                            size="middle"
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Image Link
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <p className="label-form">Product details</p>
                  <Form.Item
                    label="Product name"
                    name="productName"
                    rules={[
                      {
                        required: true,
                        message: "Product name is required!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Category"
                    name="mainCategoriesId"
                    rules={[
                      {
                        required: true,
                        message: "Category is required!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Select
                      //   mode="multiple"
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      placeholder="select category"
                      optionLabelProp="label"
                      showSearch
                      //   options={categoryOptions}
                      onChange={selectCategory}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {mainCatData.categories.map((main) => {
                        return (
                          <Option value={main.slug} key={main.id}>
                            {main.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {/* sub category */}
                  <Form.Item
                    label="Sub-category"
                    name="categoriesId"
                    rules={[
                      {
                        required: true,
                        message: "Sub-category is required!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginLeft: "12px",
                    }}
                  >
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      placeholder="select sub category"
                      showSearch
                      onChange={selectSubCategory}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {subCatMainData?.SubCategoryByCateSlug.map((res) => {
                        return (
                          <Option
                            // onClick={() => setSlug(res.slug)}
                            value={res.slug}
                            key={res.id}
                          >
                            {res.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {/* branding */}
                  <Form.Item
                    label="Brading"
                    name="brandName"
                    rules={[
                      {
                        required: true,
                        message: "Branding is required!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Select
                      size="large"
                      showSearch
                      onChange={(value) => {
                        setBranding(value);
                      }}
                      placeholder="Select a brand name"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {brandData.brands.map((brand) => {
                        return (
                          <Option value={brand.title} key={brand.id}>
                            {brand.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {/* rating */}
                  <Form.Item
                    // onChange={(value) => {
                    //   setRating(value);
                    // }}
                    initialValue={3}
                    label="Rating"
                    name="rating"
                    rules={[
                      {
                        required: true,
                        message: "Rating is required!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginLeft: "12px",
                    }}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      size="large"
                      min={1}
                      max={5}
                      onChange={(value) => {
                        setRating(value);
                      }}
                    />
                  </Form.Item>
                  {/* link */}
                  <Form.Item
                    label="Link"
                    name="link"
                    rules={[
                      {
                        type: "url",
                        message: "This field must be a valid url.",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Input size="large" placeholder="Referal link" />
                  </Form.Item>
                  {/* Asin */}
                  <Form.Item
                    label="Asin"
                    name="asin"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginLeft: "12px",
                    }}
                  >
                    <Input size="large" />
                  </Form.Item>
                  <Form.Item label="Product Size" name="sizes">
                    <Input
                      size="large"
                      onChange={(e) => {
                        setSizes(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Button
                    size="small"
                    onClick={() => setMainSizes((arr) => [...arr, sizes])}
                    type="primary"
                  >
                    Add size
                  </Button>
                  <p>Picks product's color</p>
                  <Row gutter={[12, 12]}>
                    <Col span={18}>
                      <ColorPicker
                        theme={{
                          background: "#fff",
                          inputBackground: "grey",
                          borderColor: "none",
                          borderRadius: "8px",
                          border: "none",
                          color: "black",
                          boxShadow: "none",
                          width: "320px",
                        }}
                        color={color}
                        onChange={(color) => setColor(color.hex)}
                      />
                    </Col>
                    <Col span={6}>
                      <div
                        style={{
                          marginTop: "20px",
                          background: `${color}`,
                          width: "30px",
                          height: "30px",
                          borderRadius: "15px",
                        }}
                      ></div>
                    </Col>

                    <Button
                      size="small"
                      onClick={() => setMainColor((arr) => [...arr, color])}
                      type="primary"
                    >
                      Add color
                    </Button>
                  </Row>
                  <br />
                  <p className="label-form">Pricing</p>
                  <Space>
                    {/* <Form layout="vertical"> */}
                    {/* price */}
                    <Space direction="vertical">
                      <Form.Item
                        label="Price (USD)"
                        name="usPrice"
                        rules={[
                          {
                            required: true,
                            message: "Price is required!",
                          },
                        ]}
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          onChange={(value) => {
                            setPrice(value);
                          }}
                        />
                      </Form.Item>
                      {/* weight */}
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Weight is required!",
                          },
                        ]}
                        label="Weight (Kg)"
                        name="weight"
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          onChange={(value) => {
                            setWeight(value);
                          }}
                          min={0}
                        />
                      </Form.Item>
                      {/* discount */}
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Discount is required!",
                          },
                        ]}
                        label="Discount (%)"
                        name="discount"
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          min={0}
                          max={100}
                          onChange={(value) => {
                            setDiscount(value);
                          }}
                        />
                      </Form.Item>
                    </Space>
                    {/* </Form> */}
                    <Divider type="vertical" />
                    <div>
                      <div className="pricing-detail">
                        <span>Subtotal</span>
                        <span>
                          {!price ? (
                            <>{(data?.product.usPrice).toFixed(2)}</>
                          ) : (
                            price.toFixed(2)
                          )}
                        </span>
                      </div>
                      <div className="pricing-detail">
                        <span>Shipping</span>
                        <span>
                          {weight === undefined && price === undefined ? (
                            <>{(data?.product.shippingCost).toFixed(2)}</>
                          ) : weight >= 0 && price === undefined ? (
                            <>
                              {(
                                data?.product.usPrice * 0.12 +
                                weight * 10
                              ).toFixed(2)}
                            </>
                          ) : weight >= 0 && price >= 0 ? (
                            <>{(price * 0.12 + weight * 7).toFixed(2)}</>
                          ) : !weight && price >= 0 ? (
                            <>
                              {(
                                price * 0.12 +
                                data?.product.weight * 7
                              ).toFixed(2)}
                            </>
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                      <div className="pricing-detail">
                        <span>Discount</span>
                        <span>
                          {discount === undefined && price === undefined ? (
                            <>
                              {(
                                (data?.product.usPrice *
                                  data?.product.discount) /
                                100
                              ).toFixed(2)}
                            </>
                          ) : discount >= 0 && price === undefined ? (
                            <>
                              {(
                                (data?.product.usPrice * discount) /
                                100
                              ).toFixed(2)}
                            </>
                          ) : discount >= 0 && price >= 0 ? (
                            <>{((price * discount) / 100).toFixed(2)}</>
                          ) : discount === undefined && price >= 0 ? (
                            <>
                              {((price * data?.product.discount) / 100).toFixed(
                                2
                              )}
                            </>
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                      <Divider />
                      <div className="pricing-detail">
                        <span>Total</span>
                        <span className="total-price">
                          {price === undefined &&
                          weight === undefined &&
                          discount === undefined ? (
                            <>{(data?.product.discountPrice).toFixed(2)}</>
                          ) : price >= 0 &&
                            weight === undefined &&
                            discount === undefined ? (
                            <>
                              {(
                                price * 0.12 +
                                data?.product.weight * 7 +
                                price -
                                (price * data?.product.discount) / 100
                              ).toFixed(2)}
                            </>
                          ) : price >= 0 &&
                            weight >= 0 &&
                            discount === undefined ? (
                            <>
                              {(
                                price * 0.12 +
                                weight * 7 +
                                price -
                                (price * data?.product.discount) / 100
                              ).toFixed(2)}
                            </>
                          ) : price === undefined &&
                            weight >= 0 &&
                            discount === undefined ? (
                            <>
                              {(
                                data?.product.usPrice * 0.12 +
                                weight * 7 +
                                data?.product.usPrice -
                                (data?.product.usPrice *
                                  data?.product.discount) /
                                  100
                              ).toFixed(2)}
                            </>
                          ) : price === undefined &&
                            weight >= 0 &&
                            discount >= 0 ? (
                            <>
                              {(
                                data?.product.usPrice * 0.12 +
                                weight * 7 +
                                data?.product.usPrice -
                                (data?.product.usPrice * discount) / 100
                              ).toFixed(2)}
                            </>
                          ) : price >= 0 && weight >= 0 && discount >= 0 ? (
                            <>
                              {(
                                price * 0.12 +
                                weight * 7 +
                                price -
                                (price * discount) / 100
                              ).toFixed(2)}
                            </>
                          ) : price === undefined &&
                            weight === undefined &&
                            discount >= 0 ? (
                            <>
                              {(
                                data?.product.usPrice * 0.12 +
                                data?.product.weight * 7 +
                                data?.product.usPrice -
                                (data?.product.usPrice * discount) / 100
                              ).toFixed(2)}
                            </>
                          ) : price >= 0 &&
                            weight === undefined &&
                            discount >= 0 ? (
                            <>
                              {(
                                price * 0.12 +
                                data?.product.weight * 7 +
                                price -
                                (price * discount) / 100
                              ).toFixed(2)}
                            </>
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                      <div className="pricing-detail">
                        <span>Main Price</span>
                        <span className="total-price">
                          {price === undefined && weight === undefined ? (
                            <>{(data?.product.originalPrice).toFixed(2)}</>
                          ) : price >= 0 && weight === undefined ? (
                            <>
                              {(
                                price * 0.12 +
                                data?.product.weight * 7 +
                                Number(price)
                              ).toFixed(2)}
                            </>
                          ) : price >= 0 && weight >= 0 ? (
                            <>
                              {(
                                price * 0.12 +
                                weight * 7 +
                                Number(price)
                              ).toFixed(2)}
                            </>
                          ) : price === undefined && weight >= 0 ? (
                            <>
                              {(
                                data?.product.usPrice * 0.12 +
                                weight * 7 +
                                data?.product.usPrice
                              ).toFixed(2)}
                            </>
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                    </div>
                  </Space>
                  <br />
                  <Form.Item label="description" name="descriptions">
                    <ReactQuill
                      theme="snow"
                      value={value}
                      onChange={setValue}
                      placeholder="Write something..."
                    />
                  </Form.Item>
                  <br />
                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      // disabled={
                      //   name === "" ||
                      //   category === "" ||
                      //   subCategory.length <= 0 ||
                      //   branding === "" ||
                      //   !rating ||
                      //   price === 0 ||
                      //   !price
                      // }
                    >
                      Publish
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
          <Col span={14}>
            <h1 className="header-content">Preview</h1>
            <div className="product-preview-container">
              <Row gutter={[24, 24]}>
                <Col offset={3} span={4} className="allow-scroll">
                  {!_images[0]
                    ? data?.product.imagesURL?.map((res, ind) => (
                        <div key={ind} onClick={handleImageClick(ind)}>
                          <img
                            className="product-image-small"
                            alt="product"
                            src={res}
                          />
                        </div>
                      ))
                    : _images.map((res, ind) => (
                        <div key={ind} onClick={handleImageClick(ind)}>
                          <img
                            className="product-image-small"
                            alt="product"
                            src={res}
                          />
                        </div>
                      ))}
                </Col>
                <Col span={17}>
                  <div className="product-image-container">
                    {discount !== 0 && (
                      <div className="discount-tag">
                        <Tag color="error">{discount}% OFF</Tag>
                      </div>
                    )}
                    <>
                      {!_images[0] ? (
                        <img
                          alt="product name"
                          src={data?.product.imagesURL[selectedImage]}
                          className="product-image"
                        />
                      ) : (
                        // <img alt="product name" src={!imgLink ? "" : imgLink} />
                        <img
                          alt="product name"
                          src={_images[selectedImage]}
                          className="product-image"
                        />
                      )}
                    </>
                  </div>
                </Col>
                <Col offset={3} span={21}>
                  <div className="description-container">
                    <p className="second-label">
                      {branding === "" ? (
                        <>{data?.product.brandName}</>
                      ) : (
                        branding
                      )}
                    </p>
                    <h2 className="product-name">
                      {name === "" ? <>{data?.product.productName}</> : name}
                    </h2>
                    <h1 className="product-price">
                      {price === undefined &&
                      weight === undefined &&
                      discount === undefined ? (
                        <>{(data?.product.discountPrice).toFixed(2)}</>
                      ) : price >= 0 &&
                        weight === undefined &&
                        discount === undefined ? (
                        <>
                          {(
                            price * 0.12 +
                            data?.product.weight * 7 +
                            price -
                            (price * data?.product.discount) / 100
                          ).toFixed(2)}
                        </>
                      ) : price >= 0 &&
                        weight >= 0 &&
                        discount === undefined ? (
                        <>
                          {(
                            price * 0.12 +
                            weight * 7 +
                            price -
                            (price * data?.product.discount) / 100
                          ).toFixed(2)}
                        </>
                      ) : price === undefined &&
                        weight >= 0 &&
                        discount === undefined ? (
                        <>
                          {(
                            data?.product.usPrice * 0.12 +
                            weight * 7 +
                            data?.product.usPrice -
                            (data?.product.usPrice * data?.product.discount) /
                              100
                          ).toFixed(2)}
                        </>
                      ) : price === undefined &&
                        weight >= 0 &&
                        discount >= 0 ? (
                        <>
                          {(
                            data?.product.usPrice * 0.12 +
                            weight * 7 +
                            data?.product.usPrice -
                            (data?.product.usPrice * discount) / 100
                          ).toFixed(2)}
                        </>
                      ) : price >= 0 && weight >= 0 && discount >= 0 ? (
                        <>
                          {(
                            price * 0.12 +
                            weight * 7 +
                            price -
                            (price * discount) / 100
                          ).toFixed(2)}
                        </>
                      ) : price === undefined &&
                        weight === undefined &&
                        discount >= 0 ? (
                        <>
                          {(
                            data?.product.usPrice * 0.12 +
                            data?.product.weight * 7 +
                            data?.product.usPrice -
                            (data?.product.usPrice * discount) / 100
                          ).toFixed(2)}
                        </>
                      ) : price >= 0 &&
                        weight === undefined &&
                        discount >= 0 ? (
                        <>
                          {(
                            price * 0.12 +
                            data?.product.weight * 7 +
                            price -
                            (price * discount) / 100
                          ).toFixed(2)}
                        </>
                      ) : (
                        0
                      )}
                    </h1>
                    <Rate
                      allowHalf
                      //   defaultValue={Number(data?.product.rating)}
                      value={
                        rating == 0 ? Number(data?.product.rating) : rating
                      }
                    />
                    <br />
                    <h2>Size</h2>
                    <Row gutter={[12, 12]}>
                      {mainSizes.map((res) => {
                        return (
                          <Col span={3}>
                            <div
                              className="size-border"
                              onClick={() => {
                                handleRemoveItemSize(res);
                              }}
                            >
                              {res}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>

                    <br />
                    <h2>Colors</h2>
                    <Row>
                      {mainColor?.map((res, ind) => {
                        return (
                          <Col span={3}>
                            <div
                              onClick={() => {
                                handleRemoveItem(res);
                              }}
                              style={{
                                display: "flex",
                                marginTop: "20px",
                                background: `${res}`,
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                              }}
                            ></div>
                          </Col>
                        );
                      })}
                    </Row>

                    <br />
                    <br />
                    <br />
                    <Button
                      type="primary"
                      ghost
                      size="large"
                      style={{ width: "100%" }}
                    >
                      Checkout
                    </Button>
                    <br />
                    <br />
                    <Button
                      type="primary"
                      size="large"
                      style={{ width: "100%" }}
                    >
                      Add to cart
                    </Button>
                    <br />
                    <div>
                      {!value ? (
                        <>
                          <h3>Descriptions</h3>
                          <div>{parse(data?.product.descriptions)}</div>
                        </>
                      ) : (
                        <>
                          <h3>Descriptions</h3>
                          <div>{parse(value)}</div>
                        </>
                      )}
                    </div>
                    {/* <div>
                      {highlights !== "" && (
                        <>
                          <h3>Highlights</h3>
                          <div>{parse(highlights)}</div>
                        </>
                      )}
                    </div>
                    <div>
                      {keyfeature !== "" && (
                        <>
                          <h3>Key Features</h3>
                          <div>{parse(highlights)}</div>
                        </>
                      )}
                    </div>
                    <div>
                      {size !== "" && (
                        <>
                          <h3>Size Guides</h3>
                          <div>{parse(highlights)}</div>
                        </>
                      )}
                    </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default EditProduct;
