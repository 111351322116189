import { Button, Col, Divider, Form, Input, Row, message } from "antd";
import { Link, useParams } from "react-router-dom";
import { TRACKING, UPDATE_TRACKING } from "../graphql/tracking";
import { useMutation, useQuery } from "@apollo/client";

import React from "react";

const TrackingDetail = () => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const { loading, data, refetch } = useQuery(TRACKING, {
    variables: { id },
  });
  const [update_tracking_status] = useMutation(UPDATE_TRACKING);
  if (loading) return "loading...";
  console.log(data, "data");
  const {
    cost,
    estimate,
    insurance,
    note,
    packageType,
    shippingCountry,
    status,
    trackingNumber,
    weight,
    user,
    costChange,
    payment,
    shipping,
    weightChange,
    trackingId,
  } = data.tracking;

  const handleSubmit = (values) => {
    update_tracking_status({
      variables: {
        id,
        ...values,
        payment: Number(values.payment),
        shipping: Number(values.shipping),
        costChange: Number(values.costChange),
        status: values.status === "" ? "Not in Cambodia" : values.status,
        weightChange: Number(values.weightChange),
      },
    })
      .then(async (res) => {
        message.success(res.data.update_tracking_status.message);
        refetch();
        form.resetFields();
      })
      .catch((error) => {
        let err = JSON.parse(JSON.stringify(error));
        message.error(err.graphQLErrors[0].message);
      });
    console.log(values);
  };
  return (
    <>
      <h2>Tracking Detail</h2>
      <div className="pie-box">
        <Row gutter={[32, 32]}>
          <Col span={14}>
            <Form
              name="basic"
              form={form}
              layout="vertical"
              size="large"
              onFinish={handleSubmit}
            >
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Form.Item label="Weight" name="weightChange">
                    <Input className="schoolInput" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Cost" name="costChange">
                    <Input className="schoolInput" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Shipping"
                    name="shipping"
                    rules={[
                      {
                        required: true,
                        message: "Payment is required!",
                      },
                    ]}
                  >
                    <Input className="schoolInput" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Total Cost"
                    name="payment"
                    rules={[
                      {
                        required: true,
                        message: "Payment is required!",
                      },
                    ]}
                  >
                    <Input className="schoolInput" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Status" name="status">
                <Input className="schoolInput" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={10}>
            <Divider orientation="left" plain>
              <h4>Customer Infomation</h4>
            </Divider>
            <div>
              <p>
                Name: <b>{user.fullname}</b>
              </p>
              <p>
                Email: <b>{user.email}</b>
              </p>
              <p>
                Phone: <b>{user.address.phone}</b>
              </p>
              <p>
                Address: <b>{user.address.address}</b>
              </p>

              <Divider orientation="left" plain>
                <h4>Tracking Infomation</h4>
              </Divider>
              <p>
                Tracking ID: <b>{trackingId}</b>
              </p>
              <p>
                Tracking Number: <b>{trackingNumber}</b>
              </p>
              <p>
                Package Type: <b>{packageType}</b>
              </p>
              <p>
                Shipping Country: <b>{shippingCountry}</b>
              </p>
              <p>
                Status: <b>{status}</b>
              </p>
              <p>
                Cost: <b>${costChange === 0 ? cost : costChange}</b>
              </p>
              <p>
                Weight: <b>{weightChange === 0 ? weight : weightChange}</b>
              </p>
              <p>
                Estimate: <b>{estimate}</b>
              </p>
              <p>
                Insurance: <b>{insurance}</b>
              </p>
              {payment === 0 || shipping === 0 ? (
                ""
              ) : (
                <div>
                  <Divider orientation="left" plain>
                    <h4>Payment Infomation</h4>
                  </Divider>
                  <p>
                    Shipping: <b>${shipping}</b>
                  </p>
                  <p>
                    Total Cost: <b>${payment}</b>
                  </p>
                </div>
              )}
            </div>
            <br />
            <Link target="_blank" to={`/dashboard/preview/tracking/${id}`}>
              <Button type="default">Print this</Button>
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TrackingDetail;
