// import React from "react";
// import {
//   Form,
//   Input,
//   message,
//   Button,
//   Spin,
//   Layout,
//   Row,
//   Col,
//   Select,
// } from "antd";
// import { useMutation, useQuery } from "@apollo/client";
// import { UPDATE_SUB_CATEGORY, SUB_CATEGORY } from "../../graphql/sub-category";
// import { useParams } from "react-router";

// const { Content } = Layout;

// const UpdateSubCategory = () => {
//   const { id } = useParams();
//   const [form] = Form.useForm();
//   const { loading, error, data } = useQuery(SUB_CATEGORY, {
//     variables: {
//       id,
//     },
//   });

//   const [updateCategory] = useMutation(UPDATE_SUB_CATEGORY);

//   if (error) {
//     console.error(error);
//   }
//   if (loading || !data) {
//     return (
//       <center>
//         <Spin tip="Loading ..." />
//       </center>
//     );
//   }
//   console.log("data", data);

//   const handleSubmit = (values) => {
//     updateCategory({
//       variables: {
//         id,
//         title: values.title,
//       },
//     })
//       .then(async (res) => {
//         message.success(res.data.updateSubCategory.message);
//         window.location.replace("/dashboard/product/sub-category");
//       })
//       .catch((error) => {
//         let err = JSON.parse(JSON.stringify(error));
//         message.error(err.graphQLErrors[0].message);
//       });
//   };

//   return (
//     <React.Fragment>
//       <Content>
//         <div className="contentContainer-width">
//           <h1 className="header-content">Update Sub Category</h1>
//           <Form
//             name="basic"
//             form={form}
//             layout="vertical"
//             size="large"
//             onFinish={handleSubmit}
//             initialValues={data.SubCategory}
//           >
//             <Form.Item
//               label="Title"
//               name="title"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please input category!",
//                 },
//               ]}
//             >
//               <Input className="schoolInput" />
//             </Form.Item>
//             <Form.Item>
//               <Button
//                 type="primary"
//                 htmlType="submit"
//                 className="button-submit"
//               >
//                 Submit
//               </Button>
//             </Form.Item>
//           </Form>
//         </div>
//         <br />
//       </Content>
//     </React.Fragment>
//   );
// };

// export default UpdateSubCategory;
import React, { useState } from "react";
import {
  Form,
  Input,
  message,
  Button,
  Spin,
  Layout,
  Row,
  Col,
  Select,
} from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { SUB_CATEGORY, UPDATE_SUB_CATEGORY } from "../../graphql/sub-category";
import { CATEGORIES } from "../../graphql/categories";
import { useParams } from "react-router";

const { Content } = Layout;

const UpdateMainCategory = () => {
  const { id } = useParams();
  const [categorySlug, setCategorySlug] = useState("");
  const [form] = Form.useForm();
  const { loading, error, data } = useQuery(SUB_CATEGORY, {
    variables: {
      id,
    },
  });
  const { loading: loadingCate, data: dataCate } = useQuery(CATEGORIES);
  const [updateCategory] = useMutation(UPDATE_SUB_CATEGORY);

  if (error) {
    console.error(error);
  }
  if (loading || loadingCate || !data || !dataCate) {
    return (
      <center>
        <Spin tip="Loading ..." />
      </center>
    );
  }

  const handleSubmit = (values) => {
    console.log(values);
    updateCategory({
      variables: {
        id,
        title: values.title,
        categoryId: values.categoryId,
        categorySlug: categorySlug
          ? categorySlug
          : data.SubCategory.categorySlug,
      },
    })
      .then(async (res) => {
        message.success(res.data.updateSubCategory.message);
        window.location.replace("/dashboard/product/sub-category");
      })
      .catch((error) => {
        let err = JSON.parse(JSON.stringify(error));
        message.error(err.graphQLErrors[0].message);
      });
  };

  return (
    <React.Fragment>
      <Content>
        <div className="contentContainer-width">
          <h1 className="header-content">Update Sub Category</h1>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            size="large"
            onFinish={handleSubmit}
            initialValues={data.SubCategory}
            defaultValue={data.SubCategory}
          >
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input category!",
                    },
                  ]}
                >
                  <Input className="schoolInput" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Category"
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: "Category is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    // mode=""
                    placeholder="Select a Categories"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataCate.categories.map((category) => {
                      return (
                        // eslint-disable-next-line react/jsx-no-undef
                        <Option value={category.id} key={category.id}>
                          <div onClick={() => setCategorySlug(category.slug)}>
                            {category.title}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="button-submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <br />
      </Content>
    </React.Fragment>
  );
};

export default UpdateMainCategory;
