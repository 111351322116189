import React, { createContext, useEffect } from "react";
import axios from "axios";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const URL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_API
      : process.env.REACT_APP_PRO_API;
  const [isLogin, setIsLogin] = React.useState(false);
  const [role, setRole] = React.useState("");

  const verifyToken = async () => {
    await axios
      .get(`${URL}/token/verify`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      .then((res) => {
        setIsLogin(res.data.success);
        setRole(res.data.role);
        return;
      })
      .catch(async (err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    verifyToken();
    setInterval(() => {
      verifyToken();
    }, 14 * 60 * 1000);
  }, []);

  

  return (
    <UserContext.Provider value={{ isLogin, role }}>
      {children}
    </UserContext.Provider>
  );
};
