import React, { useState, useRef } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GUEST_ORDER } from "../../graphql/order";
import { Link } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Select,
  Card,
  Divider,
  Image,
  Typography,
  Tag,
  Table,
  Modal,
  Button,
  Form,
  Input,
} from "antd";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { Noted } from "../noted";
import {
  UPDATE_PAID_AMOUNT_GUEST,
  UPDATE_STATUS_GUEST_ORDER,
} from "../../graphql/order";

const { Content } = Layout;
const statusData = [
  // "confirmed",
  "paid",
  "purchased",
  "shipped",
  "delivered",
  "cancelled",
];
const viewGuestOrder = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const forms = useRef();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form] = Form.useForm();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visible, setVisible] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [paidAmount] = useMutation(UPDATE_PAID_AMOUNT_GUEST);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateStatusGuest] = useMutation(UPDATE_STATUS_GUEST_ORDER);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleUpdateStatus = () => {
    setVisible(true);
  };
  const handleCancel1 = () => {
    setVisible(false);
  };
  const handleOk1 = () => {
    setVisible(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { id } = useParams();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { loading, data, refetch } = useQuery(GUEST_ORDER, {
    variables: {
      id,
    },
  });
  if (loading) return "loading...";
  console.log(data);
  const onSubmitAmount = (value) => {
    paidAmount({
      variables: {
        id: id,
        paidAmount: value.paidAmount,
      },
    }).then(() => {
      refetch();
      setIsModalOpen(false);
    });
  };
  const handleChangeStatus = (values) => {
    console.log(values);
  };
  const data1 = [];
  // eslint-disable-next-line array-callback-return
  data.guestOrder.productOrder.map((item) => {
    // usPrices.push(item.usPrice);
    item.products.map((res) => data1.push(item.qty * res.discountPrice));
  });
  const total = data1.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  const columns = [
    {
      title: "Number",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Thumbnail",
      dataIndex: "products",
      key: "thumbnail",
      render: (products) => {
        return (
          <img
            style={{ borderRadius: "4px" }}
            height="40px"
            width="40px"
            src={products[0].thumbnail[0]}
            alt="avatar"
          />
        );
      },
    },
    {
      title: "ProductName",
      dataIndex: "products",
      render: (products) => (
        <div style={{ width: "300px" }}>{products[0].productName}</div>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      render: (products, data) => <div>{data.qty}</div>,
    },
    {
      title: "Price after Tax * Qty",
      dataIndex: "products",
      key: "products",
      render: (products, data) => (
        <span>
          {products.map((res) => {
            return <div>$ {res.usPrice.toFixed(2) * data.qty}</div>;
          })}
        </span>
      ),
    },

    {
      title: "Shipping",
      dataIndex: "products",
      key: "products",
      render: (products, data) => (
        <span>
          {products.map((res) => {
            return <div>$ {res.shippingCost.toFixed(2) * data.qty}</div>;
          })}
        </span>
      ),
    },
    {
      title: "Discount",
      dataIndex: "products",
      key: "products",
      render: (products) => (
        <span>
          {products.map((res) => {
            return <div>{res.discount}</div>;
          })}
        </span>
      ),
    },
    {
      title: "Price in Cambodia * Qty",
      dataIndex: "products",
      key: "products",
      render: (products, data) => (
        <span>
          {products.map((res) => {
            return <div>$ {res.discountPrice.toFixed(2) * data.qty}</div>;
          })}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "products",
      key: "products",
      render: (products) => (
        <span>
          {products.map((res) => {
            return (
              <Link to={`/dashboard/product/edit/${res.id}`}>
                <Tag color="#5339d1">Edit</Tag>
              </Link>
            );
          })}
        </span>
      ),
    },
  ];
  const onFinish = (values) => {
    console.log(values);
    updateStatusGuest({
      variables: {
        id: id,
        status: values.status,
        noted: values.note === undefined ? "" : values.note,
      },
    })
      .then(() => {
        refetch();

        form.resetFields();
        setVisible(false);
        // if (values.status === "paid") {
        //   setIsModalOpen(true);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Content>
        <Typography.Title level={4} className="header-title">
          Product Details
        </Typography.Title>
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <Typography.Title level={5} className="title">
              <h1>Order Summary</h1>
            </Typography.Title>
            <div>
              <h1>Order ID : {data.guestOrder.id}</h1>
            </div>
            <div>
              <h1>Status : {data.guestOrder.status}</h1>
            </div>
            <div>
              <h1>
                Orderd Date :{" "}
                {moment(parseInt(data.guestOrder.created_at)).format(
                  "Do MMM YYYY, hh:mm"
                )}
              </h1>
            </div>
            <div>
              <h1>
                Order Total: <b style={{ color: "green" }}>$ {total}</b>
              </h1>
              <h1>
                Paid Amount:{" "}
                <b style={{ color: "red" }}>
                  {!data.guestOrder.paidAmount
                    ? "Don't pay yet"
                    : `$ ${data.guestOrder.paidAmount}`}
                </b>
              </h1>
              {/* <h1>Order Total: $ {amountOrder}</h1> */}
            </div>
          </Col>
          <Col span={8}>
            <Typography.Title level={5} className="title">
              <h1>Shipping Address</h1>
            </Typography.Title>
            <div>
              <h1>
                Address: {data.guestOrder.city}/{data.guestOrder.district}
                <br />
                {data.guestOrder.homeStreet}
              </h1>
            </div>
          </Col>
          <Col span={8}>
            <Typography.Title level={5} className="title">
              <h1>Recipient</h1>
            </Typography.Title>
            <div>
              <h1>Name: {data.guestOrder.fullname}</h1>
            </div>
            <div>
              <h1>Email: {data.guestOrder.email}</h1>
            </div>
            <div>
              <h1>Phone: {data.guestOrder.phone}</h1>
            </div>
          </Col>
        </Row>
        <br />

        <Table
          columns={columns}
          dataSource={data.guestOrder.productOrder}
          pagination={true}
        />
        <Button onClick={handleUpdateStatus}>Update Status</Button>
        <Button style={{ marginLeft: "12px" }} onClick={showModal}>
          Paid Amount
        </Button>
        <Modal
          title="Provide Status"
          open={visible}
          onOk={form.submit}
          onCancel={handleCancel1}
          visible={visible}
          okText="Sent"
          cancelText="Cancel"
        >
          <Form
            ref={forms}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            {/* {!item && (
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select value={getStatus} placeholder="Select status" allowClear>
              <Option value={myItems[0]}>{myItems[0]}</Option>
            </Select>
          </Form.Item>
        )} */}

            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true }]}
            >
              <Select
                value={statusData}
                onChange={handleChangeStatus}
                options={statusData.map((status) => ({
                  label: status,
                  value: status,
                }))}
              />
            </Form.Item>

            <Form.Item
              name="note"
              label="Note"
              // rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
            {/* <Form.Item name="user_email" label="send to">
              <Input.TextArea defaultValue={data} />
            </Form.Item> */}
          </Form>
        </Modal>

        <Modal
          title="Payment Confirmed"
          open={isModalOpen}
          footer={false}
          // onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form onFinish={onSubmitAmount}>
            <Form.Item label="Paid Amount" name="paidAmount">
              <Input placeholder="input paid amount" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="button-submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Card style={{ marginTop: 24 }} className="box">
          <h3>Noted</h3>
          {data.guestOrder.noted.map((item) => {
            return (
              <Card>
                <Row gutter={[16, 16]} justify="space-between">
                  {item.status === "paid" && (
                    <>
                      <Tag
                        style={{ textTransform: "uppercase" }}
                        color="#87d068"
                      >
                        {item.status}
                      </Tag>
                    </>
                  )}
                  {item.status === "purchased" && (
                    <>
                      <Tag
                        style={{ textTransform: "uppercase" }}
                        color="#108ee9"
                      >
                        {item.status}
                      </Tag>
                    </>
                  )}
                  {item.status === "shipped" && (
                    <>
                      <Tag
                        style={{ textTransform: "uppercase" }}
                        color="#1ac1c1"
                      >
                        {item.status}
                      </Tag>
                    </>
                  )}
                  {item.status === "delivered" && (
                    <>
                      <Tag
                        style={{ textTransform: "uppercase" }}
                        color="#e73073"
                      >
                        {item.status}
                      </Tag>
                    </>
                  )}
                  {item.status === "cancelled" && (
                    <>
                      <Tag
                        style={{ textTransform: "uppercase" }}
                        color="#cd201f"
                      >
                        {item.status}
                      </Tag>
                    </>
                  )}
                </Row>
              </Card>
            );
          })}
        </Card>
      </Content>
    </div>
  );
};

export default viewGuestOrder;
