import React from "react";
import { Form, Input, message, Button, Spin, Layout } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_BRAND, GET_BRAND } from "../../graphql/brand";
import { useParams } from "react-router";

const { Content } = Layout;

const UpdateBrand = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { loading, error, data } = useQuery(GET_BRAND, {
    variables: {
      brandId: id,
    },
  });

  const [updateBrand] = useMutation(UPDATE_BRAND);

  if (error) {
    console.error(error);
  }
  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..." />
      </center>
    );
  }

  const handleSubmit = (values) => {
    updateBrand({
      variables: {
        updateBrandId: id,
        title: values.title,
      },
    })
      .then(async (res) => {
        message.success(res.data.update_brand.message);
        window.location.replace("/dashboard/create/brand");
      })
      .catch((error) => {
        let err = JSON.parse(JSON.stringify(error));
        message.error(err.graphQLErrors[0].message);
      });
  };

  return (
    <React.Fragment>
      <Content>
        <div className="contentContainer-width">
          <h1 className="header-content">Update brand name</h1>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            size="large"
            onFinish={handleSubmit}
            initialValues={data.brand}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input category!",
                },
              ]}
            >
              <Input className="schoolInput" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="button-submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <br />
      </Content>
    </React.Fragment>
  );
};

export default UpdateBrand;
