import React from "react";
import { useQuery } from "@apollo/client";
import { PRODUCTS } from "../../graphql/products";
import { Table, Tag, Spin } from "antd";
import moment from "moment";

const ProductTable = () => {
  const { loading, error, data } = useQuery(PRODUCTS);
  if (error) {
    console.error(error);
  }
  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..."></Spin>
      </center>
    );
  }

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (thumbnail, data) => {
        return (
          <img
            style={{ borderRadius: "4px" }}
            height="40px"
            width="60px"
            src={!thumbnail[0] ? data.imagesURL[0] : thumbnail[0]}
            alt="avatar"
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "name",
      render: (productName) => {
        return <div>{productName.substring(0, 10)}...</div>;
      },
    },
    // {
    //   title: "Categories",
    //   dataIndex: "categories",
    //   key: "categories",
    //   render: (categories) => {
    //     return categories.map((category) => {
    //       return <Tag color="#7868e6">{category.title}</Tag>;
    //     });
    //   },
    // },
    // {
    //   title: "Author",
    //   dataIndex: "user",
    //   key: "user",
    //   render: (user) => {
    //     return user.fullname;
    //   },
    // },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (data) => {
        return moment(parseInt(data)).format("Do MMM YYYY");
      },
    },
    {
      title: "Updated Date",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (data) => {
        return moment(parseInt(data)).format("Do MMM YYYY");
      },
    },
  ];

  return (
    <div className="pie-box">
      <h1>New Product</h1>
      <Table
        columns={columns}
        dataSource={data.products.slice(0, 4)}
        pagination={false}
      />
    </div>
  );
};

export default ProductTable;
