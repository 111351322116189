import { Button, Spin, Table } from "antd";

import { ORDERS } from "../../graphql/order";
import React from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";

const OrderTable = () => {
  const { loading, error, data } = useQuery(ORDERS);
  if (error) {
    console.error(error);
  }
  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..."></Spin>
      </center>
    );
  }

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "productOrder",
      key: "productOrder",
      render: (productOrder, data) => (
        <span>
          {productOrder.map((res) => {
            console.log(res, "res");
            return (
              <img
                style={{ borderRadius: "4px", paddingRight: "3px" }}
                height="40px"
                width="60px"
                src={res.products[0].imagesURL}
                // src={
                //   res.products[0].thumbnail === "" || !res.products.thumbnail
                //     ? res.products[0].imagesURL
                //     : res.products[0].thumbnail
                // }
                alt="avatar"
              />
            );
          })}
        </span>
      ),
    },
    {
      title: "Pro Name",
      dataIndex: "productOrder",
      key: "productOrder",
      render: (productOrder) => (
        <span>
          {productOrder.map((res) => {
            return (
              <span>
                {res.products[0].productName.substring(0, 10) + "..."}
              </span>
            );
          })}
        </span>
      ),
    },
    {
      title: "Order Date/Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (data) => {
        return moment(parseInt(data)).format("Do MMM YYYY, hh:mm");
      },
    },
    {
      title: "Pcs.",
      dataIndex: "productOrder",
      key: "productOrder",
      render: (productOrder) => <span>{productOrder.length}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amountOrder",
      key: "amountOrder",
      render: (amountOrder) => <span>$ {amountOrder}</span>,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "csm",
      render: (customer_name) => {
        return customer_name.fullname;
      },
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },
  ];

  return (
    <div className="pie-box">
      <div>
        <h1>Orders List</h1>
      </div>

      <Table
        columns={columns}
        dataSource={data.get_orders.slice(0, 4)}
        pagination={false}
      />
      <br></br>
      <Button style={{ justifyContent: "flex-end" }} type="primary">
        <a href="/dashboard/orders">Check all</a>
      </Button>
    </div>
  );
};

export default OrderTable;
