import { gql } from "@apollo/client";

const CREATE_BRAND = gql`
  mutation ($title: String) {
    create_brand(title: $title) {
      message
    }
  }
`;

const UPDATE_BRAND = gql`
  mutation ($title: String, $updateBrandId: String) {
    update_brand(title: $title, id: $updateBrandId) {
      message
    }
  }
`;

const GET_BRAND = gql`
  query ($brandId: String) {
    brand(id: $brandId) {
      title
    }
  }
`;

const BRANDS = gql`
  query {
    brands {
      id
      title
      created_at
      updated_at
    }
  }
`;

export { BRANDS, CREATE_BRAND, GET_BRAND, UPDATE_BRAND };
