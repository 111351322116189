import { BANNERS, DELETE_BANNER, UPDATE_BANNER } from "../../graphql/banner";
import { Popconfirm, Spin, Table, Tag, message } from "antd";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { Link } from "react-router-dom";
import moment from "moment";

const Banner = ({ handleState }) => {
  const [updateId, setUpdateId] = useState("");
  const [updatebanner] = useMutation(UPDATE_BANNER);
  const [delete_banner] = useMutation(DELETE_BANNER);
  const { loading, error, data, refetch } = useQuery(BANNERS);
  if (error) {
    console.error(error);
  }
  console.log(data);
  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..." />
      </center>
    );
  }

  const columns = [
    {
      title: "image",
      dataIndex: "image",
      key: "image",
      render: (thumbnail) => {
        return (
          <img
            style={{ borderRadius: "4px" }}
            height="40px"
            width="60px"
            src={thumbnail}
            // src={`http://localhost:7000/public/uploads/${thumbnail}`}
            alt="avatar"
          />
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(parseInt(created_at)).format("Do MMMM YYYY");
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) => {
        return moment(parseInt(updated_at)).format("Do MMMM YYYY");
      },
    },
    {
      title: "Status",
      dataIndex: "id",
      render: (id, data) => {
        return (
          <React.Fragment>
            <Popconfirm
              title="Are you sure to change this banner？"
              onConfirm={() => {
                updatebanner({ variables: { id: updateId } })
                  .then((res) => {
                    message.success(res.data.update_banner.message);
                    refetch();
                  })
                  .catch((error) => {
                    let err = JSON.parse(JSON.stringify(error));
                    message.error(err.graphQLErrors[0].message);
                  });
              }}
              okText="Yes"
              cancelText="No"
              //   icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              {data.show === false ? (
                <Tag onClick={() => setUpdateId(id)} color="#ff4757">
                  Inactive{" "}
                </Tag>
              ) : (
                <Tag onClick={() => setUpdateId(id)} color="#2ed573">
                  Active
                </Tag>
              )}
            </Popconfirm>
          </React.Fragment>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (id, data) => {
        console.log(data);
        return (
          <React.Fragment>
            <Link to={`/dashboard/update/banner/${id}`}>
              <Tag color="#2176ff">Edit</Tag>
            </Link>
            <Popconfirm
              title="Are you sure to change this banner？"
              onConfirm={() => {
                delete_banner({ variables: { id: updateId } })
                  .then((res) => {
                    message.success(res.data.delete_banner.message);
                    refetch();
                  })
                  .catch((error) => {
                    let err = JSON.parse(JSON.stringify(error));
                    message.error(err.graphQLErrors[0].message);
                  });
              }}
              okText="Yes"
              cancelText="No"
              //   icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <Tag onClick={() => setUpdateId(id)} color="#ff4757">
                Delete
              </Tag>
            </Popconfirm>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Table columns={columns} dataSource={data.banners} />
    </React.Fragment>
  );
};

export default Banner;
