import { Button, Layout, Modal } from "antd";

import Brands from "./brands";
import CreateBrand from "./create-brand";
import React from "react";

const { Content } = Layout;

const MainBrands = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <Modal
        title="Add Brand Name"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <CreateBrand />
      </Modal>
      <Content>
        <div>
          <div className="flex between">
            <h2>Brands Name</h2>
            <Button type="primary" onClick={showModal}>
              New Brand
            </Button>
          </div>
          <Brands />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default MainBrands;
