import React from "react";
import { Layout } from "antd";
// import BlogsCategories from "./categories";
import CreateMainCategory from "./create-main-category";
import MainCategories from "./main-categories";

const { Content } = Layout;

const Categories = () => {
  return (
    <React.Fragment>
      <Content>
        <h1 className="header-content">Add Main Category</h1>
        <div className="contentContainer-width">
          <CreateMainCategory />
        </div>
        <br />
        <br />
        <br />
        <div>
          <MainCategories />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Categories;
