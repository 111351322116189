import axios from "axios";

// const API_URL = "https://server.jabaram.com/";
// const API_URL = "http://localhost:60011/";
const API_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PRO_API;

export const listProducts = () => {
  return axios({
    method: "GET",
    url: API_URL + "/products",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const listReviewProducts = () => {
  return axios({
    method: "GET",
    url: API_URL + "/preview/products",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const listPreviewProducts = () => {
  return axios({
    method: "GET",
    url: API_URL + "/preview/products",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteProduct = (id) => {
  return axios({
    method: "DELETE",
    url: API_URL + `/delete/product/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const approveProduct = (id) => {
  return axios({
    method: "PUT",
    url: API_URL + "/approve/product",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      id: id,
    },
  });
};

export const importLinkAmazon = (link) => {
  return axios({
    method: "POST",
    url: API_URL + "/import/link-amazon",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      link: link,
    },
  });
};

export const deleteProducts = (id) => {
  console.log(id);
  return axios({
    method: "POST",
    url: API_URL + `/delete/products`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      id: id,
    },
  });
};
