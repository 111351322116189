import React, { useState } from "react";
import { Input, Form, Button, message, Spin } from "antd";
import axios from "axios";
// import Cookies from "js-cookie";
import logo from "../assets/images/jabarom.png";
import Lottie from "lottie-react";
import shipping from "../assets/lottie/world-shipping-4.json";

const config = {
	headers: { "Content-Type": "application/json" },
	withCredentials: true,
};

export default function Login() {
	const URL =
		process.env.NODE_ENV === "development"
			? process.env.REACT_APP_DEV_API
			: process.env.REACT_APP_PRO_API;
	const [loading, setLoading] = useState(false);
	const onFinish = (values) => {
		setLoading(true);
		axios
			.post(`${URL}/login`, { ...values }, config)
			.then(async (res) => {
				if (res.data.role === "user") {
					await message.error("You are not admin", 3);
					setLoading(false);
				} else {
					await message.success(res.data.message, 3);
					window.location.replace("/dashboard");
				}
			})
			.catch((error) => {
				setLoading(false);
				message.error(error.response.data.message);
			});
	};

	return (
		<React.Fragment>
			<div>
				<Lottie
					className="loginBackground"
					animationData={shipping}
					loop={true}
				/>
				<div className="loginContainer">
					<center>
						<img className="login-logo" src={logo} alt="jabarom" />
					</center>
					<Spin spinning={loading} tip="Loading ...">
						<Form
							onFinish={onFinish}
							initialValues={{ remember: true }}
							className="login-form"
							layout="vertical"
							size="large"
						>
							{/* =================== Email ================= */}
							<Form.Item
								label="Email"
								name="email"
								rules={[
									{
										type: "email",
										message: "The email type is invalid!",
									},
									{
										required: true,
										message: "The email field is required.",
									},
								]}
							>
								<Input type="email" className="login-label" size="large" />
							</Form.Item>

							{/* =================== Password ================= */}
							<Form.Item
								label="Password"
								name="password"
								rules={[
									{
										required: true,
										message: "Please input your Password",
									},
								]}
							>
								<Input.Password
									type="password"
									className="login-label"
									size="large"
								/>
							</Form.Item>

							{/* =================== Button Submit ================= */}
							<Form.Item>
								<br />
								<Button
									type="primary"
									htmlType="submit"
									className="login-form-button"
									size="large"
								>
									Login
								</Button>
							</Form.Item>
							{/* <div style={{ marginTop: "20px" }}>
              Don't have an account yet? <Link to="/register">Register</Link>
            </div> */}
						</Form>
					</Spin>
				</div>
			</div>
		</React.Fragment>
	);
}
