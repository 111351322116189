import { Button, Layout, Modal } from "antd";

import CreateBlog from "./create-subcategory";
import React from "react";
import SubCategories from "./sub-categories";

const { Content } = Layout;

const Subcategories = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <Modal
        title="Create New Subcategory"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <CreateBlog />
      </Modal>

      <Content>
        <div className="flex between">
          <h2>Subcategories</h2>
          <Button type="primary" onClick={showModal}>
            New Subcategory
          </Button>
        </div>
        <SubCategories />
      </Content>
    </React.Fragment>
  );
};

export default Subcategories;
