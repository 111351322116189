import React, { Fragment, useState } from 'react';
import { Button, Input } from 'antd';
import { importLinkAmazon } from '../../services/products'

export const ImportLink = () => {
  const [link, setLink] = useState(null)
  const handleLink = (e) => {
    setLink(e.target.value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    importLinkAmazon(link).then((response) => {
      console.log(response);
    })
  }
  return (
    <Fragment>
      <Input.Group compact>
        <Input
          onChange={handleLink}
          style={{ width: 'calc(100% - 200px)' }}
        />
        <Button onClick={handleSubmit}>Link Amazon</Button>
      </Input.Group>
    </Fragment>
  );
};
