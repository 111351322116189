import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { CATEGORIES, CREATE_CATEGORIES } from "../../graphql/categories";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { SUB_CATEGORIES } from "../../graphql/sub-category";

const CreateCategory = ({ setIsModalOpen }) => {
  const [form] = Form.useForm();
  const { refetch } = useQuery(CATEGORIES);
  const { loading, data } = useQuery(SUB_CATEGORIES);
  const [createMutation] = useMutation(CREATE_CATEGORIES);
  const [subCategorySlug, setSubCategorySlug] = useState([]);
  console.log(subCategorySlug);
  if (loading) {
    return "loading";
  }
  const handleSubmit = (values) => {
    createMutation({
      variables: {
        title: values.title,
        subCategoryId: values.subCategoryId,
        subCategorySlug: subCategorySlug ? subCategorySlug : [],
      },
    })
      .then((res) => {
        message.success(res.data.createCategory.message);
        refetch();
        form.resetFields();
        setIsModalOpen(false);
      })
      .catch((error) => {
        let err = JSON.parse(JSON.stringify(error));
        message.error(err.graphQLErrors[0].message);
        form.resetFields();
      });
  };

  return (
    <React.Fragment>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        size="large"
        onFinish={handleSubmit}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Title is required!",
                },
              ]}
            >
              <Input className="schoolInput" />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
              label="Sub Category"
              name="subCategoryId"
              rules={[
                {
                  required: true,
                  message: "SubCategory is required!",
                },
              ]}
            >
              <Select
                showSearch
                // mode=""
                placeholder="Select a SubCategories"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {data.SubCategories.map((category) => {
                  return (
                    // eslint-disable-next-line react/jsx-no-undef
                    <Option value={category.id} key={category.id}>
                      <div onClick={() => setSubCategorySlug(category.slug)}>
                        {category.title}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="button-submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default CreateCategory;
