import { Card, Row, Tag, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Popup } from "./orders/popup";
import React, { useState } from "react";

export const Noted = ({ noted, refetch, id }) => {
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState(null);
  return (
    <Card style={{ marginTop: 24 }} className="box">
      <h3>Noted</h3>

      {noted.map((item) => {
        return (
          <Card style={{ marginTop: 24 }} className="box">
            <Row gutter={[16, 16]} justify="space-between">
              <div>
                {/* {item.status === "confirmed" && (
                  <>
                    <Tag style={{ textTransform: "uppercase" }} color="#2db7f5">
                      {item.status}
                    </Tag>
                  </>
                )}
                {item.status === "delivering" && (
                  <>
                    <Tag style={{ textTransform: "uppercase" }} color="#87d068">
                      {item.status}
                    </Tag>
                  </>
                )}
                {item.status === "delivered" && (
                  <>
                    <Tag style={{ textTransform: "uppercase" }} color="#108ee9">
                      {item.status}
                    </Tag>
                  </>
                )} */}
                {item.status === "paid" && (
                  <>
                    <Tag style={{ textTransform: "uppercase" }} color="#87d068">
                      {item.status}
                    </Tag>
                  </>
                )}
                {item.status === "purchased" && (
                  <>
                    <Tag style={{ textTransform: "uppercase" }} color="#108ee9">
                      {item.status}
                    </Tag>
                  </>
                )}
                {item.status === "shipped" && (
                  <>
                    <Tag style={{ textTransform: "uppercase" }} color="#1ac1c1">
                      {item.status}
                    </Tag>
                  </>
                )}
                {item.status === "delivered" && (
                  <>
                    <Tag style={{ textTransform: "uppercase" }} color="#e73073">
                      {item.status}
                    </Tag>
                  </>
                )}
                {item.status === "cancelled" && (
                  <>
                    <Tag style={{ textTransform: "uppercase" }} color="#cd201f">
                      {item.status}
                    </Tag>
                  </>
                )}
                <div>{item.text}</div> 
              </div>

              <div>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setVisible(true);
                    setItem(item);
                  }}
                />
              </div>
            </Row>
          </Card>
        );
      })}
      <Popup
        visible={visible}
        setVisible={setVisible}
        item={item}
        refetch={refetch}
        id={id}
      />
    </Card>
  );
};
