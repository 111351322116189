import React, { useState } from "react";
import { Modal, Form, Input, Select, Button } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import emailjs from "@emailjs/browser";
import {
  UPDATE_STATUS_ORDER,
  UPDATE_NOTED_ORDER,
  QUERY_STATUS_NOTED,
  UPDATE_PAID_AMOUNT,
} from "../../graphql/order";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const { Option } = Select;
export const Popup = ({
  visible,
  setVisible,
  status,
  id,
  refetch,
  item,
  data,
}) => {
  const forms = React.useRef();

  const [form] = Form.useForm();
  const {
    data: queryNoted,
    loading,
    refetch: refetchStatusNoted,
  } = useQuery(QUERY_STATUS_NOTED, {
    variables: { id: id },
  });

  const [getStatus] = useState(status);
  const [updateStatusOrder] = useMutation(UPDATE_STATUS_ORDER);
  const [updateNotedOrder] = useMutation(UPDATE_NOTED_ORDER);
  const [paidAmount] = useMutation(UPDATE_PAID_AMOUNT);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hideModal = () => {
    setVisible(false);
  };
  if (loading) return null;

  const statusData = [
    // "confirmed",
    "paid",
    "purchased",
    "shipped",
    "delivered",
    "cancelled",
  ];
  // const statusData = ["delivering", "delivered"];
  const statusNoted = [];
  queryNoted.queryStatusOrder.map((item) => {
    statusNoted.push(item.status);
    return true;
  });

  const myItems = statusData.filter(function (item) {
    return !statusNoted.includes(item);
  });
  const onSubmitAmount = (value) => {
    console.log(value);
    paidAmount({
      variables: {
        id: id,
        paidAmount: value.paidAmount,
      },
    }).then(() => {
      setIsModalOpen(false);
    });
  };

  const onFinish = (values) => {
    if (item) {
      // emailjs
      //   .sendForm(
      //     "service_67x2zjk",
      //     "__ejs-test-mail-service__",
      //     forms.current,
      //     "user_NLo2fUyLLdpnKrEENa6K1"
      //   )
      //   .then(
      //     (result) => {
      //       console.log(result.text);
      //     },
      //     (error) => {
      //       console.log(error.text);
      //     }
      //   );
      // console.log("edit", item.id, id, values.note);
      updateNotedOrder({
        variables: {
          id: id,
          noted: values.note === undefined ? "" : values.note,
          noteId: item.id,
        },
      })
        .then(() => {
          refetch();
          refetchStatusNoted();
          form.resetFields();
          setVisible(false);
        })
        .catch((err) => {
          console.log(err, "error");
        });
      return;
    }
    updateStatusOrder({
      variables: {
        id: id,
        status: values.status,
        noted: values.note === undefined ? "" : values.note,
      },
    })
      .then(() => {
        refetch();
        refetchStatusNoted();
        form.resetFields();
        setVisible(false);
        if (values.status === "paid") {
          setIsModalOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (item) {
    form.setFieldsValue({ note: item.text });
  }

  const handleChangeStatus = (values) => {
    console.log(values);
  };
  return (
    <>
      <Modal
        title="Provide Status"
        open={visible}
        onOk={form.submit}
        onCancel={hideModal}
        okText="Sent"
        cancelText="Cancel"
      >
        <Form
          ref={forms}
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
        >
          {/* {!item && (
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select value={getStatus} placeholder="Select status" allowClear>
              <Option value={myItems[0]}>{myItems[0]}</Option>
            </Select>
          </Form.Item>
        )} */}
          {!item && (
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true }]}
            >
              <Select
                value={statusData}
                onChange={handleChangeStatus}
                options={statusData.map((status) => ({
                  label: status,
                  value: status,
                }))}
              />
            </Form.Item>
          )}
          <Form.Item name="note" label="Note">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="user_email" label="send to">
            <Input.TextArea defaultValue={data} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Payment Confirmed"
        open={isModalOpen}
        footer={false}
        // onOk={handleOk}
        // onCancel={handleCancel}
      >
        <Form onFinish={onSubmitAmount}>
          <Form.Item label="Paid Amount" name="paidAmount">
            <Input placeholder="input paid amount" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="button-submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
