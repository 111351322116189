// import React, { useState } from "react";
// import { Form, Upload, message, Button, Input } from "antd";
// import { useMutation, useQuery } from "@apollo/client";
// import { BANNERS, CREATE_BANNER } from "../../graphql/banner";
// import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
// import beforeUpload from "../../libs/beforeUpload";
// const CreateBanner = () => {
// const URL =
//   process.env.NODE_ENV === "development"
//     ? process.env.REACT_APP_DEV_API
//     : process.env.REACT_APP_PRO_API;
// const [form] = Form.useForm();
// const { refetch } = useQuery(BANNERS);
// const [state, setState] = useState({
//   imageUrl: null,
//   loading: false,
// });
//   console.log(state.imageUrl, "imageUrl");
//   const [createbanner] = useMutation(CREATE_BANNER);
// const handleChange = (info) => {
//   console.log(info.file);
//   if (info.file.status === "uploading") {
//     setState({ loading: true });
//     return;
//   }
//   if (info.file.status === "done") {
//     setState({
//       imageUrl: info.file.response.imageUrl,
//       loading: false,
//     });
//   }
// };
//   const handleSubmit = (values) => {
//     console.log(values, "values");
// createbanner({
//   variables: {
//     image: state.imageUrl,
//     ...values,
//   },
// })
//   .then((res) => {
//     message.success(res.data.create_banner.message);
//     refetch();
//     form.resetFields();
//   })
//   .catch((error) => {
//     let err = JSON.parse(JSON.stringify(error));
//     message.error(err.graphQLErrors[0].message);
//     form.resetFields();
//   });
//   };

//   return (
//     <React.Fragment>
//       <Form
//         name="basic"
//         form={form}
//         layout="vertical"
//         size="large"
//         onFinish={handleSubmit}
//       >
// <Form.Item
//   label="Banner"
//   name="image"
//   rules={[
//     {
//       required: true,
//       message: "Title is required!",
//     },
//   ]}
// >
//   <Form.Item label="Thumbnail">
//     <Upload
//       name="thumbnail"
//       listType="picture-card"
//       className="avatar-uploader"
//       showUploadList={false}
//       action="http://localhost:60011/upload/image"
//       // action="https://server.jabaram.com/upload/image"
//       // action={`${URL}/upload/image`}
//       onChange={handleChange}
//       beforeUpload={beforeUpload}
//     >
//       {state.imageUrl ? (
//         <img
//           src={state.imageUrl}
//           alt="thumbnail"
//           style={{ height: "150px" }}
//         />
//       ) : (
//         <div>
//           {state.loading ? <LoadingOutlined /> : <PlusOutlined />}
//           <div style={{ marginTop: 8 }}>315 × 200</div>
//         </div>
//       )}
//     </Upload>
//     <div style={{ color: "red", fontSize: "13px" }}>
//       The size must under 1MB
//     </div>
//   </Form.Item>
// </Form.Item>
// <Form.Item name="link" label="Link ">
//   <Input className="schoolInput" />
// </Form.Item>

// <Form.Item>
//   <Button type="primary" htmlType="submit" className="button-submit">
//     Submit
//   </Button>
// </Form.Item>
//       </Form>
//     </React.Fragment>
//   );
// };

import { BANNERS, CREATE_BANNER } from "../../graphql/banner";
import { Button, Form, Input, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
// export default CreateBanner;
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import beforeUpload from "../../libs/beforeUpload";

const CreateBanner = () => {
  const URL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_API
      : process.env.REACT_APP_PRO_API;
  const [form] = Form.useForm();
  const { refetch } = useQuery(BANNERS);
  const [state, setState] = useState({
    imageUrl: null,
    loading: false,
  });
  const [createbanner] = useMutation(CREATE_BANNER);
  const handleChange = (info) => {
    console.log(info.file);
    if (info.file.status === "uploading") {
      setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      setState({
        imageUrl: info.file.response.imageUrl,
        loading: false,
      });
    }
  };

  const handleSubmit = (values) => {
    createbanner({
      variables: {
        image: state.imageUrl,
        link: values.link,
      },
    })
      .then((res) => {
        message.success(res.data.create_banner.message);
        refetch();
        form.resetFields();
      })
      .catch((error) => {
        let err = JSON.parse(JSON.stringify(error));
        message.error(err.graphQLErrors[0].message);
        form.resetFields();
      });
  };
  return (
    <React.Fragment>
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <Form.Item
          label="Banner"
          name="image"
          // rules={[
          //   {
          //     required: true,
          //     message: "Banner is required!",
          //   },
          // ]}
        >
          <Upload
            name="thumbnail"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            // action="http://localhost:60011/upload/image"
            // action="https://server.jabaram.com/upload/image"
            action={`${URL}/upload/image`}
            onChange={handleChange}
            beforeUpload={beforeUpload}
          >
            {state.imageUrl ? (
              <img
                src={state.imageUrl}
                alt="thumbnail"
                style={{ height: "150px" }}
              />
            ) : (
              <div>
                {state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>315 × 200</div>
              </div>
            )}
          </Upload>
          <div style={{ color: "red", fontSize: "13px" }}>
            The size must under 1MB
          </div>
        </Form.Item>
        <Form.Item name="link" label="Link ">
          <Input className="schoolInput" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="button-submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default CreateBanner;
