import Banners from "./banners";
// import SubCategories from "./sub-categories";
import CreateBanners from "./create-banner";
import { Layout } from "antd";
import React from "react";

const { Content } = Layout;

const CreateBanner = () => {
  return (
    <React.Fragment>
      <Content>
        <h1 className="header-content">Add Banner</h1>
        <div className="contentContainer-width">
          <CreateBanners />
        </div>
        <br />
        <br />
        <br />
        <div>
          <Banners />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default CreateBanner;
