import { Outlet, Route } from "react-router-dom";
import React, { useEffect } from "react";

import { Layout } from "antd";
import MyFooter from "./components/layout/footer";
import NavBar from "./components/layout/navbar";
import SideNavbar from "./components/layout/side-navbar";
import axios from "axios";

// Global varible

const { Content } = Layout;

const PrivateRoute = () => {
  const URL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_API
      : process.env.REACT_APP_PRO_API;
  const [isLogin, setIsLogin] = React.useState("");
  const [role, setRole] = React.useState("");

  const verifyToken = async () => {
    await axios
      .get(`${URL}/token/verify`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      .then((res) => {
        setIsLogin(res.data.success);
        setRole(res.data.role);
      })
      .catch(async (err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    verifyToken();
    setInterval(() => {
      verifyToken();
    }, 14 * 60 * 1000);
  }, []);

  if (role === "user" || isLogin === false) {
    window.location.replace("/logout");
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SideNavbar />
      <Layout style={{ padding: "15px 50px" }}>
        {/* <NavBar /> */}
        <Content className="content-padding">
          <Outlet />
        </Content>
        <MyFooter />
      </Layout>
    </Layout>
  );
};

export default PrivateRoute;
