import React, { useState, useRef } from "react";
import {
  Layout,
  Table,
  Spin,
  Tag,
  Radio,
  Popover,
  Popconfirm,
  message,
  Input,
  Space,
  Button,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import Highlighter from "react-highlight-words";
import {
  QUOATATOINS,
  DELETE_QUOTATION,
  UPDATE_STATUS_QUOTE,
} from "../../graphql/quotation";
import { Link } from "react-router-dom";
import { QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";

const { Content } = Layout;
const Quotations = () => {
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [update_status_quote] = useMutation(UPDATE_STATUS_QUOTE);
  const [delete_quoatation] = useMutation(DELETE_QUOTATION);
  const [ids, setIds] = useState("");
  const [id, setId] = useState("");
  const { loading, data, refetch } = useQuery(QUOATATOINS);
  if (loading || !data) {
    return (
      <center>
        <Spin tip="Loading ..."></Spin>
      </center>
    );
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const onChange = (e) => {
    setValue(e.target.value);
    update_status_quote({
      variables: {
        id,
        status: e.target.value,
      },
    }).then(() => {
      message.success("update successfully");
      refetch();
    });
  };
  const content = (
    <div>
      <Radio.Group onChange={onChange} value={value}>
        <Radio value="Waiting">Waiting</Radio>
        <Radio value="Sent">Sent</Radio>
        <Radio value="Create Order">Create Order</Radio>
      </Radio.Group>
    </div>
  );
  const columns = [
    {
      title: "ID",
      dataIndex: "JBID",
      key: "JBID",
      render: (_, data) => {
        console.log(data, "datas");
        return (
          <div>
            {data.type === "create" ? (
              <p>{data.JBID}</p>
            ) : (
              <p>{data.request_quote.JBID}</p>
            )}
          </div>
        );
      },
      ...getColumnSearchProps("JBID"),
    },
    // {
    //   title: "Thumbnail",
    //   dataIndex: "thumbnail",
    //   key: "thumbnail",
    //   render: (data, thumbnail) => {
    //     console.log(thumbnail);
    //     return (
    //       <img
    //         style={{ borderRadius: "4px" }}
    //         height="40px"
    //         width="60px"
    //         src={`http://localhost:60011/${thumbnail.product[0].thumbnail}`}
    //         alt="avatar"
    //       />
    //     );
    //   },
    // },

    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      ...getColumnSearchProps("customerName"),
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      ...getColumnSearchProps("customerEmail"),
    },
    {
      title: "Customer Phone",
      dataIndex: "customerPhone",
      key: "customerName",
      ...getColumnSearchProps("customerPhone"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (data) => {
        return (
          <>
            {data === "create" ? (
              <Tag color="blue-inverse">{data}</Tag>
            ) : (
              <Tag color="green-inverse">{data}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (data) => {
        return moment(parseInt(data)).format("Do MMM YYYY");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, data) => {
        return (
          <div
            onClick={() => setId(data.id)}
            style={{ cursor: "pointer" }}
            className="table-action"
          >
            <Popover
              content={content}
              title="Update Status"
              trigger="click"
              placement="topLeft"
            >
              {status === "Waiting" && (
                <Tag color="red-inverse" placement="top">
                  {status}
                </Tag>
              )}
              {status === "Sent" && (
                <Tag color="green-inverse" placement="top">
                  {status}
                </Tag>
              )}
              {status === "Create Order" && (
                <Tag color="blue-inverse" placement="top">
                  {status}
                </Tag>
              )}
            </Popover>
          </div>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "id",
      key: "action",
      render: (id) => {
        return (
          <React.Fragment>
            <Link to={`/dashboard/view/quote/${id}`}>
              <Tag color="#5339d1">view</Tag>
            </Link>

            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => {
                delete_quoatation({ variables: { id: ids } })
                  .then((res) => {
                    message.success("delete successfully");
                    refetch();
                  })
                  .catch((error) => {
                    message.error("can not delete!");
                  });
              }}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <Tag
                style={{ cursor: "pointer" }}
                onClick={() => setIds(id)}
                color="#ff4333"
              >
                Delete
              </Tag>
            </Popconfirm>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <div>
      <Content>
        <h1>Quotations</h1>
      </Content>
      <Table columns={columns} dataSource={data.quotations} />
    </div>
  );
};

export default Quotations;
