import { gql } from "@apollo/client";

const ORDERS = gql`
  query {
    get_orders {
      id
      orderId
      amountOrder
      userId
      status
      phone
      created_at
      qty
      noted {
        id
        status
        text
      }
      contacted
      productOrder {
        qty
        products {
          id
          productName
          thumbnail
          imagesURL
          originalPrice
          discountPrice
          shippingCost
          discount
          usPrice
        }
      }
      addressOrder {
        address
        city
        district
        phone
      }
      customer_name {
        fullname
        email
      }
    }
  }
`;
const ORDER = gql`
  query ($id: String!) {
    get_order(id: $id) {
      orderId
      color
      size
      id
      paidAmount
      amountOrder
      userId
      qty
      phone
      contacted
      created_at
      status
      customer_name {
        fullname
        email
      }
      productOrder {
        color
        size
        id
        qty
        productId
        products {
          id
          productName
          thumbnail
          originalPrice
          discountPrice
          shippingCost
          discount
          usPrice
          link
          imagesURL
        }
      }
      addressOrder {
        address
        city
        district
        phone
        homeStreet
      }
      noted {
        id
        status
        text
      }
    }
  }
`;

const DELETE_ORDER = gql`
  mutation ($id: String!) {
    delete_order(id: $id) {
      message
    }
  }
`;

const STATUS_CONTACT = gql`
  mutation ($id: String!, $contacted: Boolean) {
    status_contact(id: $id, contacted: $contacted) {
      message
    }
  }
`;

const GUEST_ORDERS = gql`
  query {
    guestOrders {
      id
      fullname
      email
      phone
      address
      amountOrder
      status
      productOrder {
        qty
        products {
          id
          productName
          thumbnail
          originalPrice
          discountPrice
          shippingCost
          discount
          usPrice
        }
      }
      noted {
        id
        status
        text
      }
      city
      district
      contacted
      created_at
    }
  }
`;

const GUEST_ORDER = gql`
  query ($id: String!) {
    guestOrder(id: $id) {
      id
      fullname
      paidAmount
      homeStreet
      email
      phone
      address
      amountOrder
      productOrder {
        qty
        products {
          id
          productName
          thumbnail
          originalPrice
          discountPrice
          shippingCost
          discount
          usPrice
        }
      }
      noted {
        id
        status
        text
      }
      city
      district
      status
      contacted
      created_at
    }
  }
`;

const UPDATE_STATUS_ORDER = gql`
  mutation ($id: String!, $status: String!, $noted: String!) {
    updateStatus(id: $id, status: $status, noted: $noted) {
      message
    }
  }
`;
const UPDATE_STATUS_GUEST_ORDER = gql`
  mutation ($id: String!, $status: String!, $noted: String!) {
    updateStatusGuest(id: $id, status: $status, noted: $noted) {
      message
    }
  }
`;
const UPDATE_PAID_AMOUNT = gql`
  mutation ($id: String!, $paidAmount: String!) {
    paid_amount(id: $id, paidAmount: $paidAmount) {
      message
    }
  }
`;

const UPDATE_PAID_AMOUNT_GUEST = gql`
  mutation ($id: String!, $paidAmount: String) {
    paid_amount_guest(id: $id, paidAmount: $paidAmount) {
      message
    }
  }
`;

const UPDATE_NOTED_ORDER = gql`
  mutation ($id: String!, $noted: String!, $noteId: String!) {
    updateNoted(id: $id, noted: $noted, noteId: $noteId) {
      message
    }
  }
`;

const UPDATE_QTY_ORDER = gql`
  mutation ($id: String!, $productOrderId: String!, $qty: Int!) {
    update_order_qty(id: $id, productOrderId: $productOrderId, qty: $qty) {
      message
    }
  }
`;

const QUERY_STATUS_NOTED = gql`
  query ($id: String!) {
    queryStatusOrder(id: $id) {
      id
      status
      text
    }
  }
`;

export {
  ORDERS,
  ORDER,
  DELETE_ORDER,
  STATUS_CONTACT,
  GUEST_ORDER,
  GUEST_ORDERS,
  UPDATE_STATUS_ORDER,
  UPDATE_NOTED_ORDER,
  QUERY_STATUS_NOTED,
  UPDATE_PAID_AMOUNT,
  UPDATE_PAID_AMOUNT_GUEST,
  UPDATE_STATUS_GUEST_ORDER,
  UPDATE_QTY_ORDER,
};
