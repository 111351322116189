import { Button } from "antd";
import React, { useState, useRef } from "react";
import Papa from "papaparse";
import axios from "axios";
// import { listProducts } from "../../services/products";a
import { UploadOutlined } from "@ant-design/icons";

export const ImportButton = ({ setItems }) => {
  const API_URL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_API
      : process.env.REACT_APP_PRO_API;
  const [uploading, setUploading] = useState(false);
  const inputRef = useRef();
  const handleUploadCSV = () => {
    setUploading(true);

    const input = inputRef?.current;
    const reader = new FileReader();
    const [file] = input.files;

    reader.onloadend = async ({ target }) => {
      // .catch((error) => {
      //   setUploading(false);
      // });
      try {
        const csv = Papa.parse(target.result, { header: true });
        console.log(csv?.data);
        await axios(`${API_URL}/import/products`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            csv: csv?.data,
          }),
        }).then((res) => {
          console.log(res.status);
        });

        //   .then(() => {
        //   console.log("running");
        //   setUploading(false);
        //   listProducts().then((response) => {
        //     setItems(response.data.data);
        //   });
        window.location.reload();
        // });
      } catch (error) {
        window.location.reload();
        setUploading(false);
      }
    };
    window.location.reload();
    reader.readAsText(file);
  };
  return (
    <>
      <input
        ref={inputRef}
        disabled={uploading}
        type="file"
        className="form-control"
      />
      <Button
        shape="round"
        size="middle"
        icon={<UploadOutlined />}
        onClick={handleUploadCSV}
        disabled={uploading}
      >
        {uploading ? "Uploading..." : "Import"}
      </Button>
    </>
  );
};
