import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Spin,
  Upload,
  message,
  Space,
} from "antd";
import { PRODUCT, UPDATE_PRODUCT } from "../../graphql/products";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import ReactQuill from "react-quill";
import { BRANDS } from "../../graphql/brand";
import { SUB_CATEGORIES } from "../../graphql/sub-category";
import beforeUpload from "../../libs/beforeUpload";
import Icon, { UploadOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { stringify, v4 as uuidv4 } from "uuid";
import {
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
const { Content } = Layout;
const { Option } = Select;

const URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PRO_API;

const UplaodTestimonial = () => {
  const { id } = useParams();
  const [slug, setSlug] = useState([]);
  const [pri, setPri] = useState(0);
  const [wieght, setWieght] = useState(0);
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [createProduct] = useMutation(UPDATE_PRODUCT);
  const [thumbnail, setThumbnail] = useState([]);
  const [fileList, setFileList] = useState([]);
  const { loading: cateLoading, data: cateData } = useQuery(SUB_CATEGORIES);
  const [state, setState] = useState({
    imageUrl: null,
    loading: false,
  });

  const { loading, data } = useQuery(PRODUCT, {
    variables: {
      id,
    },
  });

  const { loading: brandLoading, data: brandData } = useQuery(BRANDS);

  // const handleChange = (info) => {
  //   console.log(info);
  //   if (info.file.status === "uploading") {
  //     setState({ loading: true });
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     setState({
  //       imageUrl: info.fileList.map(
  //         (res) => `${URL}/${res.response.file[0].path}`
  //       ),
  //       loading: false,
  //     });
  //   }
  // };

  useEffect(() => {
    if (data) {
      const images = data.product.thumbnail;
      let _images = [];
      images.map((image) => {
        _images.push({
          uid: uuidv4(),
          name: image,
          status: "done",
          imageUrl: image,
        });
      });
      // setFileList(_images);
      setFileList(_images);
      setThumbnail(_images.map((res) => res.imageUrl));
    }
  }, [data]);

  // console.log(fileList, "fileList");
  // console.log(thumbnail, "thumbnail");

  const handleChange = (info) => {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setFileList(info.fileList.map((res) => res.response.file[0].path));
      setThumbnail(info.file.map((res) => res.response.file[0].path));
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      // setImageUrl(info)
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleSubmit = async (values) => {
    console.log(values.imagesURL.length, "values");
    // const saveDescription = await instanceRef.current.save();
    createProduct({
      variables: {
        userId: "1",
        id: id,
        approved: values.approved === "checked" ? true : values.approved,
        productName: values.productName ? values.productName : "",
        originalPrice:
          values.originalPrice === ""
            ? data.product.originalPrice
            : Number(values.originalPrice),
        // originalPrice:
        //   pri * 0.12 + wieght * 10 + parseInt(pri)
        //     ? pri * 0.12 + wieght * 10 + parseInt(pri)
        //     : values.originalPrice,
        // clearance: values.clearance ? values.clearance : "",
        // shippingCost: values.shipping ? parseInt(values.shipping) : 0,
        // shippingCost: pri * 0.12 + wieght * 10 ? pri * 0.12 + wieght * 10 : 0,
        // star: values.star ? parseInt(values.star) : "",
        rating: values.rating ? parseInt(values.rating) : 0,
        // thumbnail: state.imageUrl === null ? thumbnail : state.imageUrl,
        thumbnail: thumbnail,
        // usPrice: values.usPrice ? Number(values.usPrice) : Number(pri),
        // weight: values.weight ? Number(values.weight) : Number(wieght),
        usPrice:
          values.usPrice === "" ? data.product.usPrice : Number(values.usPrice),
        weight:
          values.weight === "" ? data.product.weight : Number(values.weight),
        shippingCost:
          values.shippingCost === ""
            ? data.product.shippingCost
            : Number(values.shippingCost),
        asin: values.asin ? values.asin : "",
        discount: values.discount ? parseInt(values.discount) : 0,
        // imagesURL: values.imagesURL ? values.imagesURL : [],
        link: values.link ? values.link : "",
        categoriesId: values.categoriesId ? values.categoriesId : "",
        categoriesSlug: slug ? slug : "",
        details: values.details ? values.details : "",
        descriptions: values.descriptions ? values.descriptions : "",
        brandName: values.brandName ? values.brandName : "",
        // imagesURL: data.product.imagesURL,
        imagesURL:
          values.imagesURL.length == 0
            ? data.product.imagesURL.map((res) => res)
            : values.imagesURL.map((res) => res.imagesURL),
      },
    })
      .then(async (res) => {
        await message.success(res.data.updateProduct.message, 3);
        window.location.replace("/dashboard/product/all");
      })
      .catch((error) => console.error(error));
  };

  const imageurl = data?.product.imagesURL.map((res) => res);

  if (
    loading ||
    brandLoading ||
    !data ||
    cateLoading ||
    cateData === undefined
  ) {
    return (
      <center>
        <Spin tip="Loading..."></Spin>
      </center>
    );
  }
  const handleChanges = (value) => {
    setSlug(value);
    console.log("select", value);
  };
  console.log(data.product, "dataproduct");
  return (
    <React.Fragment>
      <Content>
        <h2 className="header-content">Update Your Products</h2>
        <div className="contentContainer-width">
          <Form
            initialValues={data.product}
            defaultValue={data.product}
            name="basic"
            layout="vertical"
            size="large"
            onFinish={handleSubmit}
            form={form}
          >
            <Row gutter={[32, 0]}>
              <Col span={20}>
                <Row gutter={[12, 12]}>
                  <Col span={8}>
                    <Form.Item
                      label="Name"
                      name="productName"
                      rules={[
                        {
                          required: true,
                          message: "Name is required!",
                        },
                      ]}
                    >
                      <Input className="schoolInput" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="originalPrice"
                      label="Main Price"
                      rules={[
                        {
                          required: true,
                          message: "Price is required!",
                        },
                      ]}
                    >
                      <Input
                        style={{ color: "#38a7c8" }}
                        type="number"
                        className="schoolInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="shippingCost" label="Shipping">
                      <Input type="number" className="schoolInput" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[12, 12]}>
                  <Col span={8}>
                    <Form.Item name="usPrice" label="Us Price">
                      <Input
                        onChange={(e) => {
                          setPri(e.target.value);
                          setWieght(data.product.weight);
                        }}
                        className="schoolInput"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="weight" label="Weight">
                      <Input
                        onChange={(e) => setWieght(Number(e.target.value))}
                        type="number"
                        className="schoolInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Sub-category"
                      name="categoriesId"
                      rules={[
                        {
                          required: true,
                          message: "Category is required!",
                        },
                      ]}
                    >
                      <Select
                        onChange={handleChanges}
                        showSearch
                        // mode="multiple"
                        placeholder="Select a Categories"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cateData.SubCategories.map((category) => {
                          return (
                            <Option
                              onClick={() => setSlug(category.slug)}
                              value={category.slug}
                              key={category.id}
                            >
                              {category.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Discount" name="discount">
                      <Input type="number" className="schoolInput" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Asin" name="asin">
                      <Input className="schoolInput" />
                    </Form.Item>
                  </Col>
                  <Col span={24 / 3}>
                    <Form.Item label="Brand name" name="brandName">
                      <Select
                        showSearch
                        placeholder="Select a brand name"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {brandData.brands.map((brand, index) => {
                          return (
                            <Option value={brand.title} key={index}>
                              {brand.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Rating" name="rating">
                      <Input className="schoolInput" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Link" name="link">
                      <Input className="schoolInput" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.List name="imagesURL">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Space key={field.key} align="baseline">
                              <Form.Item
                                {...field}
                                label="Image Link"
                                name={[field.name, "imagesURL"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing image Link",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>

                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add sights
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    {/* <Form.List name="imagesURL">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...resetField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                label="Image-Link"
                                name={[name, "imagesURL"]}
                              >
                                <Input className="schoolInput" />
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              size="small"
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add field
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List> */}
                  </Col>
                </Row>
                <Form.Item label="description" name="descriptions">
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    placeholder="Write something..."
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Thumbnail" name="thumbnail">
                  <Upload
                    multiple="true"
                    name="multi-files"
                    listType="text"
                    className="avatar-uploader"
                    showUploadList={true}
                    action={`${URL}/multiple-upload`}
                    onChange={handleChange}
                    // fileList={fileList}
                    defaultFileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  {/* <Upload
                    multiple="true"
                    name="multi-files"
                    listType="text"
                    className="avatar-uploader"
                    showUploadList={true}
                    fileList={fileList}
                    // action="https://server.jabaram.com/upload/image"
                    action={`${URL}/multiple-upload`}
                    // action="https://server.jabaram.com/multiple-upload"
                    onChange={handleChange}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload> */}
                  {/* <Upload
                    multiple="true"
                    name="multi-files"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={true}
                    // fileList={thumbnail}
                    // action="https://server.jabaram.com/upload/image"
                    action="http://localhost:60011/multiple-upload"
                    // action="https://server.jabaram.com/multiple-upload"
                    onChange={handleChange}
                  >
                    <div>
                      {state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>315 × 200</div>
                    </div>
                  </Upload> */}
                  {/* <Upload
                    // name="thumbnail"
                    multiple={true}
                    name="multi-files"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={true}
                    action="http://localhost:60011/multiple-upload"
                    // action="https://server.jabaram.com/upload/image"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    // fileList={thumbnail}
                  >
                    {state.imageUrl ? (
                      <img
                        src={state.imageUrl[0]}
                        alt="thumbnail"
                        style={{ width: "120px", height: "100px" }}
                      />
                    ) : (
                      <img
                        src={`${thumbnail[0]}`}
                        alt="thumbnail"
                        style={{ width: "120px", height: "100px" }}
                      />
                    )}
                    <div>
                      {state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>315 × 200</div>
                    </div>
                  </Upload> */}
                  <center>
                    <div style={{ color: "red", fontSize: "13px" }}>
                      The size must under 1MB. Size: 315 × 200
                    </div>
                  </center>
                </Form.Item>
                <Form.Item
                  name="approved"
                  valuePropName={
                    data.product.approved === true ? "checked" : false
                  }
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Checkbox>Approve this product</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default UplaodTestimonial;
