import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Table,
  Tag,
} from "antd";
import {
  ORDER,
  UPDATE_PAID_AMOUNT,
  UPDATE_QTY_ORDER,
} from "../../graphql/order";
import React, { useState } from "react";

import { Link } from "react-router-dom";
// import { DELETE_ORDER } from "../../graphql/order";
import { Noted } from "../noted";
import { Popup } from "./popup";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";

const { Content } = Layout;
const ViewOrder = () => {
  const forms = React.useRef();
  const [form] = Form.useForm();
  // const [delete_order] = useMutation(DELETE_ORDER);
  const [visible, setVisible] = useState(false);
  const [paidAmount] = useMutation(UPDATE_PAID_AMOUNT);
  const [update_order_qty] = useMutation(UPDATE_QTY_ORDER);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productOrderId, setProductOrderId] = useState("");
  const { id } = useParams();

  const { loading, data, refetch } = useQuery(ORDER, {
    variables: {
      id,
    },
  });
  if (loading) return "loading...";
  console.log(data, "data");
  const handleUpdateStatus = () => {
    setVisible(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const onSubmitAmount = (value) => {
    paidAmount({
      variables: {
        id: id,
        paidAmount: value.paidAmount,
      },
    }).then(() => {
      refetch();
      setIsModalOpen(false);
    });
  };

  const onSubmitQty = (values) => {
    console.log(values, "values");
    update_order_qty({
      variables: {
        id: id,
        productOrderId: productOrderId,
        qty: Number(values.qty),
      },
    }).then(() => {
      message.success("update successfully");
      refetch();
      form.resetFields();
      handleOk1();
    });
  };

  const proId = (id) => {
    setProductOrderId(id);
  };

  const columns = [
    {
      title: "Number",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Thumbnail",
      dataIndex: "products",
      key: "thumbnail",
      render: (products) => {
        return (
          <img
            style={{ borderRadius: "4px" }}
            height="40px"
            width="40px"
            // src={products[0].thumbnail[0]}
            src={
              products[0].thumbnail[0] === ""
                ? products[0].imagesURL[0]
                : products[0].thumbnail[0]
            }
            alt="avatar"
          />
        );
      },
    },
    {
      title: "ProductName",
      dataIndex: "products",
      render: (products, data) => {
        return (
          <div style={{ width: "200px" }}>
            <a
              style={{ color: "black" }}
              href={products[0].link}
              target="_blank"
              rel="noreferrer"
            >
              {products[0].productName}
            </a>
          </div>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "qty",
      render: (products, data) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal1();
              proId(data.id);
            }}
          >
            <Tag color="orange-inverse">{data.qty}</Tag>
          </div>
        );
      },
    },
    {
      title: "Size & Color",
      dataIndex: "size",
      render: (products, data) => {
        console.log(data);
        return (
          <div
            style={{ cursor: "pointer" }}
            // onClick={() => {
            //   showModal1();
            //   proId(data.id);
            // }}
          >
            <Tag color={data.color}>{data.size}</Tag>
          </div>
        );
      },
    },

    {
      title: "Price after Tax * Qty",
      dataIndex: "products",
      key: "products",
      render: (products, data) => (
        <span>
          {products.map((res) => {
            return <div>$ {(res.usPrice * data.qty).toFixed(2)}</div>;
          })}
        </span>
      ),
    },

    {
      title: "Shipping",
      dataIndex: "products",
      key: "products",
      render: (products, data) => {
        return (
          <span>
            {products.map((res) => {
              return <div>$ {(res.shippingCost * data.qty).toFixed(2)}</div>;
            })}
          </span>
        );
      },
    },
    {
      title: "Discount",
      dataIndex: "products",
      key: "products",
      render: (products) => (
        <span>
          {products.map((res) => {
            return <div>{res.discount}</div>;
          })}
        </span>
      ),
    },
    {
      title: "Price in Cambodia * Qty",
      dataIndex: "products",
      key: "products",
      render: (products, data) => (
        <span>
          {products.map((res) => {
            return <div>$ {(res.discountPrice * data.qty).toFixed(2)}</div>;
          })}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "products",
      key: "products",
      render: (products) => (
        <span>
          {products.map((res) => {
            return (
              <Link to={`/dashboard/product/edit-products/${res.id}`}>
                <Tag color="#5339d1">Edit</Tag>
              </Link>
            );
          })}
        </span>
      ),
    },
  ];

  // const data1 = [];
  // const mainData = data.get_order.productOrder;
  // const subData = mainData.map((res) => res.products);
  // console.log(subData, "subData");
  // subData.map((res) => res.map((ress) => data1.push(ress.discountPrice)));
  // const total = data1.reduce((accumulator, value) => {
  //   return accumulator + value;
  // }, 0);

  const data1 = [];
  // eslint-disable-next-line array-callback-return
  data.get_order.productOrder.map((item) => {
    // usPrices.push(item.usPrice);
    item.products.map((res) => data1.push(item.qty * res.discountPrice));
  });
  const total = data1.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  return (
    <React.Fragment>
      <Content className="order-details-sec">
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <h2>Order Summary</h2>
            <p>
              Order ID :{" "}
              {!data.get_order.orderId
                ? data.get_order.id
                : data.get_order.orderId}
            </p>
            <p>Status : {data.get_order.status}</p>
            <p>
              Date :{" "}
              {moment(parseInt(data.get_order.created_at)).format(
                "Do MMM YYYY, hh:mm"
              )}
            </p>
            <p>
              Total: <b style={{ color: "#05c46b" }}>${total.toFixed(2)}</b>
              {/* <b style={{ color: "green" }}>$ {data.get_order.amountOrder}</b> */}
            </p>
            <p>
              Paid Amount:{" "}
              <b style={{ color: "red" }}>
                {!data.get_order.paidAmount
                  ? "Don't pay yet"
                  : `$${parseFloat(data.get_order.paidAmount).toFixed(2)}`}
              </b>
            </p>
          </Col>
          <Col span={8}>
            <h2>Shipping Address</h2>
            <div>
              <h1>
                Address:
                {data.get_order.addressOrder.city} /
                {data.get_order.addressOrder.district} /
                {/* {data.get_order.addressOrder.homeStreet} */}
                {data?.get_order?.addressOrder?.homeStreet === null ||
                !data?.get_order?.addressOrder?.homeStreet
                  ? ""
                  : data?.get_order?.addressOrder?.homeStreet}
              </h1>
            </div>
          </Col>
          <Col span={8}>
            <h2>Recipient</h2>
            <p>Name: {data.get_order.customer_name.fullname}</p>
            <p>Email: {data.get_order.customer_name.email}</p>
            <p>
              Phone:{" "}
              {data.get_order.addressOrder.phone === ""
                ? "no phone"
                : data.get_order.addressOrder.phone}{" "}
            </p>
          </Col>
        </Row>
        <br />

        <Table
          columns={columns}
          dataSource={data.get_order.productOrder}
          pagination={true}
        />
        <Card style={{ marginTop: 24 }} className="box">
          <Button onClick={handleUpdateStatus}>Update Status</Button>
          <Button style={{ marginLeft: "12px" }} onClick={showModal}>
            Paid Amount
          </Button>
          <Modal
            title="Update order QTY"
            okText="Update"
            open={isModalOpen1}
            onOk={form.submit}
            onCancel={handleCancel1}
          >
            <Form form={form} ref={forms} onFinish={onSubmitQty}>
              <Form.Item lable="QTY" name="qty">
                <Input placeholder="input Qty" />
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Payment Confirmed"
            open={isModalOpen}
            footer={false}
            // onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form onFinish={onSubmitAmount}>
              <Form.Item label="Paid Amount" name="paidAmount">
                <Input placeholder="input paid amount" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Popup
            visible={visible}
            setVisible={setVisible}
            data={data.get_order.customer_name.email}
            status={data.get_order.status}
            id={data.get_order.id}
            refetch={refetch}
          />
        </Card>
        <Noted
          noted={data.get_order.noted}
          refetch={refetch}
          id={data.get_order.id}
        />
      </Content>
    </React.Fragment>
  );
};

export default ViewOrder;
