import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Tag, Tooltip, Popconfirm, Button } from "antd";
import { ImportLink } from "../components/actions/importLink";
import { ImportButton } from "../components/actions/ImportButton";
import { TablePreview } from "../components/tables/TablePreview";
import { listPreviewProducts, deleteProduct } from "../services/products";
import {
  QuestionCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";

export const PreviewProduct = () => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    listPreviewProducts().then((response) => {
      setItems(response.data.data);
    });
  }, []);
  console.log(items);

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (thumbnail) => {
        return (
          <img
            style={{ borderRadius: "4px" }}
            height="40px"
            width="40px"
            src={thumbnail[0]}
            alt="avatar"
          />
        );
      },
    },
    {
      title: "ImageURL",
      dataIndex: "imagesURL",
      key: "thumbnail",
      render: (imagesURL) => {
        return (
          <img
            style={{ borderRadius: "4px" }}
            height="40px"
            width="40px"
            src={imagesURL}
            alt="avatar"
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
      render: (data) => {
        return <div>{data.substring(0, 20) + "..."}</div>;
      },
    },
    {
      title: "Asin",
      dataIndex: "asin",
      key: "asin",
    },
    // {
    //   title: "Categories",
    //   dataIndex: "categoriesId",
    //   key: "categoriesId",
    //   render: (categoriesID) => {
    //     return categoriesID.map((category) => {
    //       return <Tag color="#7868e6">{category}</Tag>;
    //     });
    //   },
    // },
    {
      title: "Original Price",
      dataIndex: "originalPrice",
      key: "originalPrice",
    },
    {
      title: "Shipping Cost",
      dataIndex: "shippingCost",
      key: "shippingCost",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Actions",
      dataIndex: "object",
      key: "object",
      render: (object) => {
        return (
          <>
            <Popconfirm
              title="Are you sure to delete this product？"
              onConfirm={() => {
                try {
                  deleteProduct(object._id).then(() => {
                    listPreviewProducts().then((response) => {
                      setItems(response.data.data);
                    });
                  });
                } catch (e) {
                  throw Error(e);
                }
              }}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
            <Tooltip title="Display at amazon">
              <a target="_blank" rel="noreferrer" href={object.link}>
                <Button
                  style={{ marginLeft: 8 }}
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </a>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const data = [];
  console.log(items);
  items.forEach((element, i) => {
    if (element.approved === false) {
      data.push({
        key: element._id,
        object: element,
        ...element,
      });
    }
  });
  return (
    <Fragment>
      <Row>
        <Col span={12}>
          <ImportLink />
        </Col>
        <Col span={12} align="right">
          <ImportButton setItems={setItems} />
        </Col>
        {data.length}
        <Col span={24}>
          {data && (
            <TablePreview data={data} columns={columns} setItems={setItems} />
          )}
        </Col>
      </Row>
    </Fragment>
  );
};
