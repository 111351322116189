import { Divider, Layout, Menu, Typography } from "antd";
import { NavLink } from "react-router-dom";
import React from "react";
import logo from "../../assets/images/jabarom.png";

const { Sider } = Layout;
// const { SubMenu } = Menu;

const SideNavbar = () => {
  const [pathname, setPathname] = React.useState(window.location.pathname);

  const subMenu = window.location.pathname.split("/")[2];

  React.useEffect(() => {
    setPathname(window.location.pathname);
  }, [pathname]);

  return (
    <React.Fragment>
      <Sider theme="light" width={250} breakpoint="lg" collapsedWidth="0">
        <center>
          <div className="logos">
            <NavLink to="/dashboard">
              {/* <h1 className="jabarom-logo">JabArom</h1> */}
              <img src={logo} className="vuthy-logo" alt="logo" />
            </NavLink>
          </div>
        </center>
        <Menu
          defaultSelectedKeys={[pathname]}
          defaultOpenKeys={[subMenu]}
          // onOpenChange={onOpenChange}
          theme="light"
          mode="inline"
          className="menu-categories"
        >
          <Menu.Item
            key="/dashboard"
            icon={<img src="/images/dashboard.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard">Overview</NavLink>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/users"
            icon={<img src="/images/man.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/users">Users</NavLink>
          </Menu.Item>
          {/* <Typography.Title level={4}>Order</Typography.Title> */}
          <Divider orientation="left" plain>
            Order
          </Divider>
          <Menu.Item
            key="/dashboard/orders"
            icon={<img src="/images/order.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/orders">Customer Order</NavLink>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/guestOrders"
            icon={
              <img src="/images/online-shopping.png" height={25} alt="dash" />
            }
          >
            <NavLink to="/dashboard/guestOrders">Guest Order</NavLink>
          </Menu.Item>

          <Menu.Item
            key="/dashboard/preview/trackings"
            icon={
              <img src="/images/fast-delivery.png" height={25} alt="dash" />
            }
          >
            <NavLink to="/dashboard/preview/trackings">
              Customer Shipping
            </NavLink>
          </Menu.Item>
          <Divider orientation="left" plain>
            Quatation
          </Divider>
          <Menu.Item
            key="/dashboard/create/quotation"
            icon={<img src="/images/quotation.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/create/quotation">Add Quotation</NavLink>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/quotations"
            icon={<img src="/images/quotes.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/quotations">Quotations</NavLink>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/request-quotations"
            icon={<img src="/images/quote.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/view/request-quotations">
              Request Quotation
            </NavLink>
          </Menu.Item>

          {/* <Menu.Item
            key="/dashboard/quotations"
            icon={<img src="/images/new-product.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/quotations">Quatations</NavLink>
          </Menu.Item> */}
          <Divider orientation="left" plain>
            Products
          </Divider>
          {/* <Typography.Title level={4}>Product</Typography.Title> */}
          {/*
          <SubMenu key="order" icon={<ProjectOutlined />} title="Orders">
            <Menu.Item key="/dashboard/orders">
              <NavLink to="/dashboard/orders">Customer Orders</NavLink>
            </Menu.Item>
            <Menu.Item key="/dashboard/guestOrders">
              <NavLink to="/dashboard/guestOrders">Guest Orders</NavLink>
            </Menu.Item>
          </SubMenu>
          */}
          <Menu.Item
            key="/dashboard/product/all"
            icon={
              <img src="/images/brand-identity.png" height={25} alt="dash" />
            }
          >
            <NavLink to="/dashboard/product/all">Products</NavLink>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/product/approve"
            icon={<img src="/images/rejected.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/product/approve">
              Products not approve
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/product/best-seller"
            icon={<img src="/images/best-seller.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/product/best-seller">
              Products best seller
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item
            key="/dashboard/preview/product"
            icon={<img src="/images/new-product.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/preview/product">Preview Product</NavLink>
          </Menu.Item> */}
          {/* <Menu.Item
						key="/dashboard/product/add"
						icon={<img src="/images/new-product.png" height={25} alt="dash" />}
					>
						<NavLink to="/dashboard/product/add">Add Product</NavLink>
					</Menu.Item> */}
          <Menu.Item
            key="/dashboard/product/add-products"
            icon={<img src="/images/new-product.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/product/add-products">Add Product</NavLink>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/create/brand"
            icon={<img src="/images/brand.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/create/brand">Add Brand Name</NavLink>
          </Menu.Item>
          <Divider orientation="left" plain>
            Categories
          </Divider>
          {/* <Menu.Item
            key="/dashboard/product/main-categories"
            icon={<BsUiChecksGrid color="#f9546f" />}
          >
            <NavLink to="/dashboard/product/main-categories">
              Add Main Categories
            </NavLink>
          </Menu.Item> */}
          <Menu.Item
            key="/dashboard/product/categories"
            icon={<img src="/images/maintenance.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/product/categories">Categories</NavLink>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/product/sub-category"
            icon={
              <img src="/images/folder-management.png" height={25} alt="dash" />
            }
          >
            <NavLink to="/dashboard/product/sub-category">
              Subcategories
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/create/banner"
            icon={<img src="/images/slider.png" height={25} alt="dash" />}
          >
            <NavLink to="/dashboard/create/banner">Add Banner</NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
    </React.Fragment>
  );
};

export default SideNavbar;
