// import React from "react";
// import { Form, Input, message, Button, Row, Col } from "antd";
// import { useMutation, useQuery } from "@apollo/client";
// import {
//   CREATE_SUB_CATEGORY,
//   SUB_CATEGORIES,
// } from "../../graphql/sub-category";

// const CreateCategory = () => {
//   const [form] = Form.useForm();
//   const { refetch } = useQuery(SUB_CATEGORIES);

//   const [createMutation] = useMutation(CREATE_SUB_CATEGORY);

//   const handleSubmit = (values) => {
//     createMutation({
//       variables: {
//         title: values.title,
//       },
//     })
//       .then((res) => {
//         message.success(res.data.createSubCategory.message);
//         refetch();
//         form.resetFields();
//       })
//       .catch((error) => {
//         let err = JSON.parse(JSON.stringify(error));
//         message.error(err.graphQLErrors[0].message);
//         form.resetFields();
//       });
//   };

//   return (
//     <React.Fragment>
//       <Form
//         name="basic"
//         form={form}
//         layout="vertical"
//         size="large"
//         onFinish={handleSubmit}
//       >
//         <Row>
//           <Col span={12}>
//             <Form.Item
//               label="Title"
//               name="title"
//               rules={[
//                 {
//                   required: true,
//                   message: "Title is required!",
//                 },
//               ]}
//             >
//               <Input className="schoolInput" />
//             </Form.Item>
//           </Col>
//           <Col span={12}></Col>
//         </Row>

//         <Form.Item>
//           <Button type="primary" htmlType="submit" className="button-submit">
//             Submit
//           </Button>
//         </Form.Item>
//       </Form>
//     </React.Fragment>
//   );
// };

// export default CreateCategory;

import { Button, Col, Form, Input, Row, Select, message } from "antd";
import {
  CREATE_SUB_CATEGORY,
  SUB_CATEGORIES,
} from "../../graphql/sub-category";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { CATEGORIES } from "../../graphql/categories";

const SubCategory = () => {
  const [categorySlug, setCategorySlug] = useState("");
  const [form] = Form.useForm();
  const { refetch } = useQuery(SUB_CATEGORIES);
  const { loading, data } = useQuery(CATEGORIES);
  const [createMutation] = useMutation(CREATE_SUB_CATEGORY);
  if (loading) return "laoding...";
  const handleSubmit = (values) => {
    createMutation({
      variables: {
        title: values.title,
        categoryId: values.categoryId ? values.categoryId : "",
        categorySlug: categorySlug ? categorySlug : "",
      },
    })
      .then((res) => {
        message.success(res.data.createSubCategory.message);
        refetch();
        form.resetFields();
      })
      .catch((error) => {
        let err = JSON.parse(JSON.stringify(error));
        message.error(err.graphQLErrors[0].message);
        form.resetFields();
      });
  };

  return (
    <React.Fragment>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        size="large"
        onFinish={handleSubmit}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Title is required!",
                },
              ]}
            >
              <Input className="schoolInput" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Category"
              name="categoryId"
              rules={[
                {
                  required: true,
                  message: "Category is required!",
                },
              ]}
            >
              <Select
                showSearch
                // mode=""
                placeholder="select category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {data.categories.map((category) => {
                  return (
                    // eslint-disable-next-line react/jsx-no-undef
                    <Option value={category.id} key={category.id}>
                      <div onClick={() => setCategorySlug(category.slug)}>
                        {category.title}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="button-submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default SubCategory;
